import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({ name: 'boolToText' })
export class BoolToTextPipe implements PipeTransform {
  transform(value: boolean): string {
    let boolText = '';
    if (!isNil(value)) {
      boolText = value ? 'Oui' : 'Non';
    }
    return boolText;
  }
}
