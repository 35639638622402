import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { PreviousCrop } from './previous-crop.model';

@Injectable({
  providedIn: 'root',
})
export class PreviousCropService {
  constructor(private httpClient: HttpClient) {}

  getPreviousCropById(id: string): Observable<PreviousCrop> {
    const previousCropByIdUrl = environment.api + '/fs-agro-datum/api/previousCrops/' + id;
    return this.httpClient.get<Observable<PreviousCrop>>(previousCropByIdUrl).pipe(
      map(a => new PreviousCrop(a)),
      tapLogError('getPreviousCropById')
    );
  }

  getPreviousCropFromParcel(parcel$: Observable<Parcel>): Observable<PreviousCrop> {
    return parcel$.pipe(
      map(a => a.agroData.activities.previous.previousCropRefId),
      flatMap(previousCropRefId => this.getPreviousCropById(previousCropRefId)),
      shareReplay(1)
    );
  }

  getAntepreviousPreviousCropFromParcel(parcel$: Observable<Parcel>): Observable<PreviousCrop> {
    return parcel$.pipe(
      map(a => a.agroData.activities.anteprevious.previousCropRefId),
      flatMap(previousCropRefId => this.getPreviousCropById(previousCropRefId)),
      shareReplay(1)
    );
  }
}
