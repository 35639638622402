import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, flatMap, mergeMap, toArray, shareReplay } from 'rxjs/internal/operators';
import { ResponseEntities, EntityProvider, tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { Crop } from './crop.model';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';
import { toResponseEntities } from 'src/app/core/hal';

@Injectable({
  providedIn: 'root',
})
export class CropService implements EntityProvider<Crop> {
  relativeUrl = '/fs-agro-datum/api/crops';
  fsAgroDatumCropApi = environment.api + this.relativeUrl;
  getByHttpParams = this.entityProviderService.httpGetter(Crop, this.relativeUrl);

  constructor(
    private httpClient: HttpClient,
    private entityProviderService: EntityProviderService
  ) {}

  getCropById(id: string): Observable<Crop> {
    const cropIdUrl = this.fsAgroDatumCropApi + '/' + id;
    return this.httpClient.get<Observable<Crop>>(cropIdUrl).pipe(
      map(a => new Crop(a)),
      tapLogError('getCropById')
    );
  }

  getCropsByIds(ids: string[]): Observable<Crop[]> {
    return this.httpClient
      .get<ResponseEntities<Crop>>(this.fsAgroDatumCropApi, {
        params: new HttpParams({ fromObject: { id: ids } }),
      })
      .pipe(
        map(a => new ResponseEntities<Crop>(Crop, a)),
        map(a => a.getAllEmbedded()),
        tapLogError('getCropByIds'),
        shareReplay(1)
      );
  }

  getCropsFromParcels(parcel: Observable<Parcel[]>): Observable<Crop[]> {
    return parcel.pipe(
      mergeMap(a => a),
      map(a => a.agroData.crop.cropRefId),
      toArray(),
      flatMap(ids => this.getCropsByIds(ids)),
      tapLogError('getEmbeddedCropIdsFromParcel'),
      shareReplay(1)
    );
  }

  getCropFromParcels(parcel: Observable<Parcel>): Observable<Crop> {
    return parcel.pipe(
      map(a => a.agroData.crop.cropRefId),
      flatMap(a => this.getCropById(a))
    );
  }

  getAllCrops(projection: string): Observable<Crop[]> {
    const params = { projection, sort: 'label,asc' };
    return this.httpClient.get<ResponseEntities<Crop>>(this.fsAgroDatumCropApi, { params }).pipe(
      toResponseEntities(Crop),
      map(re => re.getAllEmbedded()),
      shareReplay(1)
    );
  }
}
