import { Entity, LinkResource } from 'src/app/core/hal';

export class Parcel extends LinkResource implements Entity {
  id: string;
  code: string;
  externalCode: string;
  pacBlockNumber: number;
  name: string;
  declaredSurface: number;
  measuredSurface: number;
  declaredDepartment: number;
  active: boolean;
  lastModifiedDate: Date;
  agroData: EmbeddedAgroData;
  aoi: EmbeddedAoi;
  frenchCommune: EmbeddedFrenchCommune;
  projectedDoseComputationDate?: Date;

  getRelativeApiUrl = () => '/fs-parcel/api/parcels';

  constructor(parcelData: any) {
    super(parcelData);
    if (parcelData) {
      this.id = parcelData.id;
      this.code = parcelData.code;
      this.externalCode = parcelData.externalCode;
      this.pacBlockNumber = parcelData.pacBlockNumber;
      this.name = parcelData.name;
      this.declaredSurface = parcelData.declaredSurface;
      this.measuredSurface = parcelData.measuredSurface;
      this.declaredDepartment = parcelData.declaredDepartment;
      this.active = parcelData.active;
      this.lastModifiedDate = parcelData.lastModifiedDate;
      this.aoi = parcelData.aoi;
      this.agroData = new EmbeddedAgroData(parcelData.agroData);
      this.frenchCommune = new EmbeddedFrenchCommune(parcelData.frenchCommune);
    }
  }
}

class EmbeddedAoi {
  originCrsName: string;
  crsName: string;
  centroid: EmbeddedCentroid;
  originPolygon: EmbeddedOriginPolygon;
  polygon: EmbeddedPolygon;
  constructor(aoiData?: any) {
    if (aoiData) {
      this.originCrsName = aoiData.originCrsName;
      this.crsName = aoiData.crsName;
      this.centroid = new EmbeddedCentroid(aoiData.centroid);
      this.originPolygon = new EmbeddedOriginPolygon(aoiData.originPolygon);
      this.polygon = new EmbeddedPolygon(aoiData.polygon);
    }
  }
}

class EmbeddedCentroid {
  type: string;
  coordinates: Coordinates[];
  constructor(centroidData?: any) {
    if (centroidData) {
      this.type = centroidData.type;
      if (centroidData.coordinates) {
        this.coordinates = centroidData.coordinates.map(coordinate => new Coordinates(coordinate));
      }
    }
  }
}

class Coordinates {
  constructor(coordinatesData?: any) {
    if (coordinatesData) {
    }
  }
}

class EmbeddedOriginPolygon {
  type: string;
  coordinates: Coordinates[];
  constructor(originPolygonData?: any) {
    if (originPolygonData) {
      this.type = originPolygonData.type;
      if (originPolygonData.coordinates) {
        this.coordinates = originPolygonData.coordinates.map(
          coordinate => new Coordinates(coordinate)
        );
      }
    }
  }
}

class EmbeddedPolygon {
  type: string;
  coordinates: Coordinates[];
  constructor(polygonData?: any) {
    if (polygonData) {
      this.type = polygonData.type;
      if (polygonData.coordinates) {
        this.coordinates = polygonData.coordinates.map(coordinate => new Coordinates(coordinate));
      }
    }
  }
}

export class EmbeddedAgroData extends LinkResource {
  medianYieldTarget: string;
  soilRefId: string;
  soilOrganicMatterRate: string;
  soilInputLabel: string;
  activities: EmbeddedActivities;
  crop: EmbeddedCrop;
  nitrogenStatus: EmbeddedNitrogenStatus;
  ndoseComputationMethodRefId: string;
  seedingActivity: EmbeddedSeedingActivity;

  constructor(agroData?: any) {
    super(agroData);
    if (agroData) {
      this.medianYieldTarget = agroData.medianYieldTarget;
      this.soilRefId = agroData.soilRefId;
      this.soilOrganicMatterRate = agroData.soilOrganicMatterRate;
      this.soilInputLabel = agroData.soilInputLabel;
      this.activities = new EmbeddedActivities(agroData.activities);
      this.crop = new EmbeddedCrop(agroData.crop);
      this.nitrogenStatus = new EmbeddedNitrogenStatus(agroData.nitrogenStatus);
      this.ndoseComputationMethodRefId = agroData.ndoseComputationMethodRefId;
    }
  }
}

export class EmbeddedActivities {
  anteprevious: EmbeddedAnteprevious;
  irrigation: EmbeddedIrrigation;
  catchCropActivity: EmbeddedcatchCropActivity;
  grasslandActivity: EmbeddedGrasslandActivity;
  organicWasteActivity: EmbeddedOrganicWasteActivity;
  historicActivities: EmbeddedHistoricActivities;
  previous: EmbeddedPrevious;
  seedingActivity: EmbeddedSeedingActivity;
  constructor(activitiesData?: any) {
    if (activitiesData) {
      this.anteprevious = activitiesData.anteprevious;
      this.irrigation = activitiesData.irrigation;
      this.catchCropActivity = activitiesData.catchCropActivity;
      this.grasslandActivity = activitiesData.grasslandActivity;
      this.organicWasteActivity = activitiesData.organicWasteActivity;
      this.historicActivities = activitiesData.historicActivities;
      this.previous = activitiesData.previous;
      this.seedingActivity = activitiesData.seedingActivity;
    }
  }
}

export class EmbeddedCrop extends LinkResource {
  linkedVarietyUsed: boolean;
  linkedVarietyName: string;
  varietyRefId: string;
  kindRefId: string;
  cropRefId: string;
  kindData: EmbeddedKindData;
  constructor(cropsData?: any) {
    super(cropsData);
    if (cropsData) {
      this.linkedVarietyUsed = cropsData.linkedVarietyUsed;
      this.linkedVarietyName = cropsData.linkedVarietyName;
      this.varietyRefId = cropsData.varietyRefId;
      this.kindRefId = cropsData.kindRefId;
      this.cropRefId = cropsData.cropRefId;
    }
  }
}

class EmbeddedKindData {
  code: string;
  label: string;
}

class EmbeddedNitrogenStatus {
  catchCropEffect: number;
  deficit: number;
  fertilizerMicrobialEffect: number;
  fertilizerVolatilizationEffect: number;
  floodingEffect: number;
  grasslandEffect: number;
  humusMineralizationEffect: number;
  irrigationEffect: number;
  maxDose: number;
  mesuredNCredit: number;
  actualMesuredNCredit: number;
  nabsBalanceBegining: number;
  ncreditBalanceBegining: number;
  ncreditBalanceBeginingCollectedDate: string;
  ncreditYield: number;
  organicWasteEffect: number;
  previousCropMinEffect: number;
  projectedDose: number;
  symbioticFixationEffect: number;
  totalNeed: number;
  workingCoefficient: number;
  projectedDoseComputationDate: Date;

  constructor(nitrogenStatusData?: any) {
    if (nitrogenStatusData) {
      this.catchCropEffect = nitrogenStatusData.catchCropEffect;
      this.deficit = nitrogenStatusData.deficit;
      this.fertilizerMicrobialEffect = nitrogenStatusData.fertilizerMicrobialEffect;
      this.fertilizerVolatilizationEffect = nitrogenStatusData.fertilizerVolatilizationEffect;
      this.floodingEffect = nitrogenStatusData.floodingEffect;
      this.grasslandEffect = nitrogenStatusData.grasslandEffect;
      this.humusMineralizationEffect = nitrogenStatusData.humusMineralizationEffect;
      this.irrigationEffect = nitrogenStatusData.irrigationEffect;
      this.maxDose = nitrogenStatusData.maxDose;
      this.mesuredNCredit = nitrogenStatusData.mesuredNCredit;
      this.actualMesuredNCredit = nitrogenStatusData.actualMesuredNCredit;
      this.nabsBalanceBegining = nitrogenStatusData.nabsBalanceBegining;
      this.ncreditBalanceBegining = nitrogenStatusData.ncreditBalanceBegining;
      this.ncreditBalanceBeginingCollectedDate =
        nitrogenStatusData.ncreditBalanceBeginingCollectedDate;
      this.ncreditYield = nitrogenStatusData.ncreditYield;
      this.organicWasteEffect = nitrogenStatusData.organicWasteEffect;
      this.previousCropMinEffect = nitrogenStatusData.previousCropMinEffect;
      this.projectedDose = nitrogenStatusData.projectedDose;
      this.symbioticFixationEffect = nitrogenStatusData.symbioticFixationEffect;
      this.totalNeed = nitrogenStatusData.totalNeed;
      this.workingCoefficient = nitrogenStatusData.workingCoefficient;
      if (nitrogenStatusData.projectedDoseComputationDate) {
        this.projectedDoseComputationDate = new Date(
          nitrogenStatusData.projectedDoseComputationDate
        );
      }
    }
  }
}

class EmbeddedIrrigation {
  irrigated: boolean;
  irrigation: string;
  irragationNitrateLevel: string;
  waterTurnDurationDays: string;

  constructor(irrigationData?: any) {
    if (irrigationData) {
      this.irrigated = irrigationData.irrigated;
      this.irrigation = irrigationData.irrigation;
      this.irragationNitrateLevel = irrigationData.irragationNitrateLevel;
      this.waterTurnDurationDays = irrigationData.waterTurnDurationDays;
    }
  }
}

class EmbeddedcatchCropActivity extends LinkResource {
  seedingDate: Date;
  emergenceDate: Date;
  destructionDate: Date;
  catchCropRefId: string;
  catchCropBiomassLevelRefId: string;

  constructor(catchCropActivityData?: any) {
    super(catchCropActivityData);
    if (catchCropActivityData) {
      this.seedingDate = catchCropActivityData.seedingDate;
      this.emergenceDate = catchCropActivityData.emergenceDate;
      this.destructionDate = catchCropActivityData.destructionDate;
      this.catchCropRefId = catchCropActivityData.catchCropRefId;
      this.catchCropBiomassLevelRefId = catchCropActivityData.catchCropBiomassLevelRefId;
    }
  }
}

class EmbeddedGrasslandActivity {
  grasslandTypeRefId: string;
  grasslandUsageRefId: string;
  regularlyReversed: boolean;
  reverseDate: string;
  reversedGrasslandAgeRefId: string;

  constructor(grasslandActivityData?: any) {
    if (grasslandActivityData) {
      this.grasslandTypeRefId = grasslandActivityData.grasslandTypeRefId;
      this.grasslandUsageRefId = grasslandActivityData.grasslandUsageRefId;
      this.regularlyReversed = grasslandActivityData.regularlyReversed;
      this.reverseDate = grasslandActivityData.reverseDate;
      this.reversedGrasslandAgeRefId = grasslandActivityData.reversedGrasslandAgeRefId;
    }
  }
}

class EmbeddedOrganicWasteActivity {
  inputs: Inputs[] = [];
  periodicInputRefs: PeriodicInputRefs[] = [];

  constructor(organicWasteActivityData?: any) {
    if (organicWasteActivityData.inputs) {
      this.inputs = organicWasteActivityData.inputs.map(input => new Inputs(input));
    }
    if (organicWasteActivityData.periodicInputRefs) {
      this.periodicInputRefs = organicWasteActivityData.periodicInputRefs.map(
        periodicInputRef => new PeriodicInputRefs(periodicInputRef)
      );
    }
  }
}

class EmbeddedAnteprevious {
  irrigated: string;
  organicWasteInputs: OrganicWasteInput[] = [];
  previousCropRefId: string;
  previousCropWasteUsageRefId: string;
  totalNDose: string;
  yield: string;
  constructor(antepreviousData?: any) {
    this.irrigated = antepreviousData.irrigated;
    if (antepreviousData.organicWasteInputs) {
      this.organicWasteInputs = antepreviousData.organicWasteInputs.map(
        organicWasteInput => new OrganicWasteInput(organicWasteInput)
      );
    }
  }
}

class OrganicWasteInput {
  dose: number;
  inputDate: string;
  organicWasteInputModeRefId: string;
  organicWasteRefId: string;
  constructor(organicWasteInputsData?: any) {
    this.dose = organicWasteInputsData.dose;
    this.inputDate = organicWasteInputsData.inputDate;
    this.organicWasteInputModeRefId = organicWasteInputsData.organicWasteInputModeRefId;
    this.organicWasteRefId = organicWasteInputsData.organicWasteRefId;
  }
}

class EmbeddedHistoricActivities {
  historicCropWasteUsageRefId: string;
  inputs: Inputs[] = [];

  constructor(historicActivitiesData?: any) {
    this.historicCropWasteUsageRefId = historicActivitiesData.historicCropWasteUsageRefId;
    if (historicActivitiesData.inputs) {
      this.inputs = historicActivitiesData.inputs.map(input => new Inputs(input));
    }
  }
}

class EmbeddedPrevious {
  irrigated: boolean;
  organicWasteInputs: OrganicWasteInput[] = [];
  previousCropRefId: string;
  previousCropWasteUsageRefId: string;
  totalNDose: string;
  yield: string;
  constructor(previousData?: any) {
    this.irrigated = previousData.irrigated;
    if (previousData.organicWasteInputs) {
      this.organicWasteInputs = previousData.organicWasteInputs.map(
        organicWasteInput => new OrganicWasteInput(organicWasteInput)
      );
    }
    this.previousCropRefId = previousData.previousCropRefId;
    this.previousCropWasteUsageRefId = previousData.previousCropWasteUsageRefId;
    this.totalNDose = previousData.totalNDose;
    this.yield = previousData.yield;
  }
}

class EmbeddedSeedingActivity {
  seedingDate: Date;
  density: string;
  rawSpacing: string;
  actualSeedingDate: DataCue;
  constructor(seedingActivityData?: any) {
    this.seedingDate = seedingActivityData.seedingDate;
    this.density = seedingActivityData.density;
    this.rawSpacing = seedingActivityData.rawSpacing;
    this.actualSeedingDate = seedingActivityData.actualSeedingDate;
  }
}

class Inputs {
  dose: number;
  inputDate: string;
  organicWasteInputModeRefId: string;
  organicWasteRefId: string;

  constructor(inputsData?: any) {
    if (inputsData) {
      this.dose = inputsData.dose;
      this.inputDate = inputsData.inputDate;
      this.organicWasteInputModeRefId = inputsData.organicWasteInputModeRefId;
      this.organicWasteRefId = inputsData.organicWasteRefId;
    }
  }
}

class PeriodicInputRefs {
  periodicInputRefId: string;

  constructor(periodicInputRefsData?: any) {
    this.periodicInputRefId = periodicInputRefsData.periodicInputRefId;
  }
}

class EmbeddedFrenchCommune {
  codeInsee: string;
  name: string;
  codeDepartment: string;

  constructor(frenchCommuneData?: any) {
    if (frenchCommuneData) {
      this.codeInsee = frenchCommuneData.codeInsee;
      this.name = frenchCommuneData.name;
      this.codeDepartment = frenchCommuneData.codeDepartment;
    }
  }
}
