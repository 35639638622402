import { Component, OnInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DxDatasourceCreatorService, mapErrorToMsg } from 'src/app/core';
import { HttpParams } from '@angular/common/http';
import { ArvalisService, TimeService } from 'src/app/shared';

@Component({
  selector: 'app-arvalis-imports',
  templateUrl: './arvalis-imports.component.html',
  styleUrls: ['./arvalis-imports.component.scss'],
})
export class ArvalisImportsComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

  dataSource: DataSource;
  allowedFileExtensions = ['.csv'];
  selectedFile = [];
  importPopupVisible = false;
  onImport = this.importFile.bind(this);
  importPending$: Observable<any>;

  constructor(
    private arvalisService: ArvalisService,
    private datasourceCreator: DxDatasourceCreatorService,
    private timeService: TimeService
  ) {
    this.calculateDate = this.calculateDate.bind(this);
  }

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.loadHistory(searchParams).pipe(
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ httpParams: new HttpParams().set('projection', 'history') }),
    });
  }

  loadHistory(searchParams) {
    return this.arvalisService.getPaginatedStageFiles(searchParams);
  }

  importFile() {
    this.importPopupVisible = false;
    const file = this.selectedFile.pop();
    this.importPending$ = this.arvalisService.uploadStageFile(file).pipe(
      finalize(() => {
        this.datagrid.instance.refresh();
      })
    );
  }

  calculateDate(dataSource) {
    return this.timeService.utcToTimeZone(dataSource.importDate);
  }
}
