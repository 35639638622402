import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { TechnicianService, Technician, Cooperative } from 'src/app/shared';
import { tap } from 'rxjs/operators';
import { mapErrorToMsg, DxDatasourceCreatorService } from 'src/app/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';

@Component({
  selector: 'app-technicians-list',
  templateUrl: './technicians-list.component.html',
  styleUrls: ['./technicians-list.component.scss'],
})
export class TechniciansListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  popupVisible = false;
  dataSource: DataSource;
  totalNbTechnicians: number;

  constructor(
    private technicianService: TechnicianService,
    private datasourceCreator: DxDatasourceCreatorService,
    private httpMappingService: HttpMappingService
  ) {}

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.technicianService.getByHttpParams(searchParams.httpParams).pipe(
          this.httpMappingService.mapEntities({
            keyToSearchInGetter: 'technician.id',
            pathToDataInOutput: 'registeredTechnicianData',
            projection: 'technicianList',
            relativeApiUrl: '/fs-core/api/registeredTechnicians',
          }),
          this.httpMappingService.mapEntities({
            pathToIdInInput: 'registeredTechnicianData.registeredCooperative.cooperativeRefId',
            pathToDataInOutput: 'cooperativeData',
            projection: 'dropdown',
            targetType: Cooperative,
          }),
          tap(response => (this.totalNbTechnicians = response.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ httpParams: new HttpParams().set('projection', 'list') }),
    });
  }

  fullNameColumn_calculateCellValue(technicianRow: Technician) {
    return technicianRow.getFullName();
  }

  exportDataGrid() {
    this.dataGrid.instance.exportToExcel(false);
  }
}
