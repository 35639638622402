import { LinkResource } from 'src/app/core';

export class SoilGroup extends LinkResource {
  id: string;
  label: string;
  code: string;

  constructor(soilGroupData?: any) {
    super(soilGroupData);
    if (soilGroupData) {
      this.id = soilGroupData.id;
      this.label = soilGroupData.label;
      this.code = soilGroupData.code;
    }
  }
}
