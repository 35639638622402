import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Crop, Campaign, NDoseComputationMethod, ParcelService } from 'src/app/shared';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-total-ndose-compute',
  templateUrl: './total-ndose-compute-modal.component.html',
  styleUrls: ['./total-ndose-compute-modal.component.scss'],
})
export class TotalNdoseComputeModalComponent implements OnInit {
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() doseComputed = new EventEmitter<void>();

  onDisposing = this.closeModal.bind(this);
  onCompute = this.computeTotalDose.bind(this);

  campaign: Campaign;
  crop: Crop;
  computationMethod: NDoseComputationMethod;

  constructor(private parcelService: ParcelService, private toastrService: ToastrService) {}

  ngOnInit() {}

  closeModal() {
    // Clear values to have empty fields the next time we open the modal
    this.campaign = null;
    this.crop = null;
    this.computationMethod = null;

    this.isOpenChange.emit(false);
  }

  computeTotalDose() {
    this.parcelService
      .computeTotalNForFilteredParcels(this.campaign.id, this.crop.id, this.computationMethod.id)
      .subscribe(
        () => {
          this.doseComputed.emit();
          this.closeModal();
        },
        error => {
          this.toastrService.error('Une erreur est survenue lors du calcul de la dose totale.');
        },
        () => {
          this.toastrService.success('La dose totale a été recalculée');
        }
      );
  }

  isFormValid() {
    return this.campaign && this.crop && this.computationMethod;
  }
}
