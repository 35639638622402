import { LinkResource } from 'src/app/core';

export class PreviousCropWasteUsage extends LinkResource {
  id: string;
  label: string;
  code: string;

  constructor(previousCropWasteUsageData?: any) {
    super(previousCropWasteUsageData);
    if (previousCropWasteUsageData) {
      this.id = previousCropWasteUsageData.id;
      this.label = previousCropWasteUsageData.label;
      this.code = previousCropWasteUsageData.code;
    }
  }
}
