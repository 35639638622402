import { LinkResource } from 'src/app/core';

export class GrasslandUsage extends LinkResource {
  id: string;
  label: string;
  code: string;

  constructor(grasslandUsageData?: any) {
    super(grasslandUsageData);
    if (grasslandUsageData) {
      this.id = grasslandUsageData.id;
      this.label = grasslandUsageData.label;
      this.code = grasslandUsageData.code;
    }
  }
}
