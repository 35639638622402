import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader-button',
  templateUrl: './loader-button.component.html',
  styleUrls: ['./loader-button.component.scss'],
})
export class LoaderButtonComponent {
  @Input() observable: Observable<any>;

  pending: boolean;

  constructor() {}
}
