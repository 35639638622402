import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LayoutModule } from './layout/layout.module';
import { DxDatasourceCreatorService } from './dx';
import { HttpMappingModule } from './rxjs-operators/http-mapping/http-mapping.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from './http.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { RequestPaginationInterceptor } from './http/request-pagination.interceptor';

import { DifferenceService } from './utility/difference.service';

@NgModule({
  declarations: [],
  imports: [
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      tapToDismiss: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
      positionClass: 'toast-bottom-right',
    }),
  ],
  exports: [LayoutModule, HttpMappingModule],
  providers: [
    DxDatasourceCreatorService,
    DifferenceService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestPaginationInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
