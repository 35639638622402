import { Component } from '@angular/core';
import { UsersListComponent } from 'src/app/feature/users-list/users-list.component';
import { RegionalEngineer } from 'src/app/shared/user/regional-engineer.model';

@Component({
  selector: 'app-regional-engineers-list',
  templateUrl: '../users-list.component.html',
  styleUrls: ['../users-list.component.scss'],
})
export class RegionalEngineersListComponent extends UsersListComponent {
  name = {
    singular: 'Ingenieur régional',
    plural: 'Ingénieurs régionaux',
  };

  displayEmail = true;
  displayPhone1 = true;
  displayPhone2 = true;

  getEndpointFilter() {
    return 'otherUsers?profile=REGIONAL_ENGINEER';
  }

  getEndpoint() {
    return 'otherUsers';
  }
  getType() {
    return RegionalEngineer;
  }
}
