import { Pipe, PipeTransform } from '@angular/core';
import Qty from 'js-quantities';
import { isNil } from 'lodash';

@Pipe({ name: 'convertTo' })
export class UnitConverterPipe implements PipeTransform {
  transform(value: number, fromUnit: string, toUnit: string, customUnitLabel?: string): string {
    let convertedValue = isNil(value)
      ? ''
      : Qty(value, fromUnit)
          .toPrec(0.01)
          .toString(toUnit);
    if (!isNil(customUnitLabel)) {
      convertedValue = convertedValue.replace(toUnit, customUnitLabel);
    }
    return convertedValue;
  }
}
