import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';
import { DxDatasourceCreatorService, mapErrorToMsg } from 'src/app/core';
import { SearchService } from 'src/app/feature/search/search.service';
import { tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-parcels-search-grid',
  templateUrl: './parcels-search-grid.component.html',
  styleUrls: ['./parcels-search-grid.component.scss'],
})
export class ParcelsSearchGridComponent implements OnInit, OnChanges {
  @Input() query: string;
  @Output() totalElements = new EventEmitter<number>();
  @ViewChild('searchGrid') searchGrid: DxDataGridComponent;
  dataSource: DataSource;

  constructor(
    private datasourceCreator: DxDatasourceCreatorService,
    private searchService: SearchService
  ) {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.searchService.searchParcels(searchParams).pipe(
          tap(r => this.totalElements.emit(r.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({
        query: this.query,
        httpParams: new HttpParams().set('projection', 'search'),
      }),
    });
  }

  ngOnInit() {
    if (this.searchGrid.instance) {
      this.searchGrid.instance.refresh();
    }
  }

  ngOnChanges() {
    if (this.searchGrid.instance) {
      this.searchGrid.instance.refresh();
    }
  }
}
