import { Injectable } from '@angular/core';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';
import { RegisteredCrop } from './registered-crop.model';

@Injectable({
  providedIn: 'root',
})
export class RegisteredCropService {
  httpGet = this.entityProviderService.httpGetter(RegisteredCrop, RegisteredCrop.relativeApiUrl);

  constructor(private entityProviderService: EntityProviderService) {}
}
