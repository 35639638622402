import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RestResourceService {
  constructor() {}

  getUrl(linkResource): string {
    return linkResource._links.self.href;
  }

  getId(linkResource): string {
    const url = this.getUrl(linkResource);
    return url.substr(url.lastIndexOf('/') + 1);
  }
}
