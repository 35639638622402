import { Component, OnInit, ViewChild } from '@angular/core';
import { TotalNdoseFilter } from './total-ndose-filter';
import { TotalNdoseParcelsComponent } from './total-ndose-parcels/total-ndose-parcels.component';
import { TotalNdosePerformanceTargetComponent } from './total-ndose-performance-target/total-ndose-performance-target.component';
import { TotalNdoseDistributionComponent } from './total-ndose-distribution/total-ndose-distribution.component';

@Component({
  selector: 'app-total-ndose',
  templateUrl: './total-ndose.component.html',
  styleUrls: ['./total-ndose.component.scss'],
})
export class TotalNdoseComponent implements OnInit {
  @ViewChild(TotalNdoseParcelsComponent)
  parcelList: TotalNdoseParcelsComponent;

  @ViewChild(TotalNdosePerformanceTargetComponent)
  parcelPerformanceTargetChart: TotalNdosePerformanceTargetComponent;

  @ViewChild(TotalNdoseDistributionComponent)
  parcelDistributionChart: TotalNdoseDistributionComponent;

  filter: TotalNdoseFilter;

  constructor() {}

  ngOnInit() {}

  reloadComponents(filter?: TotalNdoseFilter) {
    if (filter) {
      this.filter = filter;
    }
    this.parcelList.applyFilter(this.filter);
    this.parcelPerformanceTargetChart.loadParcels(this.filter);
    this.parcelDistributionChart.loadParcels(this.filter);
  }
}
