import { Entity } from 'src/app/core/hal';

export class CustomerDataFile implements Entity {
  id: string;
  fileName: string;
  urlZip: string;
  status: string;
  campaignRefId: string;
  cooperativeRefId: string;
  lastActivityDate: Date;
  hasReports: boolean;

  getRelativeApiUrl = () => '/fs-customer-datafile/api/dataFiles';

  constructor(dataFile?: any) {
    if (dataFile) {
      this.id = dataFile.id;
      this.fileName = dataFile.fileName;
      this.urlZip = dataFile.urlZip;
      this.status = dataFile.status;
      this.campaignRefId = dataFile.campaignRefId;
      this.cooperativeRefId = dataFile.cooperativeRefId;
      this.lastActivityDate = dataFile.lastActivityDate;
      this.hasReports = dataFile.hasReports;
    }
  }
}
