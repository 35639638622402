import { Component, OnInit, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { Cooperative, CooperativeService } from 'src/app/shared';
import { Observable, EMPTY } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { HttpParams } from '@angular/common/http';
import { mergeMap, tap } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import { DxDatasourceCreatorService, mapErrorToMsg } from 'src/app/core';

@Component({
  selector: 'app-cooperatives-list',
  templateUrl: './cooperatives-list.component.html',
  styleUrls: ['./cooperatives-list.component.scss'],
})
export class CooperativesListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  cooperative$: Observable<Cooperative[]>;
  totalNbCoop: number;
  dataSource: DataSource;
  formMode: String;
  selectedLogos = {
    logoSmall: { content: [], isValid: true, errorObject: {} },
    logoLarge: { content: [], isValid: true, errorObject: {} },
  };
  allowedFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];
  logoMaxSize = {
    logoSmall: 150,
    logoLarge: 3000,
  };

  constructor(
    private cooperativeService: CooperativeService,
    private datasourceCreator: DxDatasourceCreatorService
  ) {}

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.loadCooperativeList(searchParams).pipe(
          tap(response => (this.totalNbCoop = response.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ httpParams: new HttpParams().set('projection', 'cooperativeList') }),
      update: this.updateRow.bind(this),
      insert: this.addRow.bind(this),
    });
  }

  loadCooperativeList(searchParams) {
    return this.cooperativeService.getByHttpParams(searchParams.httpParams);
  }

  exportAll() {
    this.cooperativeService.exportCooperatives();
  }

  updateRow(key, value): Promise<any> {
    const coopId = key.id;
    const patchData = this.preFormatValue(value);
    return this.cooperativeService
      .updateCooperative(coopId, patchData)
      .pipe(mapErrorToMsg('Il y a eu une erreur pendant la modification de la coopérative !'))
      .toPromise()
      .then(() => {
        if (value.logoSmall || value.logoLarge) {
          this.uploadLogos(coopId, value).subscribe();
        }
      });
  }

  addRow(value) {
    const patchData = this.preFormatValue(value);
    return this.cooperativeService
      .addCooperative(new Cooperative(patchData))
      .pipe(
        mergeMap(coop => this.uploadLogos(coop.id, value)),
        mapErrorToMsg("Il y a eu une erreur pendant l'ajout de la coopérative !")
      )
      .toPromise();
  }

  uploadLogos(coopId: string, value: any): Observable<any> {
    const logoSmall = this.getLogoIfValid('logoSmall', value);
    const logoLarge = this.getLogoIfValid('logoLarge', value);
    return logoSmall || logoLarge
      ? this.cooperativeService.uploadLogos(coopId, logoSmall, logoLarge)
      : EMPTY;
  }

  private getLogoIfValid(dataField, value) {
    return value[dataField] && this.selectedLogos[dataField].isValid
      ? this.selectedLogos[dataField].content[0]
      : null;
  }

  private preFormatValue(value) {
    const patchData = Object.assign({}, value);
    delete patchData['logoSmall'];
    delete patchData['logoLarge'];
    return patchData;
  }

  clearFiles() {
    this.selectedLogos = {
      logoSmall: { content: [], isValid: true, errorObject: {} },
      logoLarge: { content: [], isValid: true, errorObject: {} },
    };
  }

  onEditingStart() {
    this.formMode = 'EDIT';
    this.clearFiles();
  }

  onInitNewRow() {
    this.formMode = 'ADD';
    this.clearFiles();
  }

  hasLogo(data) {
    if (this.formMode === 'EDIT') {
      if (data.column.dataField === 'logoSmall') {
        return data.data.hasLogoSmall;
      } else if (data.column.dataField === 'logoLarge') {
        return data.data.hasLogoLarge;
      }
    }
    return false;
  }

  validateImage(e, data) {
    const dataField = data.column.dataField; // dataField can be logoSmall or logoLarge

    // Create an Image object to check the logo dimension to upload.
    const url = URL.createObjectURL(e.value[0]);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      if (img.width > this.logoMaxSize[dataField] || img.height > this.logoMaxSize[dataField]) {
        this.selectedLogos[dataField].errorObject = {
          message:
            'Le logo dépasse la dimension max : ' +
            this.logoMaxSize[dataField] +
            ' x ' +
            this.logoMaxSize[dataField] +
            ' px',
        };
        this.selectedLogos[dataField].isValid = false;
      } else {
        this.selectedLogos[dataField].isValid = true;
        // Hack to force update method to be called. If no changes are detected, the updateCooperative isn't called.
        // So, we must remove this datafield to patched data.
        data.setValue('changed');
      }
    };
  }

  getLogosUrl(data) {
    // Hack to considere that url has changed, and so reload <img>.
    // data.id is dx id generated when cooperatives are loaded.
    // When a cooperative is patched or added, all cooperative are reload and a new dx id is set.
    // If we keep initial href, the initial logo is cached and shown until the page is refreshed.
    return data.data._links[data.column.dataField].href + '?nocache=' + data.id;
  }
}
