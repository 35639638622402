import { Component, OnInit } from '@angular/core';
import { LogbookService, TimeService } from 'src/app/shared';
import { DxDatasourceCreatorService, mapErrorToMsg } from 'src/app/core';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-logbook-daily',
  templateUrl: './logbook-daily.component.html',
  styleUrls: ['./logbook-daily.component.scss'],
})
export class LogbookDailyComponent implements OnInit {
  dataSource: DataSource;

  constructor(
    private logbookService: LogbookService,
    private datasourceCreator: DxDatasourceCreatorService,
    private timeService: TimeService
  ) {
    this.calculateDate = this.calculateDate.bind(this);
  }

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.logbookService
          .getLogbooks(searchParams.httpParams)
          .pipe(mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')),
    });
  }

  calculateDate(logbook) {
    return this.timeService.utcToTimeZone(logbook.date);
  }
}
