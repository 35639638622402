import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Campaign, ArvalisService } from 'src/app/shared';

@Component({
  selector: 'app-arvalis-export-total-ndose-modal',
  templateUrl: './arvalis-export-total-ndose-modal.component.html',
  styleUrls: ['./arvalis-export-total-ndose-modal.component.scss'],
})
export class ArvalisExportTotalNdoseModalComponent implements OnInit {
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();

  campaign: Campaign;

  onDisposing = this.closeModal.bind(this);
  onExport = this.exportTotalDose.bind(this);

  constructor(private arvalisService: ArvalisService) {}

  ngOnInit() {}

  exportTotalDose() {
    this.arvalisService.exportNDoseStatsForCampaign(this.campaign.id).subscribe(() => {
      this.closeModal();
    });
  }

  closeModal() {
    // Clear values to have empty fields the next time we open the modal
    this.campaign = null;
    this.isOpenChange.emit(false);
  }

  isFormValid() {
    return this.campaign;
  }
}
