import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export class Item {
  label: String;
  icon: IconDefinition;
  routePath: String;
  tooltip: String;

  constructor(label: String, icon: IconDefinition, routePath: String, tooltip: String = null) {
    this.label = label;
    this.icon = icon;
    this.routePath = routePath;
    this.tooltip = tooltip;
  }
}
