import { Entity } from 'src/app/core/hal';
import { HttpMappingParam } from '.';

export class HttpMappingParams {
  mappings: HttpMappingParam<Entity>[] = [];

  constructor(httpMappingParamArray: HttpMappingParam<Entity>[]) {
    httpMappingParamArray.forEach(p => this.addMapping(p));
  }

  addMapping<E extends Entity>(httpMappingParam: HttpMappingParam<E>): HttpMappingParams {
    this.mappings.push(httpMappingParam);
    return this;
  }
}
