import { LinkResource } from 'src/app/core';

export class Soil extends LinkResource {
  id: string;
  label: string;
  code: string;
  originDataBase: string;
  region: string;
  arvalisCode: string;

  constructor(soilData?: any) {
    super(soilData);
    if (soilData) {
      this.id = soilData.id;
      this.label = soilData.label;
      this.code = soilData.code;
      this.originDataBase = soilData.originDataBase;
      this.region = soilData.region;
      this.arvalisCode = soilData.arvalisCode;
    }
  }
}
