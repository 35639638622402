import { LinkResource } from 'src/app/core';

export class InputMode extends LinkResource {
  id: string;
  label: string;
  code: string;
  solidCompliant: boolean;

  constructor(inputModeData?: any) {
    super(inputModeData);
    if (inputModeData) {
      this.id = inputModeData.id;
      this.label = inputModeData.label;
      this.code = inputModeData.code;
      this.solidCompliant = inputModeData.solidCompliant;
    }
  }
}
