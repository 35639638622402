import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ResponseEntities, toResponseEntities } from 'src/app/core/hal';
import { tapLogError } from 'src/app/core';
import { User } from './user.model';

@Injectable()
export class UserService {
  fsUserApi = environment.api + '/fs-core/api/';

  constructor(private httpClient: HttpClient) {}

  getPaginedUsers<T extends User>(
    type: { new (...args: any[]): T },
    searchParams: {
      httpParams: HttpParams;
      projection: string;
    },
    endpoint: string
  ): Observable<ResponseEntities<T>> {
    const params = searchParams.httpParams.set('projection', searchParams.projection);
    return this.httpClient.get<ResponseEntities<T>>(this.fsUserApi + endpoint, { params }).pipe(
      toResponseEntities(type),
      shareReplay(1)
    );
  }

  updateUser<T extends User>(
    userId: number,
    patchData,
    type: { new (...args: any[]): T },
    endpoint: string
  ): Observable<T> {
    const urlUsers = this.fsUserApi + endpoint + '/' + userId;
    return this.httpClient.patch<T>(urlUsers, patchData).pipe(
      map(a => new type(a)),
      tapLogError('updateUser'),
      shareReplay(1)
    );
  }

  addUser<T extends User>(
    userData: any,
    type: { new (...args: any[]): T },
    endpoint: string
  ): Observable<T> {
    const urlUsers = this.fsUserApi + endpoint;
    return this.httpClient.post<T>(urlUsers, new type(userData)).pipe(
      map(a => new type(a)),
      tapLogError('addUser'),
      shareReplay(1)
    );
  }

  deleteUser<T extends User>(userId: number, endpoint: string) {
    const urlUsers = this.fsUserApi + endpoint + '/' + userId;
    return this.httpClient.delete<T>(urlUsers).pipe(
      tapLogError('deleteUser'),
      shareReplay(1)
    );
  }
}
