import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Campaign } from './campaign.model';
import { map, shareReplay } from 'rxjs/operators';
import { EntityProviderService, ResponseEntities, tapLogError } from 'src/app/core';
import { toResponseEntities } from 'src/app/core/hal';

@Injectable()
export class CampaignService {
  public campaigns: Campaign[];
  relativeUrl = '/fs-core/api/campaigns';
  baseUrl = environment.api + this.relativeUrl;

  httpPost = this.entityProviderService.httpPost(Campaign, this.relativeUrl + '/from-dto');
  httpGet = this.entityProviderService.httpGetter(Campaign, this.relativeUrl);
  httpPatch = this.entityProviderService.httpPatch(Campaign, this.relativeUrl + '/from-dto');

  constructor(
    private httpClient: HttpClient,
    private entityProviderService: EntityProviderService
  ) {}

  getCampaignById(id: string): Observable<Campaign> {
    return this.httpClient.get<Observable<Campaign>>(this.baseUrl + '/' + id).pipe(
      map(a => new Campaign(a)),
      tapLogError('getCampaignById')
    );
  }

  getActiveCampaigns(projection: string): Observable<ResponseEntities<Campaign>> {
    const params = { active: 'true', projection, sort: 'year,desc' };
    return this.httpClient.get<ResponseEntities<Campaign>>(this.baseUrl, { params }).pipe(
      toResponseEntities(Campaign),
      shareReplay(1)
    );
  }

  getAllCampaigns(projection: string): Observable<ResponseEntities<Campaign>> {
    const params = { projection };
    return this.httpClient.get<ResponseEntities<Campaign>>(this.baseUrl, { params }).pipe(
      toResponseEntities(Campaign),
      shareReplay(1)
    );
  }
}
