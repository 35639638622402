import { LinkResource } from 'src/app/core';

export class Variety extends LinkResource {
  id: string;
  label: string;
  code: number;

  constructor(varietyData?: any) {
    super(varietyData);
    if (varietyData) {
      this.id = varietyData.id;
      this.label = varietyData.label;
      this.code = varietyData.code;
    }
  }
}
