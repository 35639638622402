import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/internal/operators';
import { ResponseEntities, tapLogError, toResponseEntities } from 'src/app/core';
import { RegisteredFarmer } from './registered-farmer.model';

@Injectable()
export class RegisteredFarmerService {
  constructor(private httpClient: HttpClient) {}

  getRegisteredFarmerByFarmerId(farmerId: string): Observable<RegisteredFarmer> {
    const farmerIdUrl = environment.api + '/fs-core/api/registeredFarmers/search/findByFarmerId';
    return this.httpClient.get<RegisteredFarmer>(farmerIdUrl, { params: { id: farmerId } }).pipe(
      map(a => new RegisteredFarmer(a)),
      tapLogError('getRegisteredFarmer'),
      shareReplay(1)
    );
  }

  getByHttpParams(params: HttpParams): Observable<ResponseEntities<RegisteredFarmer>> {
    const url = environment.api + '/fs-core/api/registeredFarmers';
    return this.httpClient.get<ResponseEntities<RegisteredFarmer>>(url, { params }).pipe(
      toResponseEntities(RegisteredFarmer),
      shareReplay(1)
    );
  }
}
