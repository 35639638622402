import { Component, Input, ViewChild } from '@angular/core';
import { isNumber } from 'util';

@Component({
  selector: 'app-progress-bar-indicator',
  templateUrl: './progress-bar-indicator.component.html',
  styleUrls: ['./progress-bar-indicator.component.scss'],
})
export class ProgressBarIndicatorComponent {
  @Input() max: number;
  @Input() value: number;
  @Input() error: number;
  @Input() hideTooltip: boolean;
  @Input() errorRouterLink: any[] | string;
  @ViewChild('textIndicator') textIndicator;
  @ViewChild('barIndicator') barIndicator;
  @ViewChild('textIndicatorError') textIndicatorError;
  @ViewChild('barIndicatorError') barIndicatorError;

  constructor() {}

  getTooltip() {
    let tooltip = `${this.value} / ${this.max}`;
    if (this.error) {
      tooltip += ' (' + this.error + ' erreur' + (this.error > 1 ? 's' : '') + ')';
    }
    return tooltip;
  }

  getPourcent() {
    return this.max ? Math.round((this.value * 100) / this.max) : 0;
  }

  getIndiatorText() {
    return this.max && isNumber(this.value) && isNumber(this.max) ? this.getPourcent() + ' %' : '';
  }

  getColor() {
    return this.textIndicator.nativeElement.clientWidth >
      this.barIndicator.nativeElement.clientWidth
      ? 'black'
      : 'white';
  }

  getPourcentError() {
    return this.max ? Math.round((this.error * 100) / this.max) : 0;
  }

  getIndiatorTextError() {
    return this.max && isNumber(this.error) && isNumber(this.max)
      ? this.getPourcentError() + ' %'
      : '';
  }

  getColorError() {
    return this.textIndicatorError.nativeElement.clientWidth >
      this.barIndicatorError.nativeElement.clientWidth
      ? 'black'
      : 'white';
  }
}
