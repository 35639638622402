import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ArvalisStageFile } from 'src/app/shared/arvalis/arvalis-stage-file.model';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';
import { environment } from 'src/environments/environment';
import { ResponseEntities } from 'src/app/core';
import { toResponseEntities } from 'src/app/core/hal';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ArvalisService {
  fsArvalisApi = environment.api + '/fs-connector-arvalis/api';

  constructor(private httpClient: HttpClient, private fileDownloader: FileDownloaderService) {}

  getPaginatedStageFiles(searchParams: {
    httpParams: HttpParams;
    projection: string;
  }): Observable<ResponseEntities<ArvalisStageFile>> {
    const stageFilesUrl = this.fsArvalisApi + '/stageFiles';
    const params = searchParams.httpParams.set('projection', searchParams.projection);
    return this.httpClient.get<ResponseEntities<ArvalisStageFile>>(stageFilesUrl, { params }).pipe(
      toResponseEntities(ArvalisStageFile),
      shareReplay(1)
    );
  }

  uploadStageFile(file: File) {
    const uploadUrl = this.fsArvalisApi + '/stages/upload';
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<any>(uploadUrl, formData);
  }

  exportData(): Observable<any> {
    const exportUrl = this.fsArvalisApi + '/exportClients';
    return this.fileDownloader.downloadFileFromGet('Export_Arvalis.zip', exportUrl);
  }

  exportNDoseStatsForCampaign(campaignId: string): Observable<any> {
    const exportUrl = this.fsArvalisApi + '/exportNDoseStatsForCampaign';
    let params = new HttpParams();
    params = params.set('campaignId', campaignId);
    return this.fileDownloader.downloadFileFromGet('Export_dose_totale.csv', exportUrl, params);
  }
}
