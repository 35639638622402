import { LinkResource } from 'src/app/core';

export class GrasslandType extends LinkResource {
  id: string;
  label: string;
  code: string;

  constructor(grasslandTypeData?: any) {
    super(grasslandTypeData);
    if (grasslandTypeData) {
      this.id = grasslandTypeData.id;
      this.label = grasslandTypeData.label;
      this.code = grasslandTypeData.code;
    }
  }
}
