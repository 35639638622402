import { FileDownloaderService } from './../../../shared/files/file-downloader.service';
import { RegisteredParcelService } from './../../../shared/registered-parcel/registered-parcel.service';
import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Component, OnChanges, Input } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  NDoseComputationMethod,
  Parcel,
  ParcelService,
  PieChartService,
  RegisterParcelService,
  TotalnRecommendationService,
} from 'src/app/shared';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';
import * as _ from 'lodash';
import PieChart from 'devextreme/viz/pie_chart';
import { LatestErrorsService } from '../../../shared/latest-errors/latest-errors.service';

@Component({
  selector: 'app-nitrogen-status',
  templateUrl: './nitrogen-status.component.html',
  styleUrls: ['./nitrogen-status.component.scss'],
})
export class NitrogenStatusComponent implements OnChanges {
  constructor(
    private parcelService: ParcelService,
    private pieChartService: PieChartService,
    private registerParcelService: RegisterParcelService,
    private totalnRecommendationService: TotalnRecommendationService,
    private httpMappingService: HttpMappingService,
    private toastrService: ToastrService,
    private latestErrorsService: LatestErrorsService,
    private registeredParcelService: RegisteredParcelService,
    private fileDownloaderService: FileDownloaderService
  ) {}
  @Input() parcelId: string;
  parcelCode: String;
  icons = faIcons;
  parcel$: Observable<Parcel>;
  hasTotalnReport = false;

  // Charts
  parcelPieChart: any[];
  totalMineralization: any;

  pieChartInstance: PieChart;

  savePieChartInstance = (e => {
    this.pieChartInstance = e.component;
  }).bind(this);

  customizeLegend = ((dxChartObject): string => {
    const percentValue = this.pieChartInstance.getAllSeries()[0].getAllPoints()[
      dxChartObject.pointIndex
    ].data.value;
    return dxChartObject.pointName + ` (${percentValue} %)`;
  }).bind(this);

  ngOnChanges() {
    if (this.parcelId) {
      this.loadNitrogenStatus();
      this.checkTotalnReport();
    }
  }

  loadNitrogenStatus() {
    this.parcel$ = this.parcelService.getParcelById(this.parcelId, 'card-nitro-status').pipe(
      this.httpMappingService.mapEntity({
        pathToIdInInput: 'agroData.ndoseComputationMethodRefId',
        pathToDataInOutput: 'ndoseComputationMethod',
        projection: 'label',
        targetType: NDoseComputationMethod,
      }),
      tap(this.initParcelData.bind(this)),
      tap(this.initGraphData.bind(this)),
      tap(this.totalMineralizationData.bind(this))
    );
  }

  initParcelData(parcel: Parcel) {
    console.log(parcel);
    this.parcelCode = parcel.code;
  }

  checkTotalnReport() {
    this.totalnRecommendationService
      .recommendationExistsForParcel(this.parcelId)
      .subscribe(hasReport => (this.hasTotalnReport = hasReport));
  }

  refreshNitrogenBalance() {
    console.log('refresh');
    this.registerParcelService.getRegisterParcel(this.parcelId).subscribe(
      () => {
        this.loadNitrogenStatus();
      },
      () => {
        this.registeredParcelService
          .getRegisteredParcel(this.parcelId)
          .subscribe(registeredParcel => {
            const httpParams = new HttpParams();
            this.latestErrorsService
              .getLatestErrorParcelByHttpParams(httpParams, registeredParcel[0].id)
              .subscribe(e => {
                const fileName = 'ErrorsFw_' + this.parcelCode + '.zip';
                this.toastrService
                  .error(
                    "Une erreur est survenue lors du calcul de la dose totale. Pour plus d'informations, cliquez ici.",
                    'Erreur Fertiweb',
                    { timeOut: 10000 }
                  )
                  .onTap.subscribe(onTapEvent =>
                    this.fileDownloaderService.downloadFileFromGet(
                      fileName,
                      e[0]._links.download.href,
                      null
                    )
                  );
              });
          });
      },
      () => {
        this.toastrService.success('La dose totale a été recalculée');
      }
    );
  }

  downloadReport() {
    this.totalnRecommendationService.downloadParcelReport(this.parcelId);
  }

  // Charts

  initGraphData(parcel: Parcel) {
    const translationDictionnary = {
      humusMineralizationEffect: 'Effet humus',
      previousCropMinEffect: 'Effet précédent',
      grasslandEffect: 'Arrière effet prairie',
      catchCropEffect: 'Effet CIPAN',
      irrigationEffect: "Apport de l'eau d'irrigation",
      mesuredNCredit: 'Reliquat sortie hiver',
      nabsBalanceBegining: 'Azote moyen absorbé sortie hiver',
    };
    const fieldToDisplay = [
      'humusMineralizationEffect',
      'grasslandEffect',
      'catchCropEffect',
      'irrigationEffect',
      'mesuredNCredit',
    ];
    let chartValues = <any>_.pick(parcel.agroData.nitrogenStatus, fieldToDisplay);
    chartValues = this.pieChartService.removeZeroAndNullValues(chartValues);
    const chartValuesAsPercentage = this.pieChartService.convertAsPercent(chartValues);
    const dataSource = this.pieChartService.createPieChartDataSource(chartValuesAsPercentage);
    this.parcelPieChart = this.pieChartService.translateDataSource(
      translationDictionnary,
      dataSource
    );
  }

  totalMineralizationData(parcel: Parcel) {
    const translationDictionnary = {
      mineralization: 'Minéralisation',
      other: 'Autres fournitures',
      nitrogen: 'Azote moyen absorbé sortie hiver',
    };
    const fieldsToSum = {
      mineralization: ['humusMineralizationEffect', 'grasslandEffect', 'catchCropEffect'],
      other: ['ncreditBalanceBegining', 'irrigationEffect'],
    };

    const fieldsSum = this.pieChartService.reduceValuesByFields(fieldsToSum, <any>(
      parcel.agroData.nitrogenStatus
    ));
    const fieldsSumAsPercentage = this.pieChartService.convertAsPercent(fieldsSum);
    const dataSource = this.pieChartService.createPieChartDataSource(fieldsSumAsPercentage);
    this.totalMineralization = this.pieChartService.translateDataSource(
      translationDictionnary,
      dataSource
    );
  }

  withPercentString(dxChartObject): string {
    return `${dxChartObject.value} %`;
  }

  generatePdf() {
    this.parcelService.generatePdf(this.parcelId).subscribe(() => (this.hasTotalnReport = true));
  }
}
