export const environment = {
  production: true,
  // URL DU CLUSTER K8S : Ex: https://34.76.133.201
  api: 'https://agri.geoapi-airbusds.com/api/v1',
  debugRoutes: false,
  authentication: {
    // ADSGEO_APPENGIN_URL Ex: dot-agri4-209909.appspot.com
    // TPL_APP_ENGINE_SERVICE_NAME ex: 1-0-1 <== Version with dash instead of point
    //  Cette IP/HOST doit etre whitelisté sur idp
    front: 'https://manager.myfarmstar.com',
    // IDP CIBLE Ex: https://authenticate-int.idp.private.geoapi-airbusds.com/auth/realms/IDP
    authority: 'https://authenticate.oneatlas.airbus.com/auth/realms/IDP',
    // à Templatiser pour que tout requiert authent coté front Ex: true|false
    guardAllRoutes: true,
  },
};
