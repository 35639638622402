import { NgModule } from '@angular/core';
import { ParcelCardComponent } from './parcel-card.component';
import { SharedModule } from 'src/app/shared';
import { CropInfosComponent } from './crop-infos/crop-infos.component';
import { ParcelDetailsComponent } from './parcel-details/parcel-details.component';
import { NitrogenStatusComponent } from './nitrogen-status/nitrogen-status.component';
import { ParcelStagesComponent } from './parcel-stages/parcel-stages.component';

@NgModule({
  declarations: [
    ParcelCardComponent,
    CropInfosComponent,
    ParcelDetailsComponent,
    NitrogenStatusComponent,
    ParcelStagesComponent,
  ],
  imports: [SharedModule],
  exports: [ParcelCardComponent],
  providers: [],
})
export class ParcelCardModule {}
