import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/internal/operators';
import { ResponseEntities, tapLogError, toResponseEntities } from 'src/app/core';
import { RegisteredTechnician } from './registered-technician.model';

@Injectable()
export class RegisteredTechnicianService {
  constructor(private httpClient: HttpClient) {}

  getRegisteredTechnicianByTechnicianId(technicianId: string): Observable<RegisteredTechnician> {
    const technicianIdUrl =
      environment.api + '/fs-core/api/registeredTechnicians/search/findByTechnicianId';
    return this.httpClient
      .get<RegisteredTechnician>(technicianIdUrl, { params: { id: technicianId } })
      .pipe(
        map(a => new RegisteredTechnician(a)),
        tapLogError('getRegisteredTechnicians'),
        shareReplay(1)
      );
  }

  getByHttpParams(params: HttpParams): Observable<ResponseEntities<RegisteredTechnician>> {
    const url = environment.api + '/fs-core/api/registeredTechnicians';
    return this.httpClient.get<ResponseEntities<RegisteredTechnician>>(url, { params }).pipe(
      toResponseEntities(RegisteredTechnician),
      shareReplay(1)
    );
  }

  getEmbeddedregisteredFarmerByTechnicianId(
    technicianId: string
  ): Observable<RegisteredTechnician> {
    const technicianIdUrl =
      environment.api + '/fs-core/api/registeredTechnicians/search/findByTechnicianId';
    return this.httpClient
      .get<RegisteredTechnician>(technicianIdUrl, { params: { id: technicianId } })
      .pipe(
        map(a => new RegisteredTechnician(a)),
        map(a => a.registeredFarmers),
        tapLogError('getEmbeddedregisteredFarmerByTechnicianId'),
        shareReplay(1)
      );
  }
}
