import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { GrasslandType } from './grassland-type.model';

@Injectable({
  providedIn: 'root',
})
export class GrasslandTypeService {
  constructor(private httpClient: HttpClient) {}

  getGrasslandTypeById(id: string): Observable<GrasslandType> {
    const grasslandTypeIdUrl = environment.api + '/fs-agro-datum/api/grasslandTypes/' + id;
    return this.httpClient.get<Observable<GrasslandType>>(grasslandTypeIdUrl).pipe(
      map(a => new GrasslandType(a)),
      tapLogError('getGrasslandTypeById')
    );
  }

  getGrasslandTypeFromParcel(parcel$: Observable<Parcel>): Observable<GrasslandType> {
    return parcel$.pipe(
      map(a => a.agroData.activities.grasslandActivity.grasslandTypeRefId),
      flatMap(grasslandTypeRefId => this.getGrasslandTypeById(grasslandTypeRefId)),
      shareReplay(1)
    );
  }
}
