import { Component, OnInit, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { tap } from 'rxjs/operators';
import { FarmerService, Farmer, Cooperative } from 'src/app/shared';
import { mapErrorToMsg } from 'src/app/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { HttpParams } from '@angular/common/http';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';

@Component({
  selector: 'app-farmers-list',
  templateUrl: './farmers-list.component.html',
  styleUrls: ['./farmers-list.component.scss'],
})
export class FarmersListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  dataSource: DataSource;
  totalNbFarmers: number;

  constructor(
    private farmerService: FarmerService,
    private datasourceCreator: DxDatasourceCreatorService,
    private httpMappingService: HttpMappingService
  ) {}

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.farmerService.getByHttpParams(searchParams.httpParams).pipe(
          this.httpMappingService.mapEntities({
            keyToSearchInGetter: 'farmer.id',
            pathToDataInOutput: 'registeredFarmerData',
            projection: 'farmerList',
            relativeApiUrl: '/fs-core/api/registeredFarmers',
          }),
          this.httpMappingService.mapEntities({
            pathToIdInInput: 'registeredFarmerData.registeredCooperative.cooperativeRefId',
            pathToDataInOutput: 'cooperativeData',
            projection: 'dropdown',
            targetType: Cooperative,
          }),
          tap(response => (this.totalNbFarmers = response.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ httpParams: new HttpParams().set('projection', 'list') }),
    });
  }

  fullNameColumn_calculateCellValue(farmerRow: Farmer) {
    return farmerRow.getFullName();
  }

  exportDataGrid() {
    this.dataGrid.instance.exportToExcel(false);
  }
}
