import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PieChartService {
  constructor() {}

  /**
   * Convert an object to pie chart datasource format
   */
  createPieChartDataSource(values: { [k: string]: number }): any[] {
    return Object.entries(values).map(data => {
      const key = data[0];
      const value = <number>data[1];
      return { key, value };
    });
  }

  /**
   * Sum values of fields by group
   */
  reduceValuesByFields(
    fieldsToSum: { [k: string]: string[] },
    values: { [k: string]: number }
  ): { [k: string]: number } {
    const result: { [k: string]: number } = {};
    Object.keys(fieldsToSum).forEach(fieldKey => {
      const fieldsNameToSum = fieldsToSum[fieldKey];
      result[fieldKey] = fieldsNameToSum
        .map(fieldName => {
          return values[fieldName];
        })
        .reduce((a, b) => a + b);
    });
    return result;
  }

  /**
   * Convert chart value to percentage
   */
  convertAsPercent(values: { [k: string]: number }): { [k: string]: number } {
    const total = Object.values(values).reduce((a, b) => a + b, 0);
    Object.keys(values).forEach(key => {
      const valueNumber = values[key];
      const percentageValueNumber = Math.round((valueNumber / total) * 100);
      values[key] = percentageValueNumber;
    });
    return values;
  }

  /**
   * Remove null values and zero values
   */
  removeZeroAndNullValues(values: { [k: string]: number }): { [k: string]: number } {
    Object.keys(values).forEach(key => {
      const valueNumber = values[key];
      if (valueNumber <= 0 || !valueNumber) {
        delete values[key];
      }
    });
    return values;
  }

  /**
   * Translate the chart based on dictionnary
   */
  translateDataSource(dictionnary: { [k: string]: string }, dataSource: any[]): any[] {
    return dataSource.map(sourceField => {
      sourceField.key = dictionnary[sourceField.key];
      return sourceField;
    });
  }
}
