import { AuthGuard } from '@fret-ngx/aaa';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * Used as @fret-ngx/aaa AuthGuard wrapper
 */
@Injectable({
  providedIn: 'root',
})
export class MyAuthGuard implements CanActivate {
  constructor(private adsAuthGuard: AuthGuard) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.adsAuthGuard.canActivate(route, state);
  }
}
