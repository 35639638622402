import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { PreviousCropWasteUsage } from './previous-crop-waste-usage.model';

@Injectable({
  providedIn: 'root',
})
export class PreviousCropWasteUsageService {
  constructor(private httpClient: HttpClient) {}

  getPreviousCropWasteUsageById(id: string): Observable<PreviousCropWasteUsage> {
    const previousCropWasteUsageByIdUrl =
      environment.api + '/fs-agro-datum/api/previousCropWasteUsages/' + id;
    return this.httpClient
      .get<Observable<PreviousCropWasteUsage>>(previousCropWasteUsageByIdUrl)
      .pipe(
        map(a => new PreviousCropWasteUsage(a)),
        tapLogError('getPreviousCropWasteUsageById')
      );
  }

  getPreviousCropWasteUsageFromParcel(
    parcel$: Observable<Parcel>
  ): Observable<PreviousCropWasteUsage> {
    return parcel$.pipe(
      map(a => a.agroData.activities.previous.previousCropWasteUsageRefId),
      flatMap(previousCropWasteUsageRefId =>
        this.getPreviousCropWasteUsageById(previousCropWasteUsageRefId)
      ),
      shareReplay(1)
    );
  }

  getAntepreviousPreviousCropWasteUsageFromParcel(
    parcel$: Observable<Parcel>
  ): Observable<PreviousCropWasteUsage> {
    return parcel$.pipe(
      map(a => a.agroData.activities.anteprevious.previousCropWasteUsageRefId),
      flatMap(previousCropWasteUsageRefId =>
        this.getPreviousCropWasteUsageById(previousCropWasteUsageRefId)
      ),
      shareReplay(1)
    );
  }
}
