import { Entity, LinkResource } from 'src/app/core/hal';

export class ArvalisStageFile extends LinkResource implements Entity {
  id: string;
  name: string;
  importDate: Date;
  status: boolean;

  getRelativeApiUrl = () => '/fs-connector-arvalis/api/stageFiles';

  constructor(stageFile: any) {
    super(stageFile);
    if (stageFile) {
      this.id = stageFile.id;
      this.name = stageFile.name;
      this.importDate = stageFile.importDate;
      this.status = stageFile.status;
    }
  }
}
