import { Entity, LinkResource } from 'src/app/core/hal';

export class TotalNDeliveredRecommendation extends LinkResource implements Entity {
  id: string;

  getRelativeApiUrl = () => '/fs-totaln/api/totalNDeliveredRecommendations';

  constructor(campaignData?: any) {
    super(campaignData);
    if (campaignData) {
      Object.assign(this, campaignData);
    }
  }
}
