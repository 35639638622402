import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared';
import { TechniciansListComponent } from './technicians-list.component';

@NgModule({
  declarations: [TechniciansListComponent],
  imports: [SharedModule],
  exports: [TechniciansListComponent],
  providers: [],
})
export class TechniciansListModule {}
