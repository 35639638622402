import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DifferenceService {
  difference(base, object) {
    const diff = _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key]) ? this.difference(value, base[key]) : value;
      }
    });
    const removedKey = _.difference(Object.keys(base), Object.keys(object));
    if (removedKey.length) {
      diff['__removed'] = removedKey.map(key => base[key]);
    }
    return diff;
  }
}
