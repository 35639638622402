import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CooperativeService } from 'src/app/shared/cooperative/cooperative.service';
import { RegisteredCooperativeService } from 'src/app/shared/registered-cooperative/registered-cooperative.service';
import { Cooperative } from 'src/app/shared/cooperative/cooperative.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-cooperative-select',
  templateUrl: './cooperative-select.component.html',
  styleUrls: ['./cooperative-select.component.scss'],
})
export class CooperativeSelectComponent implements OnInit, OnChanges {
  @Input() value: Cooperative;
  @Output() valueChange = new EventEmitter<Cooperative>();

  @Input() campaign: string;

  cooperatives$: Observable<Cooperative[]>;

  constructor(
    private cooperativeService: CooperativeService,
    private registeredCooperativeService: RegisteredCooperativeService,
    private httpMappingService: HttpMappingService
  ) {}

  ngOnInit() {
    if (this.campaign) {
      this.filterByCampaign();
    } else {
      this.cooperatives$ = this.cooperativeService.getAllCooperatives('dropdown');
    }
  }

  ngOnChanges(changes) {
    if (changes.campaign && this.campaign) {
      this.filterByCampaign();
    }
  }

  filterByCampaign() {
    const params = new HttpParams()
      .set('campaign.id', this.campaign)
      .set('projection', 'cooperative');
    this.cooperatives$ = this.registeredCooperativeService.httpGet(params).pipe(
      map(responseEntity => responseEntity.getAllEmbedded()),
      map(registeredCoops =>
        _.sortedUniqBy(
          _.sortBy(registeredCoops.map(rc => new Cooperative(rc.cooperative)), 'name'),
          'id'
        )
      )
    );
  }
}
