import { Component } from '@angular/core';
import { ArvalisService } from 'src/app/shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-arvalis-export',
  templateUrl: './arvalis-export.component.html',
  styleUrls: ['./arvalis-export.component.scss'],
})
export class ArvalisExportComponent {
  exportRequest$: Observable<any>;
  exportStatsModalOpen = false;
  constructor(private arvalisService: ArvalisService) {}

  export() {
    this.exportRequest$ = this.arvalisService.exportData();
  }
}
