import { HttpParams } from '@angular/common/http';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import * as _ from 'lodash';
import { Entity, getAllEmbedded, ResponseEntities } from 'src/app/core/hal';
import { HttpMappingParams } from './http-mapping-params.model';
import { HttpMappingParam } from './http-mapping-param.model';
import { environment } from 'src/environments/environment';

// see http-mapping/doc/http-mapping.operator.md to have doc
// this operator take one or several HttpMappingParam and call the HttpMappingProcessor::process
// method with the source observable and this params as HttpMappingParams object
export function httpMapping<E extends Entity>(
  ...httpMappingParamArray: HttpMappingParam<Entity>[]
): OperatorFunction<ResponseEntities<E>, ResponseEntities<E>> {
  return source$ =>
    new HttpMappingProcessor<E>(source$, new HttpMappingParams(httpMappingParamArray)).process(
      false
    );
}

// quick hack to use HttpMappingProcessor implementation with simple Entity instead of ResponseEntities
export function httpMappingEntity<E extends Entity>(
  ...httpMappingParamArray: HttpMappingParam<Entity>[]
): OperatorFunction<E, E> {
  return source$ =>
    new HttpMappingProcessor<E>(
      source$.pipe(map(entity => new ResponseEntities(null, { _embedded: { data: [entity] } }))),
      new HttpMappingParams(httpMappingParamArray)
    )
      .process(true)
      .pipe(map(resp => resp._embedded.data[0]));
}

// find id as UUID or string or number... at the ends of URLs
const endIdRegex = /[^/=]+$/;

interface UrlParsingResult {
  baseUrl: string;
  id: string;
  isSimple?: boolean;
  key?: string;
}

function getUrl(el, linkToResource: string) {
  const url = _.get(el, linkToResource + '.href');
  return url ? url.replace(/\{\?projection\}$/, '') : null;
}

function parseUrl(el, linkToResource: string): UrlParsingResult {
  const url: string = getUrl(el, linkToResource);
  if (url) {
    const idsFound = url.match(endIdRegex);
    if (idsFound) {
      const baseUrl = url.replace(endIdRegex, '');
      const result: UrlParsingResult = {
        baseUrl,
        id: idsFound[0],
        isSimple: /\/$/.test(baseUrl),
      };
      const foundKey = baseUrl.match(/\?.*(?==)/);
      if (foundKey) {
        result.key = foundKey[0].substring(1);
        result.baseUrl = baseUrl.replace(new RegExp(`\\?${foundKey}=\$`), '');
      }
      return result;
    }
  }
  return { baseUrl: null, id: null };
}

class HttpMappingProcessor<E extends Entity> {
  isAnOnlyOneMapping: boolean;

  // take the source observable and the config
  constructor(
    private source$: Observable<ResponseEntities<E>>,
    private httpMappingParams: HttpMappingParams
  ) {}

  // take the source$, fetch required data, join the results, and return the result observable
  process(isAnOnlyOneMapping: boolean): Observable<ResponseEntities<E>> {
    this.isAnOnlyOneMapping = isAnOnlyOneMapping;
    return this.getObservableOfGetters().pipe(
      map(results => this.joinResults(results)),
      shareReplay(1)
    );
  }

  // return the observable of the required data
  getObservableOfGetters(): Observable<(ResponseEntities<Entity> | Entity[])[]> {
    // init the array of observable to combine : take the sources$ at index 0
    const observablesToCombine: any[] = [this.source$];
    // foreach mapping, add the data observable to array
    this.httpMappingParams.mappings.forEach((httpMappingParam: HttpMappingParam<Entity>) => {
      observablesToCombine.push(this.getObservableOfMapping<Entity>(httpMappingParam));
    });
    return combineLatest(observablesToCombine);
  }

  // return the observable of required data from the mapping
  getObservableOfMapping<T extends Entity>(
    httpMappingParam: HttpMappingParam<T>
  ): Observable<ResponseEntities<T> | T[]> {
    return this.source$.pipe(
      mergeMap(elements =>
        this.getMappingDataFromElements(getAllEmbedded(elements), httpMappingParam)
      ),
      shareReplay(1)
    );
  }

  // return an observable of data from links ; use the httpClient to fetch data
  getMappingDataFromElementsLinkToResource<T extends Entity>(
    elements,
    param: HttpMappingParam<T>
  ): Observable<ResponseEntities<T> | T[]> {
    const urlIdsMap = new Map<string, UrlParsingResult[]>();

    let baseUrlAreTheSame = true;
    let baseUrlCheck = null;

    elements.forEach(el => {
      const urlParsingResult: UrlParsingResult = parseUrl(el, param.linkToResource);
      if (urlParsingResult.id) {
        if (!urlIdsMap.has(urlParsingResult.baseUrl)) {
          urlIdsMap.set(urlParsingResult.baseUrl, []);
        }
        urlIdsMap.get(urlParsingResult.baseUrl).push(urlParsingResult);
      }
      if (baseUrlCheck == null) {
        baseUrlCheck = urlParsingResult.baseUrl;
      }

      // check all element baseUrl are the same
      if (urlParsingResult.baseUrl !== baseUrlCheck) {
        baseUrlAreTheSame = false;
      }
    });

    if (!this.isAnOnlyOneMapping && baseUrlAreTheSame && urlIdsMap.size === 1) {
      const [baseUrl, urlParsingResults] = urlIdsMap.entries().next().value;
      const ids = urlParsingResults.map(result => result.id);
      if (ids.length === 0) {
        throw new Error(`No ID found`);
      }

      if (urlParsingResults[0].isSimple || urlParsingResults[0].key) {
        const httpParamsArray = this.getHttpParamsFromIds(
          ids,
          urlParsingResults[0].key || param.getKeyToSearch(),
          param
        );
        if (urlParsingResults[0].key) {
          param.keyToSearchInGetter = urlParsingResults[0].key;
        }
        return this.mergeHttpGet(
          httpParamsArray.map(httpParams =>
            param.httpClient.get<ResponseEntities<T>>(baseUrl.replace(/\/$/, ''), {
              params: httpParams,
            })
          )
        ).pipe(
          map(res => new ResponseEntities<T>(param.targetType, res)),
          shareReplay(1)
        );
      } else {
        throw new Error('Unsupported url format');
      }
    } else {
      const urls = elements.map(el => getUrl(el, param.linkToResource));

      const arrayOfData$ = urls.map(url =>
        url
          ? param.httpClient
              .get(url, {
                params: param.getHttpParams(),
              })
              .pipe(
                map(a => (param.targetType ? new param.targetType(a) : a)),
                shareReplay(1)
              )
          : of(null)
      );
      return combineLatest(arrayOfData$);
    }
  }

  // FIXME : to refactor
  // return an observable of data from elements ids ; use the param.httpGetter to fetch data
  getMappingDataFromElements<T extends Entity>(
    elements,
    param: HttpMappingParam<T>
  ): Observable<ResponseEntities<T> | T[]> {
    if (elements.length === 0) {
      return of(param.linkToResource ? [] : new ResponseEntities(param.targetType, []));
    } else {
      if (param.linkToResource) {
        return this.getMappingDataFromElementsLinkToResource(elements, param);
      } else if (param.onGetByElement) {
        const arrayOfData$ = elements.map(el => {
          const httpParams: HttpParams = param
            .getHttpParams()
            .set(param.getKeyToSearch(), _.get(el, param.getPathToId()));
          if (param.httpGetter) {
            return param.httpGetter(httpParams);
          } else {
            return param.httpClient
              .get(environment.api + param.getRelativeApiUrl(), { params: httpParams })
              .pipe(
                map(a => (param.targetType ? new param.targetType(a) : a)),
                shareReplay(1)
              );
          }
        });
        return combineLatest(arrayOfData$);
      } else {
        const pathToId = param.getPathToId();
        // get all id inside elements
        const ids = elements.map(el => _.get(el, pathToId));
        // remove null and undefined  ids
        _.remove(ids, _.isNil);
        const httpParamsArray = this.getHttpParamsFromIds(ids, param.getKeyToSearch(), param);
        // call the getter to fetch data from the ids

        if (param.httpGetter) {
          return this.mergeHttpGet(
            httpParamsArray.map(httpParams => param.httpGetter(httpParams))
          ).pipe(shareReplay(1));
        } else {
          return this.mergeHttpGet(
            httpParamsArray.map(httpParams =>
              param.httpClient.get<ResponseEntities<T>>(
                environment.api + param.getRelativeApiUrl(),
                { params: httpParams }
              )
            )
          ).pipe(
            map(res => new ResponseEntities<T>(null, res)),
            shareReplay(1)
          );
        }
      }
    }
  }

  // take the original HttpParams and add all ids as "<keyToSearch> = <id>" param
  private getHttpParamsFromIds<T extends Entity>(
    ids: string[],
    keyToSearch: string,
    param: HttpMappingParam<T>
  ): HttpParams[] {
    const maxUrlLength = param.maxUrlLength || 3000;
    const httpParamsArray = [];
    let currentParams = param.getHttpParams();
    let currentSize = currentParams.toString().length;

    _.uniq(ids).map(id => {
      const paramSize = keyToSearch.length + 2 + id.length;
      if (maxUrlLength < currentSize + paramSize) {
        httpParamsArray.push(currentParams);
        currentParams = param.getHttpParams();
        currentSize = currentParams.toString().length;
      }
      currentParams = currentParams.append(keyToSearch, id);
      currentSize += paramSize;
    });
    httpParamsArray.push(currentParams);

    return httpParamsArray;
  }

  mergeHttpGet<T>(results$: Observable<ResponseEntities<T>>[]): Observable<ResponseEntities<T>> {
    return combineLatest(results$).pipe(
      map(results => {
        if (results.length <= 1) {
          return results[0];
        } else if (results && results.length > 0) {
          let embedded = [];
          results.forEach(result => (embedded = embedded.concat(getAllEmbedded(result))));
          const key = Object.keys(results[0]._embedded)[0];
          results[0]._embedded[key] = embedded;
          return results[0];
        }
      })
    );
  }

  // take all fetched data and join into the source object (at index 0 of gettersResults)
  joinResults(gettersResults: (ResponseEntities<Entity> | Entity[])[]): ResponseEntities<E> {
    // first items is the source observable result
    const aggregatedResults: ResponseEntities<E> = <ResponseEntities<E>>gettersResults[0];
    for (let i = 0; i < this.httpMappingParams.mappings.length; i++) {
      const getterResult = gettersResults[i + 1];

      const httpMappingParam = this.httpMappingParams.mappings[i];

      if (httpMappingParam.onGetByElement || this.isAnOnlyOneMapping) {
        const dataToMap: Entity[] =
          getterResult instanceof ResponseEntities
            ? <Entity[]>getAllEmbedded(<ResponseEntities<Entity>>getterResult)
            : <Entity[]>getterResult;

        getAllEmbedded(aggregatedResults).forEach((element, index) =>
          _.set(element, httpMappingParam.getPathToDataInOutput(), dataToMap[index])
        );
      } else {
        const dataToMap: ResponseEntities<Entity> = <ResponseEntities<Entity>>getterResult;

        // use the mapOfEntity to find the data inside result
        const mapOfEntity: EntityMap = this.getEntityMapFromMappingData(
          httpMappingParam,
          dataToMap
        );
        // for each source elements, insert the result found inside mapOfEntity : mapOfEntity.mapData
        getAllEmbedded(aggregatedResults).forEach(element =>
          mapOfEntity.mapData(element, httpMappingParam)
        );
      }
    }
    return aggregatedResults;
  }

  // build the map of results : id / data
  getEntityMapFromMappingData(
    httpMappingParam: HttpMappingParam<Entity>,
    dataToMap: ResponseEntities<Entity>
  ): EntityMap {
    // complex case : several results
    if (httpMappingParam.reverseMapping && httpMappingParam.reverseMapping.arrayOfEntities) {
      const mapOfEntity = new EntityMap();
      getAllEmbedded(dataToMap).forEach(el => {
        const pathToIdInArray =
          httpMappingParam.reverseMapping.arrayOfEntities.pathToIdInside || 'id';
        const arrayOfEntiesId = _.get(el, httpMappingParam.reverseMapping.arrayOfEntities.pathTo);
        arrayOfEntiesId.forEach(entity => {
          const fkid = _.get(entity, pathToIdInArray);
          mapOfEntity.put(httpMappingParam, fkid, el);
        });
      });
      return mapOfEntity;
    } else {
      if (httpMappingParam.reverseMapping && httpMappingParam.reverseMapping.useArrayInOutput) {
        const mapOfEntity = new EntityMap();
        const fkid = httpMappingParam.getKeyToSearch();
        getAllEmbedded(dataToMap).forEach(el => {
          mapOfEntity.put(httpMappingParam, _.get(el, fkid), el);
        });
        return mapOfEntity;
      } else {
        // simple case : build the map with data.keyToSearch : data
        return new EntityMap(_.keyBy(getAllEmbedded(dataToMap), httpMappingParam.getKeyToSearch()));
      }
    }
  }
}

class EntityMap {
  map: { [id: string]: Entity[] };

  constructor(mapOfEntity?) {
    this.map = mapOfEntity || {};
  }

  // add an element to map
  put(httpMappingParam: HttpMappingParam<Entity>, fkid, el) {
    if (httpMappingParam.reverseMapping.useArrayInOutput) {
      // complex case : several results
      if (!this.map[fkid]) {
        this.map[fkid] = [];
      }
      this.map[fkid].push(el);
    } else {
      this.map[fkid] = el;
    }
  }

  // find result in map and put it inside element, at httpMappingParam.pathToDataInOutput path
  mapData(element, httpMappingParam) {
    if (httpMappingParam.linkToResource) {
      const { id } = parseUrl(element, httpMappingParam.linkToResource);
      _.set(element, httpMappingParam.getPathToDataInOutput(), this.map[String(id)]);
    } else {
      return _.set(
        element,
        httpMappingParam.getPathToDataInOutput(),
        this.map[String(_.get(element, httpMappingParam.getPathToId()))]
      );
    }
  }
}
