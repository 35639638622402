export class ParcelPerformanceTarget {
  projectedDose: number;
  medianYieldTarget: number;
  kind: string;

  constructor(parcel?: any) {
    if (parcel) {
      this.projectedDose = parcel.agroData.nitrogenStatus.projectedDose;
      this.medianYieldTarget = parcel.agroData.medianYieldTarget;
    }
  }
}
