import { LinkResource } from 'src/app/core';

export class LaiCurvePointDto extends LinkResource {
  x: Date;
  stats: LaiStatistics;
  blacklisted: boolean;
  sensor: string;
  acquisitionName: string;

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
      this.x = new Date(data.x);
    }
  }
}

class LaiStatistics {
  mean: number;
  median: number;
  variance: number;
  validity: number;
}
