import { Component, OnInit, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { RegisteredFarmerService, ParcelService, Crop, Parcel } from 'src/app/shared';
import { Observable } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { RestResourceService, mapErrorToMsg, ResponseEntities } from 'src/app/core';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { DxDataGridComponent } from 'devextreme-angular';
import { httpMapping, HttpMappingParam } from 'src/app/core/rxjs-operators/http-mapping';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-campaign-parcel',
  templateUrl: './campaign-parcel.component.html',
  styleUrls: ['./campaign-parcel.component.scss'],
})
export class CampaignParcelComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

  icons = faIcons;
  farmerId: string;
  dataSource: DataSource;
  totalNbParcel: number;

  // tabs
  tabNames = [{ text: 'Maïs 2019' }, { text: 'Hiver 2019' }];

  cropMapping = new HttpMappingParam<Crop>({
    pathToDataInOutput: 'crop',
    pathToIdInInput: 'agroData.crop.cropRefId',
    httpGetter: this.entityProviderService.httpGetter(Crop, '/fs-agro-datum/api/crops'),
  });

  constructor(
    private route: ActivatedRoute,
    private registeredFarmerService: RegisteredFarmerService,
    private parcelService: ParcelService,
    private restResourceService: RestResourceService,
    private datasourceCreator: DxDatasourceCreatorService,
    private entityProviderService: EntityProviderService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.farmerId = params.id;
      this.initDataSource();
    });
  }

  loadFarmerData() {
    return this.registeredFarmerService.getRegisteredFarmerByFarmerId(this.farmerId);
  }

  getParcelId(cellData) {
    return this.restResourceService.getId(cellData);
  }

  loadParceldata(searchParams): Observable<ResponseEntities<Parcel>> {
    return this.parcelService.getParcelFromRegisteredFarmerWithParams(
      this.loadFarmerData(),
      searchParams.httpParams
    );
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchparams =>
        this.loadParceldata(searchparams).pipe(
          tap(response => (this.totalNbParcel = response.page.totalElements)),
          httpMapping(this.cropMapping),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
    });
  }
}
