import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, mergeMap, toArray, shareReplay } from 'rxjs/operators';
import { ResponseEntities, tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { OrganicWaste } from './organic-waste.model';

@Injectable({
  providedIn: 'root',
})
export class OrganicWasteService {
  constructor(private httpClient: HttpClient) {}

  getOrganicWasteById(id: string): Observable<OrganicWaste> {
    const organicWasteByIdUrl = environment.api + '/fs-agro-datum/api/organicWastes/' + id;
    return this.httpClient.get<Observable<OrganicWaste>>(organicWasteByIdUrl).pipe(
      map(a => new OrganicWaste(a)),
      tapLogError('getOrganicWasteById')
    );
  }

  getOrganicWasteByIds(ids: string[], projection?: string): Observable<OrganicWaste[]> {
    ids = ids.filter(id => id);
    const organicWasteByIdsUrl = environment.api + '/fs-agro-datum/api/organicWastes';
    return this.httpClient
      .get<ResponseEntities<OrganicWaste>>(organicWasteByIdsUrl, {
        params: new HttpParams({ fromObject: { id: ids, projection } }),
      })
      .pipe(
        map(a => new ResponseEntities<OrganicWaste>(OrganicWaste, a)),
        map(a => a.getAllEmbedded()),
        tapLogError('getOrganicWasteByIds'),
        shareReplay(1)
      );
  }

  // organicWaste
  getOrganicWastesFromParcel(parcel$: Observable<Parcel>): Observable<OrganicWaste[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.organicWasteActivity.inputs),
      map(b => b.organicWasteRefId),
      toArray(),
      flatMap(ids => this.getOrganicWasteByIds(ids)),
      shareReplay(1)
    );
  }

  getOrganicWastesPeriodicInputRefFromParcel(
    parcel$: Observable<Parcel>
  ): Observable<OrganicWaste[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.organicWasteActivity.periodicInputRefs),
      map(b => b.periodicInputRefId),
      toArray(),
      flatMap(ids => this.getOrganicWasteByIds(ids)),
      shareReplay(1)
    );
  }

  getPreviousOrganicWastesFromParcel(parcel$: Observable<Parcel>): Observable<OrganicWaste[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.previous.organicWasteInputs),
      map(b => b.organicWasteRefId),
      toArray(),
      flatMap(ids => this.getOrganicWasteByIds(ids)),
      shareReplay(1)
    );
  }

  getAntepreviousOrganicWastesFromParcel(parcel$: Observable<Parcel>): Observable<OrganicWaste[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.anteprevious.organicWasteInputs),
      map(b => b.organicWasteRefId),
      toArray(),
      flatMap(ids => this.getOrganicWasteByIds(ids)),
      shareReplay(1)
    );
  }

  getHistoricOrganicWastesFromParcel(parcel$: Observable<Parcel>): Observable<OrganicWaste[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.historicActivities.inputs),
      map(b => b.organicWasteRefId),
      toArray(),
      flatMap(ids => this.getOrganicWasteByIds(ids)),
      shareReplay(1)
    );
  }
}
