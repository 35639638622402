import { LinkResource } from 'src/app/core';

export class ReversedGrasslandAge extends LinkResource {
  id: string;
  label: string;
  code: string;

  constructor(reversedGrasslandAgeData?: any) {
    super(reversedGrasslandAgeData);
    if (reversedGrasslandAgeData) {
      this.id = reversedGrasslandAgeData.id;
      this.label = reversedGrasslandAgeData.label;
      this.code = reversedGrasslandAgeData.code;
    }
  }
}
