import { Injectable } from '@angular/core';
import {
  Crop,
  CropService,
  Parcel,
  ParcelService,
  RegisteredTechnician,
  RegisteredTechnicianService,
  Technician,
  TechnicianService,
} from 'src/app/shared';
import { Observable } from 'rxjs';
import { ResponseEntities } from 'src/app/core/hal';
import { HttpParams } from '@angular/common/http';
import { httpMapping, HttpMappingParam } from 'src/app/core/rxjs-operators/http-mapping';

import {
  Cooperative,
  CooperativeService,
  Farmer,
  FarmerService,
  RegisteredFarmer,
  RegisteredFarmerService,
} from 'src/app/shared';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private parcelService: ParcelService,
    private cropService: CropService,
    private farmerService: FarmerService,
    private registeredFarmerService: RegisteredFarmerService,
    private cooperativeService: CooperativeService,
    private technicianService: TechnicianService,
    private datasourceCreator: DxDatasourceCreatorService,
    private registeredTechnicianService: RegisteredTechnicianService,
    private entityProviderService: EntityProviderService
  ) {}

  parcelRegisteredFarmerMapping = new HttpMappingParam<RegisteredFarmer>({
    keyToSearchInGetter: 'parcels.refId',
    pathToDataInOutput: 'registeredFarmer',
    // return [ {id: 'registeredFarmerId', farmerRefId: 'farmerId', parcels: [{id: 'registeredParcelId', refId: 'parcelId'}, ...]}, ...]
    httpGetter: this.entityProviderService.httpGetter(
      RegisteredFarmer,
      '/fs-core/api/registeredFarmers'
    ),
    httpParams: new HttpParams().set('projection', 'search'),
    reverseMapping: { arrayOfEntities: { pathTo: 'parcels', pathToIdInside: 'refId' } },
  });

  parcelCropMapping = new HttpMappingParam<Crop>({
    pathToIdInInput: 'agroData.crop.cropRefId',
    pathToDataInOutput: 'agroData.crop',
    httpGetter: this.entityProviderService.httpGetter(Crop, '/fs-agro-datum/api/crops'),
    // httpGetter: params => this.cropService.getByHttpParams(params),
    httpParams: new HttpParams().set('projection', 'CropAgroDataParcelSearchProjection'),
  });

  farmerRegisterdFarmerMapping = new HttpMappingParam<RegisteredFarmer>({
    pathToDataInOutput: 'registeredFarmer',
    keyToSearchInGetter: 'farmer.id',
    reverseMapping: {},
    httpGetter: params => this.registeredFarmerService.getByHttpParams(params),
    httpParams: new HttpParams().set('projection', 'farmerSearch'),
  });

  farmerRegisterdCooperativeMapping = new HttpMappingParam<Cooperative>({
    pathToIdInInput: 'registeredFarmer.registeredCooperative.cooperativeRefId',
    httpGetter: params => this.cooperativeService.getByHttpParams(params),
    httpParams: new HttpParams().set('projection', 'TODO'), // TODO
    pathToDataInOutput: 'cooperative',
  });

  TechnicianRegisterdTechnicianMapping = new HttpMappingParam<RegisteredTechnician>({
    pathToDataInOutput: 'registeredtechnician',
    keyToSearchInGetter: 'technician.id',
    reverseMapping: {},
    httpGetter: params => this.registeredTechnicianService.getByHttpParams(params),
    httpParams: new HttpParams().set('projection', 'technicianSearch'),
  });

  TechnicianRegisterdCooperativeMapping = new HttpMappingParam<Cooperative>({
    pathToIdInInput: 'registeredtechnician.registeredCooperative.cooperativeRefId',
    httpGetter: params => this.cooperativeService.getByHttpParams(params),
    httpParams: new HttpParams().set('projection', 'TODO'), // TODO
    pathToDataInOutput: 'cooperative',
  });

  searchParcels(searchParams): Observable<ResponseEntities<Parcel>> {
    return this.parcelService.search(searchParams).pipe(
      httpMapping(this.parcelCropMapping, this.parcelRegisteredFarmerMapping),
      httpMapping(this.farmerRegisterdCooperativeMapping)
    );
  }

  searchFarmers(searchParams): Observable<ResponseEntities<Farmer>> {
    return this.farmerService.search(searchParams).pipe(
      httpMapping(this.farmerRegisterdFarmerMapping),
      httpMapping(this.farmerRegisterdCooperativeMapping)
    );
  }

  searchTechnicians(searchParams): Observable<ResponseEntities<Technician>> {
    return this.technicianService.search(searchParams).pipe(
      httpMapping(this.TechnicianRegisterdTechnicianMapping),
      httpMapping(this.TechnicianRegisterdCooperativeMapping)
    );
  }
}
