import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, toArray } from 'rxjs/internal/operators';
import { flatMap, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Campaign } from 'src/app/shared';
import { CustomerDataFile } from './customer-data-file.model';
import { ResponseEntities } from 'src/app/core';
import { toResponseEntities } from 'src/app/core/hal';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';

@Injectable()
export class CustomerDataFileService {
  fsCustomerDatafileApi = environment.api + '/fs-customer-datafile/api/dataFiles';
  constructor(
    private httpClient: HttpClient,
    private fileDownloaderService: FileDownloaderService
  ) {}

  getDataFilesByCampaignIds(
    ids: string[],
    httpParams: HttpParams
  ): Observable<ResponseEntities<CustomerDataFile>> {
    const urlDataFiles = environment.api + '/fs-customer-datafile/api/dataFiles/fromCampaigns';
    return this.httpClient
      .post<ResponseEntities<CustomerDataFile>>(urlDataFiles, ids, { params: httpParams })
      .pipe(
        toResponseEntities(CustomerDataFile),
        shareReplay(1)
      );
  }

  getDataFilesFromCampaignIdOrCooperativeId(
    httpParams: HttpParams,
    campaignRefId: string,
    cooperativeRefId: string
  ): Observable<ResponseEntities<CustomerDataFile>> {
    const url =
      environment.api +
      '/fs-customer-datafile/api/custom-dataFiles/fromCampaignRefIdOrCooperativeRefId';
    return this.httpClient
      .get<ResponseEntities<CustomerDataFile>>(url, {
        params: httpParams
          .set('campaignRefId', campaignRefId)
          .set('cooperativeRefId', cooperativeRefId),
      })
      .pipe(
        toResponseEntities(CustomerDataFile),
        shareReplay(1)
      );
  }

  getDataFilesFromCampaigns(
    campaignObservable: Observable<ResponseEntities<Campaign>>,
    httpParams: HttpParams
  ): Observable<ResponseEntities<CustomerDataFile>> {
    return campaignObservable.pipe(
      map(entities => entities.getAllEmbedded()),
      mergeMap(campaign => campaign),
      map(campaign => campaign.id),
      toArray(),
      flatMap(campaingIds => this.getDataFilesByCampaignIds(campaingIds, httpParams)),
      shareReplay(1)
    );
  }

  uploadDataFile(file, campaign: string, cooperative: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('cooperativeRefId', cooperative);
    formData.append('campaignRefId', campaign);
    return this.httpClient.post<any>(this.fsCustomerDatafileApi, formData);
  }

  importDataFile(datafile: string): Observable<any> {
    const url = this.fsCustomerDatafileApi + `/${datafile}/import`;
    return this.httpClient.post<any>(url, {});
  }

  testDataFile(datafile: string): Observable<any> {
    const url = this.fsCustomerDatafileApi + `/${datafile}/test`;
    return this.httpClient.post<any>(url, {});
  }

  downloadDataFileCsv(fileId: string): void {
    const downloadUrl = this.fsCustomerDatafileApi.concat(`/${fileId}/downloadCsv`);
    this.fileDownloaderService.downloadFileUrl(downloadUrl, '', 'rapport de test');
  }
}
