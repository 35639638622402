import { Component, OnChanges, Input } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpMappingParam, httpMapping } from 'src/app/core/rxjs-operators/http-mapping';
import { StageEstimate, PhenologicalStage, ParcelService } from 'src/app/shared';

@Component({
  selector: 'app-parcel-stages',
  templateUrl: './parcel-stages.component.html',
  styleUrls: ['./parcel-stages.component.scss'],
})
export class ParcelStagesComponent implements OnChanges {
  @Input() parcelId: string;

  stages$: Observable<StageEstimate[]>;

  stageEstimateMapping = new HttpMappingParam<PhenologicalStage>({
    pathToIdInInput: 'phenologicalStageRefId',
    pathToDataInOutput: 'phenologicalStage',
    keyToSearchInGetter: 'id',
    httpGetter: httpParams => this.parcelService.getByHttpParams(httpParams),
    httpParams: new HttpParams().set('projection', 'parcel-card'),
  });

  constructor(private parcelService: ParcelService) {}

  ngOnChanges() {
    if (this.parcelId) {
      this.loadStages();
    }
  }

  loadStages() {
    this.stages$ = this.parcelService
      .findPhenologicalStagesLastEstimateStageBy(this.parcelId, 'phenologicalStage')
      .pipe(
        httpMapping(this.stageEstimateMapping),
        map(respEntities => respEntities.getAllEmbedded().sort(this.sortStages))
      );
  }

  sortStages(st1, st2) {
    const idx1 = st1.phenologicalStage.index;
    const idx2 = st2.phenologicalStage.index;
    let compare = 0;
    if (idx1 !== idx2) {
      compare = idx1 > idx2 ? 1 : -1;
    }
    return compare;
  }
}
