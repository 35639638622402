import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, fromEvent } from 'rxjs';
import { tap, shareReplay, map, flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  constructor(private httpClient: HttpClient) {}

  downloadFile(data: any, type: string, filename: string): void {
    const uri = window.URL.createObjectURL(new Blob([data], { type }));
    this.downloadFileUrl(uri, type, filename);
  }

  downloadFileUrl(uri: any, type: string, filename: string): void {
    const link = document.createElement('a');
    link.href = uri;
    if (filename) {
      link.download = filename;
    }
    // Add link to the DOM so that the download works on Firefox
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.appendChild(link);
    link.click();
    bodyTag.removeChild(link);
  }

  downloadFileFromGet(filename: string, url: string, params = new HttpParams()): Observable<any> {
    const $request = this.httpClient
      .get(url, { observe: 'response', responseType: 'arraybuffer', params })
      .pipe(
        tap((response: HttpResponse<any>) => {
          // TODO Get filename from headers (Content-Disposition needed)
          this.downloadFile(response.body, response.headers.get('content-type'), filename);
        }),
        shareReplay(1)
      );

    $request.subscribe();
    return $request;
  }

  downloadFileAsBlob(url: string, params = new HttpParams()): Observable<Blob> {
    return this.httpClient
      .get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json', params })
      .pipe(
        map((response: HttpResponse<any>) => response.body),
        shareReplay(1)
      );
  }

  getFileAsUrl(url: string): Observable<any> {
    return this.downloadFileAsBlob(url).pipe(
      map(blob => {
        const reader = new FileReader();
        const fileReader$ = fromEvent(reader, 'load').pipe(map(event => reader.result));

        if (blob) {
          reader.readAsDataURL(blob);
        }

        return fileReader$;
      }),
      flatMap(fileUrl$ => fileUrl$)
    );
  }
}
