import { Component, Input, OnChanges } from '@angular/core';
import { ParcelService } from 'src/app/shared/parcel/parcel.service';
import View from 'ol/View.js';
import { fromLonLat } from 'ol/proj.js';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { BiophyValueService } from 'src/app/shared/biophy-value/biophy-value.service';
import { MapService } from './map.service';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';
import { BiophyLayer } from 'src/app/shared/biophy-value/biophy-layer.enum';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnChanges {
  @Input() parcelIds: string[];
  @Input() biophyEnabled = false;
  timeTrackingModalIsOpen = false;

  map: any;
  icons = faIcons;
  readonly layers = { ...BiophyLayer, CONTOUR: 'geoJson' };
  olLayers = { geoJson: null, lai: null, trueColor: null };
  biophyInitialized = false;
  geoJson;
  activeLayer = this.layers.CONTOUR;
  selectedParcelId: string;

  constructor(
    private parcelService: ParcelService,
    private biophyValueService: BiophyValueService,
    private mapService: MapService,
    private fileDownloaderService: FileDownloaderService,
    private route: ActivatedRoute
  ) {}

  ngOnChanges(changes) {
    if (!this.map) {
      this.initMap();
    }

    if (changes.parcelIds) {
      if (!_.isEmpty(this.parcelIds)) {
        this.selectedParcelId = this.parcelIds[0];
        this.parcelService.getGeojsonParcelByIds(this.parcelIds).subscribe(geojson => {
          if (geojson) {
            this.geoJson = geojson;
            this.olLayers.geoJson = this.mapService.drawFeatures(
              this.map,
              geojson,
              this.getContourStyle()
            );
          }
        });
      }
    }

    if (changes.biophyEnabled) {
      if (!this.biophyInitialized && this.biophyEnabled) {
        this.addBiophyDefaultOverlays();
        this.loadBiophyLayer(this.layers.LAI);
        this.loadBiophyLayer(this.layers.TRUECOLOR);
        this.biophyInitialized = true;
      }
      this.changeDisplayMode();
    }
  }

  initMap() {
    this.map = this.mapService.createMap('map');

    // Add custom controls for Biophy tab
    this.mapService.addControl(this.map, 'customControls');

    // Add text until geojson is loaded
    this.addDefaultOverlay();
  }

  // Text overlay if there is no geoJson
  addDefaultOverlay() {
    // Center of France
    const defaultViewPoint = fromLonLat([2.520755, 46.024145]);

    this.map.setView(new View({ center: defaultViewPoint, zoom: 5 }));
    this.mapService.addOverlay(this.map, 'noGeoJsonText', defaultViewPoint);
  }

  addBiophyDefaultOverlays() {
    if (this.map.getOverlays().getLength() <= 1) {
      this.mapService.addOverlay(this.map, 'noTrueColorText');
      this.mapService.addOverlay(this.map, 'noLaiText');
    }
  }

  getContourStyle() {
    return this.biophyEnabled
      ? this.mapService.getFeatureStyle('#9820DEFF', false)
      : this.mapService.getFeatureStyle('red', true);
  }

  // Tab changed
  changeDisplayMode() {
    if (this.olLayers.geoJson) {
      this.olLayers.geoJson
        .getSource()
        .forEachFeature(feat => feat.setStyle(this.getContourStyle()));
    }

    if (this.biophyEnabled) {
      this.layerChanged(this.layers.LAI);
    } else {
      this.hideLayer(this.layers.LAI);
      this.hideLayer(this.layers.TRUECOLOR);
      this.activeLayer = this.layers.CONTOUR;
    }
  }

  // Custom control action
  layerChanged(layer) {
    this.activeLayer = layer;
    if (layer === this.layers.LAI) {
      this.hideLayer(this.layers.TRUECOLOR);
    } else {
      this.hideLayer(this.layers.LAI);
    }
    if (this.olLayers[layer]) {
      this.olLayers[layer].setVisible(true);
    }
  }

  loadBiophyLayer(layer) {
    const biophyLayer$ =
      layer === this.layers.LAI
        ? this.biophyValueService.getLastLaiLayer(this.parcelIds[0])
        : this.biophyValueService.getLastTrueColorLayer(this.parcelIds[0]);
    biophyLayer$.subscribe(data => {
      if (!_.isEmpty(data)) {
        this.fileDownloaderService.getFileAsUrl(data._links.png.href).subscribe(fileUrl => {
          this.olLayers[layer] = this.mapService.createImageLayer(fileUrl, data.bbox);
          if (this.activeLayer !== layer) {
            this.olLayers[layer].setVisible(false);
          }
          this.map.addLayer(this.olLayers[layer]);
        });
      }
    });
  }

  hideLayer(layerName) {
    if (this.olLayers[layerName]) {
      this.olLayers[layerName].setVisible(false);
    }
  }

  isBiophyOverlayHidden(layer) {
    return this.activeLayer !== layer || this.olLayers[layer];
  }
}
