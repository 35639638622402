import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { TotalNdoseFilter } from 'src/app/feature/statistics/total-ndose/total-ndose-filter';
import DataSource from 'devextreme/data/data_source';
import { tap } from 'rxjs/operators';
import {
  ParcelService,
  NDoseComputationMethod,
  RegisteredFarmer,
  Cooperative,
} from 'src/app/shared';
import {
  DxDatasourceCreatorService,
  httpMapping,
  HttpMappingParam,
  mapErrorToMsg,
  EntityProviderService,
} from 'src/app/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-total-ndose-parcels',
  templateUrl: './total-ndose-parcels.component.html',
  styleUrls: ['./total-ndose-parcels.component.scss'],
})
export class TotalNdoseParcelsComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

  @Output() statisticsChanged = new EventEmitter<void>();

  icons = faIcons;
  totalNbParcels: number;
  dataSource: DataSource;
  filter: TotalNdoseFilter;
  computeModalOpen = false;
  export$: Observable<any>;

  nDoseComputationMethodMapping = new HttpMappingParam<NDoseComputationMethod>({
    pathToIdInInput: 'agroData.ndoseComputationMethodRefId',
    pathToDataInOutput: 'nDoseComputationMethod',
    httpGetter: this.entityProviderService.httpGetter(
      NDoseComputationMethod,
      '/fs-agro-datum/api/nDoseComputationMethods'
    ),
    httpParams: new HttpParams().set('projection', 'dropdown'),
  });

  registeredFarmerMapping = new HttpMappingParam<RegisteredFarmer>({
    keyToSearchInGetter: 'parcels.refId',
    pathToDataInOutput: 'registeredFarmer',
    httpGetter: this.entityProviderService.httpGetter(
      RegisteredFarmer,
      '/fs-core/api/registeredFarmers'
    ),
    httpParams: new HttpParams().set('projection', 'parcelList').set('campaign.active', 'true'),
    reverseMapping: { arrayOfEntities: { pathTo: 'parcels', pathToIdInside: 'refId' } },
  });

  cooperativeMapping = new HttpMappingParam<Cooperative>({
    pathToDataInOutput: 'cooperative',
    pathToIdInInput: 'registeredFarmer.registeredCooperative.cooperativeRefId',
    httpGetter: this.entityProviderService.httpGetter(Cooperative, '/fs-core/api/cooperatives'),
    httpParams: new HttpParams().set('projection', 'parcelList'),
  });

  constructor(
    private datasourceCreator: DxDatasourceCreatorService,
    private parcelService: ParcelService,
    private entityProviderService: EntityProviderService
  ) {
    this.reloadStatistics = this.reloadStatistics.bind(this);
  }

  ngOnInit() {
    this.initDatasource();
  }

  initDatasource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.loadParcels(searchParams).pipe(
          tap(response => (this.totalNbParcels = response.page.totalElements)),
          httpMapping(this.nDoseComputationMethodMapping, this.registeredFarmerMapping),
          httpMapping(this.cooperativeMapping),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({
        httpParams: this.filter ? this.filter.toHttpParams() : new HttpParams(),
      }),
    });
  }

  loadParcels(searchParams) {
    return this.parcelService.getNDoseParcelStatistics(searchParams, 'parcel-list');
  }

  applyFilter(filter: TotalNdoseFilter) {
    this.filter = filter;
    this.datagrid.instance.refresh();
  }

  getNCredit(row) {
    const nitrogenStatus = row.agroData.nitrogenStatus;
    return nitrogenStatus.mesuredNCredit || nitrogenStatus.ncreditBalanceBegining;
  }

  getAutresFournitures(row) {
    const nitrogenStatus = row.agroData.nitrogenStatus;
    return nitrogenStatus.irrigationEffect + nitrogenStatus.ncreditBalanceBegining;
  }

  exportToCsv() {
    this.export$ = this.parcelService.exportNDoseStatistics(
      this.filter ? this.filter.toHttpParams() : new HttpParams()
    );
  }

  reloadStatistics() {
    this.statisticsChanged.emit();
  }
}
