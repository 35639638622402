import { Component, OnInit, Input } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import {
  FarmerService,
  Cooperative,
  CooperativeService,
  RegisteredFarmerService,
  Farmer,
  RegisteredFarmer,
  Technician,
  TechnicianService,
} from 'src/app/shared';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-farmer-details',
  templateUrl: './farmer-details.component.html',
  styleUrls: ['./farmer-details.component.scss'],
})
export class FarmerDetailsComponent implements OnInit {
  parcel = 'Parcelles des campagnes actives';
  icons = faIcons;
  farmerId: string;
  farmer$: Observable<Farmer>;
  cooperative$: Observable<Cooperative>;
  technician$: Observable<Technician>;
  registeredFarmer$: Observable<RegisteredFarmer>;
  logoUrl: any;

  @Input() parcelIds: string[];

  constructor(
    private farmerService: FarmerService,
    private cooperativeService: CooperativeService,
    private registeredFarmerService: RegisteredFarmerService,
    private technicianService: TechnicianService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.farmerId = params.id;
      this.loadFarmerDetails();
      this.loadFarmerParcels();
    });
  }

  loadFarmerParcels() {
    this.registeredFarmer$.subscribe(r => {
      this.parcelIds = r.parcels.map(p => p.refId);
    });
  }

  loadFarmerDetails() {
    this.registeredFarmer$ = this.registeredFarmerService.getRegisteredFarmerByFarmerId(
      this.farmerId
    );
    this.farmer$ = this.farmerService.getFarmerById(this.farmerId, 'card');
    this.cooperative$ = this.cooperativeService.getCooperativeFromRegisteredFarmer(
      this.registeredFarmer$
    );
    this.technician$ = this.technicianService.getTechnicianFromRegisteredFarmer(
      this.registeredFarmer$,
      'card'
    );
    this.cooperative$.subscribe(coop => this.loadUrlLogo(coop.id));
  }
  loadUrlLogo(coopId) {
    this.cooperativeService.getLargeLogo(coopId).subscribe(data => this.getImageFromBlob(data));
  }

  getImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.logoUrl = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
