import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxSelectBoxModule, DxTextBoxModule, DxTemplateModule } from 'devextreme-angular';
import { TestUiComponentsComponent } from './test-ui-components.component';
import { SharedModule } from 'src/app/shared';

@NgModule({
  declarations: [TestUiComponentsComponent],
  imports: [CommonModule, DxSelectBoxModule, DxTextBoxModule, DxTemplateModule, SharedModule],
  exports: [TestUiComponentsComponent],
})
export class TestUiComponentsModule {}
