import { Entity, LinkResource } from 'src/app/core/hal';
import { RegisteredCrop } from 'src/app/shared/registered-crop/registered-crop.model';

export class Campaign extends LinkResource implements Entity {
  id: string;
  code: string;
  label: string;
  year: number;
  active = true;
  crops: RegisteredCrop[] = [];
  cropRefIds?: string[];
  countAndSurface?: { surface: string; count: string; projectedDoseCount: string };
  crs: string;

  getRelativeApiUrl = () => '/fs-core/api/campaigns';

  constructor(campaignData?: any) {
    super(campaignData);
    if (campaignData) {
      Object.assign(this, campaignData);
      if (campaignData.crops) {
        this.crops = campaignData.crops.map(crop => new Crops(crop));
      }
    }
  }
}

class Crops extends LinkResource {
  id: string;
  refId: string;
  constructor(cropsData?: any) {
    super(cropsData);
    if (cropsData) {
      Object.assign(this, cropsData);
    }
  }
}
