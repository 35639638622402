import { Entity, LinkResource } from 'src/app/core';

export class NDoseComputationMethod extends LinkResource implements Entity {
  id: string;
  label: string;
  code: string;

  getRelativeApiUrl = () => '/fs-agro-datum/api/nDoseComputationMethods';

  constructor(nDoseComputationMethodData?: any) {
    super(nDoseComputationMethodData);
    if (nDoseComputationMethodData) {
      this.id = nDoseComputationMethodData.id;
      this.label = nDoseComputationMethodData.label;
      this.code = nDoseComputationMethodData.code;
    }
  }
}
