import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseEntities, toResponseEntities } from 'src/app/core';
import { shareReplay } from 'rxjs/operators';
import { Logbook } from './logbook.model';

@Injectable()
export class LogbookService {
  fsLogbook = '/fs-logbook/api/logbooks';
  constructor(private httpClient: HttpClient) {}

  getLogbooks(params: HttpParams) {
    return this.httpClient
      .get<ResponseEntities<Logbook>>(environment.api + this.fsLogbook, { params })
      .pipe(
        toResponseEntities(Logbook),
        shareReplay(1)
      );
  }

  getLogbookEvents(): Observable<ResponseEntities<Logbook>> {
    return this.httpClient
      .get<ResponseEntities<Logbook>>(environment.api + this.fsLogbook, {
        params: { page: '0', size: '10', sort: 'date,desc' },
      })
      .pipe(
        toResponseEntities(Logbook),
        shareReplay(1)
      );
  }
}
