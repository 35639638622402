import { Cooperative, NDoseComputationMethod, Campaign } from 'src/app/shared';
import { HttpParams } from '@angular/common/http';

export class TotalNdoseFilter {
  cooperative: Cooperative;
  computationMethod: NDoseComputationMethod;
  department: string;
  campaign: Campaign;

  toHttpParams(): HttpParams {
    let params = new HttpParams();
    if (this.campaign) {
      params = params.set('campaign', this.campaign.id);
    }
    if (this.cooperative) {
      params = params.set('cooperative', this.cooperative.id);
    }
    if (this.computationMethod) {
      params = params.set('method', this.computationMethod.id);
    }
    if (this.department) {
      params = params.set('department', this.department);
    }
    return params;
  }
}
