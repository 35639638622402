import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared';
import { TotalNdoseComponent } from './total-ndose/total-ndose.component';
import { TotalNdoseParcelsComponent } from './total-ndose/total-ndose-parcels/total-ndose-parcels.component';
import { TotalNdoseDistributionComponent } from './total-ndose/total-ndose-distribution/total-ndose-distribution.component';
// tslint:disable-next-line:max-line-length
import { TotalNdosePerformanceTargetComponent } from './total-ndose/total-ndose-performance-target/total-ndose-performance-target.component';
import { TotalNdoseFilterComponent } from './total-ndose/total-ndose-filter/total-ndose-filter.component';
import { TotalNdoseComputeModalComponent } from './total-ndose/total-ndose-compute-modal/total-ndose-compute-modal.component';

@NgModule({
  declarations: [
    TotalNdoseComponent,
    TotalNdoseParcelsComponent,
    TotalNdoseDistributionComponent,
    TotalNdosePerformanceTargetComponent,
    TotalNdoseFilterComponent,
    TotalNdoseComputeModalComponent,
  ],
  imports: [SharedModule],
  exports: [TotalNdoseComponent],
  providers: [],
})
export class StatisticsModule {}
