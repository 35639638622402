import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared';
import { TechnicianCardComponent } from './technician-card.component';
import { TechnicianDetailsComponent } from './technician-details/technician-details.component';
import { CampaignFarmerComponent } from './campaign-farmer/campaign-farmer.component';

@NgModule({
  declarations: [TechnicianCardComponent, TechnicianDetailsComponent, CampaignFarmerComponent],
  imports: [SharedModule],
  exports: [TechnicianCardComponent],
  providers: [],
})
export class TechnicianCardModule {}
