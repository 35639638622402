import { Entity, LinkResource } from 'src/app/core';

export class Cooperative extends LinkResource implements Entity {
  id: string;
  code: string;
  name: string;
  hasLogoSmall?: boolean;
  hasLogoLarge?: boolean;

  getRelativeApiUrl = () => '/fs-core/api/cooperatives';

  constructor(cooperativeData?: any) {
    super(cooperativeData);
    if (cooperativeData) {
      Object.assign(this, cooperativeData);
    }
  }
}
