import { LinkResource } from 'src/app/core';

export class BiomassLevel extends LinkResource {
  id: string;
  label: string;
  code: string;

  constructor(biomassLevelData?: any) {
    super(biomassLevelData);
    if (biomassLevelData) {
      this.id = biomassLevelData.id;
      this.label = biomassLevelData.label;
      this.code = biomassLevelData.code;
    }
  }
}
