import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Campaign } from 'src/app/shared/campaign/campaign.model';
import { CampaignService } from 'src/app/shared/campaign/campaign.service';

@Component({
  selector: 'app-campaign-select',
  templateUrl: './campaign-select.component.html',
  styleUrls: ['./campaign-select.component.scss'],
})
export class CampaignSelectComponent implements OnInit {
  @Input() active: boolean;
  @Input() selectFirst: boolean;
  @Input() value: Campaign;
  @Output() valueChange = new EventEmitter<Campaign>();

  campaigns: Campaign[];

  constructor(private campaignService: CampaignService) {}

  ngOnInit() {
    const projection = 'dropdown';
    const campaigns$ = this.active
      ? this.campaignService.getActiveCampaigns(projection)
      : this.campaignService.getAllCampaigns(projection);
    campaigns$.subscribe(response => {
      this.campaigns = response.getAllEmbedded();
      if (this.selectFirst && this.campaigns.length > 0) {
        this.value = this.campaigns[0];
      }
    });
  }
}
