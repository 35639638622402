import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { HistoricCropWasteUsage } from './historic-crop-waste-usage.model';

@Injectable({
  providedIn: 'root',
})
export class HistoricCropWasteUsageService {
  constructor(private httpClient: HttpClient) {}

  getHistoricCropWasteUsageById(id: string): Observable<HistoricCropWasteUsage> {
    const historicCropWasteUsageIdUrl =
      environment.api + '/fs-agro-datum/api/historicCropWasteUsages/' + id;
    return this.httpClient
      .get<Observable<HistoricCropWasteUsage>>(historicCropWasteUsageIdUrl)
      .pipe(
        map(a => new HistoricCropWasteUsage(a)),
        tapLogError('getHistoricCropWasteUsageById')
      );
  }

  getHistoricCropWasteUsageFromParcel(
    parcel$: Observable<Parcel>
  ): Observable<HistoricCropWasteUsage> {
    return parcel$.pipe(
      map(a => a.agroData.activities.historicActivities.historicCropWasteUsageRefId),
      flatMap(historicCropWasteUsageRefId =>
        this.getHistoricCropWasteUsageById(historicCropWasteUsageRefId)
      ),
      shareReplay(1)
    );
  }
}
