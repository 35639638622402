import { Component, OnInit } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import {
  CampaignService,
  Campaign,
  RegisteredParcelService,
  LogbookService,
  Logbook,
  TimeService,
} from 'src/app/shared';
import { ResponseEntities } from 'src/app/core';
import { Observable } from 'rxjs';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  icons = faIcons;
  activeCampaigns$: Observable<ResponseEntities<Campaign>>;
  logbookEvent$: Observable<ResponseEntities<Logbook>>;

  constructor(
    private campaignService: CampaignService,
    private registeredParcelService: RegisteredParcelService,
    private httpMappingService: HttpMappingService,
    private logbookService: LogbookService,
    private timeService: TimeService
  ) {}

  ngOnInit() {
    this.activeCampaigns$ = this.campaignService.getActiveCampaigns('campaign-home-page').pipe(
      this.httpMappingService.mapEntities({
        relativeApiUrl: '/fs-core/api/registeredParcels/getCountAndSurface',
        keyToSearchInGetter: 'campaignId',
        pathToDataInOutput: 'countAndSurface',
        onGetByElement: true,
      })
    );
    this.logbookEvent$ = this.logbookService.getLogbookEvents();
    this.initDataSource();
  }

  initDataSource() {
    return this.registeredParcelService.getAllRegisteredParcels();
  }

  calculateDate(date) {
    return this.timeService.utcToTimeZone(date);
  }
}
