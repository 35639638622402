import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay, flatMap, mergeMap, toArray } from 'rxjs/operators';
import { tapLogError, ResponseEntities } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { PeriodicInput } from './periodic-input.model';

@Injectable({
  providedIn: 'root',
})
export class PeriodicInputService {
  constructor(private httpClient: HttpClient) {}

  getPeriodicInputById(id: string): Observable<PeriodicInput> {
    const periodicInputIdUrl = environment.api + '/fs-agro-datum/api/periodicInputs/' + id;
    return this.httpClient.get<Observable<PeriodicInput>>(periodicInputIdUrl).pipe(
      map(a => new PeriodicInput(a)),
      tapLogError('getPeriodicInputById')
    );
  }

  getPeriodicInputByIds(ids: string[], projection?: string): Observable<PeriodicInput[]> {
    ids = ids.filter(id => id);
    const periodicInputByIdsUrl = environment.api + '/fs-agro-datum/api/periodicInputs';
    return this.httpClient
      .get<ResponseEntities<PeriodicInput>>(periodicInputByIdsUrl, {
        params: new HttpParams({ fromObject: { id: ids, projection } }),
      })
      .pipe(
        map(a => new ResponseEntities<PeriodicInput>(PeriodicInput, a)),
        map(a => a.getAllEmbedded()),
        tapLogError('getOrganicWasteByIds'),
        shareReplay(1)
      );
  }

  getPeriodicInputsFromParcel(parcel$: Observable<Parcel>): Observable<PeriodicInput[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.organicWasteActivity.periodicInputRefs),
      map(b => b.periodicInputRefId),
      toArray(),
      flatMap(ids => this.getPeriodicInputByIds(ids)),
      shareReplay(1)
    );
  }
}
