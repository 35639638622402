import { Entity, LinkResource } from 'src/app/core/hal';
import { Cooperative } from 'src/app/shared';

export class RegisteredFarmer extends LinkResource implements Entity {
  id: string;
  farmerRefId: string;
  parcels: EmbeddedParcel[];
  campaigns: EmbeddedCampaign[];
  technicianRefId: string;
  registeredCooperative: EmbeddedRegisteredCooperative;
  registeredTechnician: EmbeddedRegisteredTechnician;
  cooperative?: Cooperative;

  getRelativeApiUrl = () => '/fs-core/api/registeredFarmers';
  constructor(registeredFarmerData?: any) {
    super(registeredFarmerData);
    if (registeredFarmerData) {
      this.id = registeredFarmerData.id;
      this.farmerRefId = registeredFarmerData.farmerRefId;
      this.technicianRefId = registeredFarmerData.technicianRefId;
      this.cooperative = registeredFarmerData.cooperative;
      this.registeredCooperative = new EmbeddedRegisteredCooperative(
        registeredFarmerData.registeredCooperative
      );
      this.registeredTechnician = new EmbeddedRegisteredTechnician(
        registeredFarmerData.registeredTechnician
      );
      if (registeredFarmerData.parcels) {
        this.parcels = registeredFarmerData.parcels.map(Parcel => new EmbeddedParcel(Parcel));
      }
      if (registeredFarmerData.campaigns) {
        this.campaigns = registeredFarmerData.campaigns.map(
          campaign => new EmbeddedCampaign(campaign)
        );
      }
    }
  }
}

export class EmbeddedParcel extends LinkResource {
  id: string;
  refId: string;
  constructor(parcelsData?: any) {
    super(parcelsData);
    if (parcelsData) {
      this.id = parcelsData.id;
      this.refId = parcelsData.parcelRefId;
    }
  }
}

export class EmbeddedCampaign extends LinkResource {
  id: string;
  code: string;
  label: string;
  year: number;
  active: boolean;
  constructor(campaignData?: any) {
    super(campaignData);
    if (campaignData) {
      this.id = campaignData.id;
      this.code = campaignData.code;
      this.label = campaignData.label;
      this.year = campaignData.year;
      this.active = campaignData.active;
    }
  }
}

class EmbeddedRegisteredCooperative {
  id: string;
  cooperativeRefId: string;
  cooperative?: Cooperative;

  constructor(registeredCooperativeData?: any) {
    if (registeredCooperativeData) {
      this.id = registeredCooperativeData.id;
      this.cooperativeRefId = registeredCooperativeData.cooperativeRefId;
      this.cooperative = registeredCooperativeData.cooperative;
    }
  }
}

export class EmbeddedRegisteredTechnician {
  id: string;
  technicianRefId: string;

  constructor(registeredTechnicianData?: any) {
    if (registeredTechnicianData) {
      this.id = registeredTechnicianData.id;
      this.technicianRefId = registeredTechnicianData.technicianRefId;
    }
  }
}
