import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { NDoseComputationMethod } from './n-dose-computation-method.model';
import { ResponseEntities } from 'src/app/core/hal';

@Injectable({
  providedIn: 'root',
})
export class NDoseComputationMethodsService {
  nDoseComputationMethodApi = environment.api + '/fs-agro-datum/api/nDoseComputationMethods';
  constructor(private httpClient: HttpClient) {}

  getNDoseComputationMethodById(id: string): Observable<NDoseComputationMethod> {
    const nDoseComputationMethodIdUrl =
      environment.api + '/fs-agro-datum/api/nDoseComputationMethods/' + id;
    return this.httpClient
      .get<Observable<NDoseComputationMethod>>(nDoseComputationMethodIdUrl)
      .pipe(
        map(a => new NDoseComputationMethod(a)),
        tapLogError('getNDoseComputationMethodById')
      );
  }

  getNDoseComputationMethodFromParcel(
    parcel$: Observable<Parcel>
  ): Observable<NDoseComputationMethod> {
    return parcel$.pipe(
      map(a => a.agroData.ndoseComputationMethodRefId),
      flatMap(ndoseComputationMethodRefId =>
        this.getNDoseComputationMethodById(ndoseComputationMethodRefId)
      ),
      shareReplay(1)
    );
  }

  getAllComputationMethods(projection: string): Observable<NDoseComputationMethod[]> {
    const params = { projection, sort: 'label,asc' };
    return this.httpClient
      .get<NDoseComputationMethod[]>(this.nDoseComputationMethodApi, { params })
      .pipe(
        map(a => new ResponseEntities<NDoseComputationMethod>(NDoseComputationMethod, a)),
        map(a => a.getAllEmbedded()),
        tapLogError('getAllComputationMethods'),
        shareReplay(1)
      );
  }
}
