import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-ui-components',
  templateUrl: './test-ui-components.component.html',
  styleUrls: ['./test-ui-components.component.scss'],
})
export class TestUiComponentsComponent implements OnInit {
  simpleProducts: string[];

  constructor() {}

  ngOnInit() {
    this.simpleProducts = ['Product 1', 'Product 2', 'Product 3'];
  }
}
