import { LinkResource, Entity } from 'src/app/core';

export class PhenologicalStage extends LinkResource implements Entity {
  id: string;
  cropRefId: string;
  code: string;
  label: string;
  index: number;

  getRelativeApiUrl = () => '/fs-growth-stage/api/phenologicalStages';

  constructor(data: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }
}
