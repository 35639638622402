import { Component, OnInit, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import {
  Campaign,
  CampaignService,
  Crop,
  CropService,
  RegisteredCooperativeCountDto,
} from 'src/app/shared';
import { DxDataGridComponent } from 'devextreme-angular';
import { HttpParams } from '@angular/common/http';
import {
  DxDatasourceCreatorService,
  mapErrorToMsg,
  HttpMappingService,
  toResponseEntities,
} from 'src/app/core';
import DataSource from 'devextreme/data/data_source';
import { map, tap } from 'rxjs/operators';
import { ResponseEntities } from 'src/app/core/hal';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';
import { ServicePack } from 'src/app/shared/service-pack/service-pack.model';

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss'],
})
export class CampaignsListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  dataSource: DataSource;
  totalElements: number;
  crops: Crop[];
  servicePackIds: string[];
  isInit: boolean;
  cooperativePopupOpen = false;
  selectedCampaign: Campaign;

  constructor(
    private campaignService: CampaignService,
    private datasourceCreator: DxDatasourceCreatorService,
    private cropService: CropService,
    private httpMappingService: HttpMappingService,
    private entityProviderService: EntityProviderService
  ) {
    this.registeredCropsCalculateDisplayValue = this.registeredCropsCalculateDisplayValue.bind(
      this
    );
  }

  ngOnInit() {
    this.initDataSource();
    this.cropService
      .getByHttpParams(
        new HttpParams({ fromObject: { projection: 'crop-campaign-list-projection' } })
      )
      .pipe(
        map(raw => new ResponseEntities<Crop>(Crop, raw)),
        map(resp => resp.getAllEmbedded())
      )
      .subscribe(crops => {
        this.crops = crops;
        this.isInit = true;
      });
    this.entityProviderService
      .httpGetter(ServicePack)()
      .pipe(
        toResponseEntities(ServicePack),
        map(responseEntities => responseEntities.getAllEmbedded())
      )
      .subscribe(servicePacks => (this.servicePackIds = servicePacks.map(sp => sp.id)));
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.campaignService.httpGet(searchParams.httpParams).pipe(
          this.httpMappingService.mapEntities({
            pathToDataInOutput: 'registeredCooperativeCount',
            httpGetter: this.entityProviderService.httpGetter(
              RegisteredCooperativeCountDto,
              '/fs-core/api/registeredCooperatives/countByCampaign'
            ),
          }),
          tap(r => (this.totalElements = r.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des campagnes !')
        ),
      getLoadParams: () => ({
        httpParams: new HttpParams().set('projection', 'campaign-list-registered-crop'),
      }),
      update: this.updateRow.bind(this),
      insert: this.addRow.bind(this),
    });
  }

  updateRow(campaign: Campaign, newValues): Promise<any> {
    return this.campaignService
      .httpPatch(campaign, newValues)
      .pipe(mapErrorToMsg('Il y a eu une erreur pendant la modification de la campagne !'))
      .toPromise();
  }

  addRow(campaign: Campaign) {
    return this.campaignService
      .httpPost(campaign)
      .pipe(mapErrorToMsg("Il y a eu une erreur pendant l'ajout de la campagne !"))
      .toPromise();
  }

  exportDataGrid() {
    this.dataGrid.instance.exportToExcel(false);
  }

  registeredCropsCalculateDisplayValue(rowData: Campaign) {
    if (this.crops && rowData.crops) {
      return this.crops
        .filter(crop => rowData.crops.find(registeredCrop => registeredCrop.cropRefId === crop.id))
        .map(crop => crop.label)
        .join(',');
    }
  }

  openCooperativeModal(campaign: Campaign) {
    this.cooperativePopupOpen = true;
    this.selectedCampaign = campaign;
  }

  onInitNewRow(e) {
    e.data = new Campaign();
  }

  onEditorPreparing(e) {
    if (e.parentType === 'dataRow' && e.dataField === 'active') {
      e.editorName = 'dxSwitch';
    }
  }
}
