import { Entity, LinkResource } from 'src/app/core';

export class Crop extends LinkResource implements Entity {
  code: string;
  id: string;
  label: string;

  getRelativeApiUrl = () => '/fs-agro-datum/api/crops';

  constructor(cropData?: any) {
    super(cropData);
    if (cropData) {
      this.code = cropData.code;
      this.id = cropData.id;
      this.label = cropData.label;
    }
  }
}
