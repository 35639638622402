import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@fret-ngx/aaa';
import { concatMap, take } from 'rxjs/operators';

/**
 * Overrides @fret-ngx/aaa TokenInterceptor to call tokenless requests
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.user.pipe(
      take(1),
      concatMap(user => {
        if (!!user && !user.expired) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${user.access_token}`,
            },
          });
        }
        return next.handle(request);
      })
    );
  }
}
