import { Component } from '@angular/core';

const versionList = require('./services-versions.json');

@Component({
  selector: 'app-about-backend-versions',
  templateUrl: './about-backend-versions.component.html',
  styleUrls: ['./about-backend-versions.component.scss'],
})
export class AboutBackendVersionsComponent {
  public versionList;
  constructor() {
    this.versionList = versionList;
  }
  // ngOnInit() {
  // this.httpClient
  //   .get(environment.api + '/fs-core/monitor/actuator/env')
  //   .pipe(
  //     map(resp => resp),
  //     catchError(err => of('HS'))
  //   )
  //   .subscribe(console.log);
  // this.propertySources
  //   .filter(e => e.properties && e.properties['maven.project.version'])
  //   .map(e => e.properties['maven.project.version'].value)[0];
  // }
}
