import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import {
  Campaign,
  Cooperative,
  CooperativeService,
  RegisteredCooperative,
  RegisteredCooperativeService,
} from 'src/app/shared';
import DataSource from 'devextreme/data/data_source';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import * as _ from 'lodash';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-cooperative-selector-popup',
  templateUrl: './cooperative-selector-popup.component.html',
  styleUrls: ['./cooperative-selector-popup.component.scss'],
})
export class CooperativeSelectorPopupComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  @Input() campaign: Campaign;

  @Input() popupOpen: boolean;
  @Output() popupOpenChange = new EventEmitter<boolean>();

  @Output() cooperativesUpdated = new EventEmitter<void>();

  cooperatives$: Observable<Cooperative[]>;
  onSave: any;
  campaignRegisteredCoop: RegisteredCooperative[];
  selectedCooperatives = [];
  dataSource: DataSource;

  constructor(
    private cooperativeService: CooperativeService,
    private registeredCooperativeService: RegisteredCooperativeService,
    private datasourceCreator: DxDatasourceCreatorService
  ) {}

  ngOnInit() {
    this.onSave = this.saveAndClose.bind(this);
    this.initDataSource();
    this.cooperatives$ = this.cooperativeService.getAllCooperatives('dropdown');
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.asStore({
      load: searchParams => this.cooperativeService.getByHttpParams(searchParams.httpParams),
      projection: 'dropdown',
    });
  }

  ngOnChanges(changes) {
    const campaignChanged = changes.campaign && changes.campaign.currentValue;
    const popupOpened = changes.popupOpen && changes.popupOpen.currentValue && this.campaign;

    if (campaignChanged || popupOpened) {
      this.registeredCooperativeService
        .getByCampaignId(this.campaign.id, 'campaign-list')
        .subscribe(registeredCoop => {
          this.campaignRegisteredCoop = registeredCoop;
          this.selectedCooperatives = registeredCoop.map(rc => rc.cooperativeRefId);
        });
    }
  }

  popupClosed() {
    this.popupOpenChange.emit(false);
  }

  saveAndClose() {
    const currentSelection = this.dataGrid.instance.getSelectedRowKeys();
    const unchangedCoop = _.intersection(currentSelection, this.selectedCooperatives);
    const toDelete = _.difference(this.selectedCooperatives, unchangedCoop);
    const toCreate = _.difference(currentSelection, unchangedCoop);

    const requests$ = [];
    toCreate.forEach(coopId => {
      requests$.push(
        this.registeredCooperativeService.createRegisteredCooperative(coopId, this.campaign)
      );
    });

    toDelete.forEach(coopId => {
      const registeredCoop = _.find(
        this.campaignRegisteredCoop,
        rc => rc.cooperativeRefId === coopId
      );
      requests$.push(this.registeredCooperativeService.httpDelete(registeredCoop));
    });

    forkJoin(requests$).subscribe(() => {
      this.popupClosed();
      this.cooperativesUpdated.emit();
    });
  }
}
