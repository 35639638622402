import { Pipe, PipeTransform } from '@angular/core';
import { TimeService } from 'src/app/shared/time/time.service';

@Pipe({ name: 'fromUtc' })
export class UtcDateDisplayPipe implements PipeTransform {
  constructor(private timeService: TimeService) {}

  transform(value: Date, format = 'DD/MM/YYYY'): string {
    return value ? this.timeService.utcToTimeZone(value, format) : '';
  }
}
