import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponentsModule } from './form-components/form-components.module';
import { FiltersPanelComponent } from './filters-panel/filters-panel.component';
import { PanelComponent } from './panel/panel.component';
import { LoadIndicatorComponent } from './loaders/load-indicator.component';
import { MatCardModule } from '@angular/material';
import { DxLoadIndicatorModule } from 'devextreme-angular';
import { LoaderButtonComponent } from './loaders/loader-button/loader-button.component';
import { TitledPanelComponent } from './titled-panel/titled-panel.component';

@NgModule({
  declarations: [
    FiltersPanelComponent,
    PanelComponent,
    LoadIndicatorComponent,
    LoaderButtonComponent,
    TitledPanelComponent,
  ],
  imports: [CommonModule, MatCardModule, DxLoadIndicatorModule],
  exports: [
    FormComponentsModule,
    FiltersPanelComponent,
    PanelComponent,
    LoadIndicatorComponent,
    LoaderButtonComponent,
    TitledPanelComponent,
  ],
})
export class ComponentsModule {}
