import { Pipe, PipeTransform } from '@angular/core';
import Qty from 'js-quantities';
import { isNil } from 'lodash';

@Pipe({ name: 'convertBaseTo' })
export class BaseUnitConverterPipe implements PipeTransform {
  // Units must match js-quantities names
  agriBaseUnits = {
    kg: ['kg', 'tonne', 'mg'],
    m: ['m', 'mm'],
    'm^3': ['L', 'm^3'],
    'm^2': ['hectare'],
  };
  baseUnitMap = new Map<string, string>();

  // Add custom labels if you don't want to display the js-quantities unit name
  unitLabels = {
    hectare: 'ha',
    'm^3': 'm3',
    tonne: 't',
  };

  constructor() {
    Object.keys(this.agriBaseUnits).forEach(baseUnit => {
      this.agriBaseUnits[baseUnit].forEach(unit => this.baseUnitMap.set(unit, baseUnit));
    });
  }

  transform(value: number, toUnit: string, customUnitLabel?: string): string {
    const units = toUnit.split('/');
    const baseUnits = units.map(u => this.baseUnitMap.get(u));
    const unitLabel = units.map(u => this.unitLabels[u] || u).join('/');
    const convertedValue = isNil(value)
      ? ''
      : Qty(value, baseUnits.join('/'))
          .to(toUnit)
          .toPrec(0.01)
          .toString();
    return convertedValue.replace(toUnit, isNil(customUnitLabel) ? unitLabel : customUnitLabel);
  }
}
