import { environment } from 'src/environments/environment';

export const AAAConfig = {
  authority: environment.authentication.authority,
  client_id: 'IDP',
  redirect_uri: `${environment.authentication.front}/auth-callback/signin`,
  silent_redirect_uri: `${environment.authentication.front}/auth-callback/silent-renew`,
  post_logout_redirect_uri: `${environment.authentication.front}/auth-callback/signout`,
  response_type: 'id_token token',
  scope: 'openid',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: false,
};
