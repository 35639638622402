import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RequestPaginationInterceptor implements HttpInterceptor {
  constructor() {}

  /*
   * Add a default size param to GET requests to override Spring's default page size (20) and fetch more data.
   * The interceptor will not change the size if the param is already set.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedRequest = request;
    if (request.method === 'GET' && !request.params.has('size')) {
      // workaround cf https://github.com/angular/angular/issues/18812#issuecomment-336301386
      const params = new HttpParams({ fromString: request.params.toString() }).set('size', '1000');
      updatedRequest = request.clone({ params });
    }
    return next.handle(updatedRequest);
  }
}
