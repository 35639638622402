import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { Soil } from './soil.model';

@Injectable({
  providedIn: 'root',
})
export class SoilService {
  constructor(private httpClient: HttpClient) {}

  getSoilById(id: string): Observable<Soil> {
    const soilIdUrl = environment.api + '/fs-agro-datum/api/soils/' + id;
    return this.httpClient.get<Observable<Soil>>(soilIdUrl).pipe(
      map(a => new Soil(a)),
      tapLogError('getSoilById')
    );
  }

  getSoilFromParcel(parcel$: Observable<Parcel>): Observable<Soil> {
    return parcel$.pipe(
      map(a => a.agroData.soilRefId),
      flatMap(soilRefId => this.getSoilById(soilRefId)),
      shareReplay(1)
    );
  }
}
