import { Entity, LinkResource } from 'src/app/core/hal';

export class Farmer extends LinkResource implements Entity {
  adress: string;
  city: string;
  email: string;
  firstName: string;
  id: string;
  idpIdentifier: string;
  lastModifiedDate: Date;
  lastName: string;
  login: string;
  phone1: string;
  phone2: string;
  profile: string;
  zipCode: string;
  active: boolean;
  code: string;
  codeInCoop: string;
  corporateName: string;
  localeLanguageTag: string;
  siret: string;
  parcelCount?: number;

  getRelativeApiUrl = () => '/fs-core/api/farmers';

  constructor(farmerDetailsData?: any) {
    super(farmerDetailsData);
    if (farmerDetailsData) {
      this.adress = farmerDetailsData.adress;
      this.city = farmerDetailsData.city;
      this.email = farmerDetailsData.email;
      this.firstName = farmerDetailsData.firstName;
      this.id = farmerDetailsData.id;
      this.idpIdentifier = farmerDetailsData.idpIdentifier;
      this.lastModifiedDate = farmerDetailsData.lastModifiedDate;
      this.lastName = farmerDetailsData.lastName;
      this.login = farmerDetailsData.login;
      this.phone1 = farmerDetailsData.phone1;
      this.phone2 = farmerDetailsData.phone2;
      this.profile = farmerDetailsData.profile;
      this.zipCode = farmerDetailsData.zipCode;
      this.active = farmerDetailsData.active;
      this.code = farmerDetailsData.code;
      this.codeInCoop = farmerDetailsData.codeInCoop;
      this.corporateName = farmerDetailsData.corporateName;
      this.localeLanguageTag = farmerDetailsData.localeLanguageTag;
      this.siret = farmerDetailsData.siret;
    }
  }

  getFullName() {
    return [this.firstName, this.lastName].join(' ').trim();
  }
}
