import { LinkResource } from 'src/app/core';

export class OrganicWaste extends LinkResource {
  id: string;
  label: string;
  code: string;
  liquid: boolean;

  constructor(organicWasteData?: any) {
    super(organicWasteData);
    if (organicWasteData) {
      this.id = organicWasteData.id;
      this.label = organicWasteData.label;
      this.code = organicWasteData.code;
      this.liquid = organicWasteData.liquid;
    }
  }
}
