import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { CoreModule } from 'src/app/core';
import { FeatureModule } from 'src/app/feature';
import { AAAModule } from '@fret-ngx/aaa';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecuredComponent } from './security/secured.component';
import { AAAConfig } from 'src/app/security/security-config';
import { TokenInterceptor } from './security/token-interceptor';
import { LOCALE_ID } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent, SecuredComponent],
  imports: [
    // App
    CoreModule,
    FeatureModule,
    AppRoutingModule,
    AAAModule.withConfig(AAAConfig),
    // Angular common
    BrowserModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
