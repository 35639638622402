import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { BiomassLevel } from './biomass-level.model';
import { Parcel } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class BiomassLevelService {
  constructor(private httpClient: HttpClient) {}

  getBiomassLevelById(id: string): Observable<BiomassLevel> {
    const biomassLevelIdUrl = environment.api + '/fs-agro-datum/api/biomassLevels/' + id;
    return this.httpClient.get<Observable<BiomassLevel>>(biomassLevelIdUrl).pipe(
      map(a => new BiomassLevel(a)),
      tapLogError('getBiomassLevelById')
    );
  }

  getBiomassLevelFromParcel(parcel$: Observable<Parcel>): Observable<BiomassLevel> {
    return parcel$.pipe(
      map(a => a.agroData.activities.catchCropActivity.catchCropBiomassLevelRefId),
      flatMap(catchCropBiomassLevelRefId => this.getBiomassLevelById(catchCropBiomassLevelRefId)),
      shareReplay(1)
    );
  }
}
