import { Entity, LinkResource } from 'src/app/core/hal';

export class FwRemoteParcelRequest extends LinkResource implements Entity {
  id: string;
  registeredParcelId: string;
  failed: boolean;

  getRelativeApiUrl = () => '/fs-connector-fertiweb/api/fwRemoteParcelRequests';

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }
}
