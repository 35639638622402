import { LinkResource } from 'src/app/core';

export class CatchCrop extends LinkResource {
  id: string;
  label: string;
  code: string;

  constructor(catchCropData?: any) {
    super(catchCropData);
    if (catchCropData) {
      this.id = catchCropData.id;
      this.label = catchCropData.label;
      this.code = catchCropData.code;
    }
  }
}
