import { SharedModule } from 'src/app/shared';
import { CoordinatorsListComponent } from './coordinators-list/coordinators-list.component';
import { DxDropDownBoxModule } from 'devextreme-angular';
import { NgModule } from '@angular/core';
import { RegionalEngineersListComponent } from './regional-engineers-list/regional-engineers-list.component';

@NgModule({
  declarations: [CoordinatorsListComponent, RegionalEngineersListComponent],
  imports: [SharedModule, DxDropDownBoxModule],
  exports: [CoordinatorsListComponent, RegionalEngineersListComponent],
  providers: [],
})
export class UsersListModule {}
