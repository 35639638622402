import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { SharedModule } from 'src/app/shared';
import { FarmersSearchGridComponent } from './search/farmers-search-grid/farmers-search-grid.component';
import { ParcelsSearchGridComponent } from './search/parcels-search-grid/parcels-search-grid.component';
import { TechniciansSearchGridComponent } from './search/technicians-search-grid/technicians-search-grid.component';
import { SearchService } from './search.service';

@NgModule({
  declarations: [
    SearchComponent,
    FarmersSearchGridComponent,
    ParcelsSearchGridComponent,
    TechniciansSearchGridComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [SearchComponent],
  providers: [SearchService],
})
export class SearchModule {}
