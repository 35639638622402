import { Entity, LinkResource } from 'src/app/core/hal';
import { Cooperative } from 'src/app/shared';

export class RegisteredTechnician extends LinkResource implements Entity {
  id: string;
  codeInCoop: string;
  technicianRefId: string;
  registeredFarmers: EmbeddedRegisteredFarmer[] = [];
  registeredCooperative: EmbeddedRegisteredCooperative;
  cooperative?: Cooperative;

  getRelativeApiUrl = () => '/fs-core/api/registeredTechnicians';

  constructor(registeredTechnicianData?: any) {
    super(registeredTechnicianData);
    if (registeredTechnicianData) {
      this.id = registeredTechnicianData.id;
      this.codeInCoop = registeredTechnicianData.codeInCoop;
      this.technicianRefId = registeredTechnicianData.technicianRefId;
      this.cooperative = registeredTechnicianData.cooperative;
      this.registeredCooperative = new EmbeddedRegisteredCooperative(
        registeredTechnicianData.registeredCooperative
      );
      if (registeredTechnicianData.registeredFarmers) {
        this.registeredFarmers = registeredTechnicianData.registeredFarmers.map(
          registeredFarmer => new EmbeddedRegisteredFarmer(registeredFarmer)
        );
      }
    }
  }
}

export class EmbeddedRegisteredFarmer extends LinkResource {
  farmerRefId: string;
  parcelCount: number;
  parcels: EmbeddedParcel[] = [];
  constructor(registeredFarmersData?: any) {
    super(registeredFarmersData);
    if (registeredFarmersData) {
      this.farmerRefId = registeredFarmersData.farmerRefId;
      this.parcelCount = registeredFarmersData.parcelCount;
      if (registeredFarmersData.parcels) {
        this.parcels = registeredFarmersData.parcels.map(parcel => new EmbeddedParcel(parcel));
      }
    }
  }
}

class EmbeddedParcel extends LinkResource {
  id: string;
  refId: string;
  constructor(parcelData?: any) {
    super(parcelData);
    if (parcelData) {
      this.id = parcelData.id;
      this.refId = parcelData.parcelRefId;
    }
  }
}

export class EmbeddedRegisteredCooperative extends LinkResource {
  id: string;
  cooperativeRefId: string;
  cooperative?: Cooperative;
  constructor(registeredCooperativeData?: any) {
    super(registeredCooperativeData);
    if (registeredCooperativeData) {
      this.id = registeredCooperativeData.id;
      this.cooperativeRefId = registeredCooperativeData.cooperativeRefId;
      this.cooperative = registeredCooperativeData.cooperative;
    }
  }
}
