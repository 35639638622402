import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { Crop } from 'src/app/shared/crop/crop.model';
import { CropService } from 'src/app/shared/crop/crop.service';
import { RegisteredCropService } from 'src/app/shared/registered-crop/registered-crop.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-crop-select',
  template: `
    <dx-select-box
      [dataSource]="crops$ | async"
      displayExpr="label"
      showClearButton="true"
      [value]="value"
      (onValueChanged)="valueChange.emit($event.value)"
    ></dx-select-box>
  `,
})
export class CropSelectComponent implements OnInit, OnChanges {
  @Input() campaign: string;
  @Input() value: Crop;
  @Output() valueChange = new EventEmitter<Crop>();

  crops$: Observable<Crop[]>;

  constructor(
    private cropService: CropService,
    private registeredCropService: RegisteredCropService,
    private httpMappingService: HttpMappingService
  ) {}

  ngOnInit() {
    this.crops$ = this.cropService.getAllCrops('dropdown');
  }

  ngOnChanges(changes) {
    if (changes.campaign && this.campaign) {
      this.filterByCampaign();
    }
  }

  filterByCampaign() {
    const params = new HttpParams().set('projection', 'empty').set('campaign.id', this.campaign);
    this.crops$ = this.registeredCropService.httpGet(params).pipe(
      this.httpMappingService.mapEntities({
        linkToResource: '_links.crop',
        pathToDataInOutput: '_links.cropData',
        projection: 'dropdown',
      }),
      map(responseEntity => responseEntity.getAllEmbedded()),
      map(registeredCrops =>
        _.sortedUniqBy(
          _.sortBy(registeredCrops.map(rc => new Crop(rc._links.cropData)), 'label'),
          'id'
        )
      )
    );
  }
}
