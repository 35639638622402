import { AfterViewInit, Component, Input } from '@angular/core';
import { TimeService } from 'src/app/shared/time/time.service';
import { IrrigationStatus } from 'src/app/shared/irrigation/IrrigationStatus.model';
import { DailyStatus } from 'src/app/shared/irrigation/DailyStatus.model';
import { EntityProviderService } from 'src/app/core/hal';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-irrigation-chart',
  templateUrl: './irrigation-chart.component.html',
  styleUrls: ['./irrigation-chart.component.scss'],
})
export class IrrigationChartComponent implements AfterViewInit {
  @Input() parcelId: string;
  dataSource = [];
  isInit = false;
  irrigationStatusGetter = this.entityProviderService.httpGetter(IrrigationStatus);

  constructor(
    private timeService: TimeService,
    private entityProviderService: EntityProviderService
  ) {
    this.curvePointTooltip = this.curvePointTooltip.bind(this);
  }

  ngAfterViewInit() {
    this.loadData();
    // FIXME: this is a quick hack, the width of the chart is defined during the modal opening animation
    //        then the width of the chart isn't the max
    setTimeout(() => (this.isInit = true), 300);
  }

  loadData() {
    this.irrigationStatusGetter(
      new HttpParams().set('parcelRefId', this.parcelId).set('projection', 'dailyStatuses')
    )
      .pipe(map(responseEntities => responseEntities.getAllEmbedded()))
      .pipe(map(irrigationStatuses => _.head(irrigationStatuses)))
      .subscribe(irrigationStatus => this.convertIrrigationStatusToDataSource(irrigationStatus));
  }

  convertIrrigationStatusToDataSource(irrigationStatus: IrrigationStatus) {
    if (irrigationStatus) {
      const now = new Date();
      const newDataSource = irrigationStatus.dailyStatuses
        .map(dailyStatus => {
          dailyStatus.date = new Date(dailyStatus.date);
          if (dailyStatus.date < now) {
            dailyStatus.previsionalWaterDeficit = null;
          } else {
            dailyStatus.previsionalWaterDeficit = dailyStatus.waterDeficit;
            dailyStatus.waterDeficit = null;
          }
          return dailyStatus;
        })
        .sort((a, b) => (a.date < b.date ? -1 : 1));

      // on cherche le max de toutes les courbes, auquel on ajoute 10%, il sera utilisé pour marquer
      // les zones de stress hydrique
      const max =
        1.1 *
        _.max(
          newDataSource.map(dailyStatus =>
            _.max([
              dailyStatus.rain,
              dailyStatus.rootDrainage,
              dailyStatus.waterDeficit,
              dailyStatus.previsionalWaterDeficit,
              dailyStatus.usableReserve,
              dailyStatus.easilyUsableReserve,
            ])
          )
        );
      // on calcule si la date est en stress hydrique
      newDataSource.forEach(
        dailyStatus =>
          (dailyStatus.highSensibility =
            (dailyStatus.waterDeficit || dailyStatus.previsionalWaterDeficit) >
            dailyStatus.easilyUsableReserve
              ? max
              : null)
      );
      // ajouter la première date pointillée
      for (let i = 1; i < newDataSource.length; i++) {
        if (new Date(newDataSource[i].date) > now) {
          newDataSource[i - 1].previsionalWaterDeficit = newDataSource[i - 1].waterDeficit;
          break;
        }
      }
      this.dataSource = newDataSource;
    } else {
      this.dataSource = [];
    }
  }

  curvePointTooltip(pointInfo) {
    const formatField = (fieldName, value) => `${fieldName} : ${_.isNil(value) ? '' : value}`;
    const pointData: DailyStatus = pointInfo.point.data;
    const tooltipContent = [];
    tooltipContent.push(formatField('Date', pointData.date));
    tooltipContent.push(
      formatField('Réserve facilement utilisable', pointData.easilyUsableReserve)
    );
    tooltipContent.push(formatField('Réserve utile', pointData.usableReserve));
    tooltipContent.push(formatField('Déficit en eau du sol', pointData.waterDeficit));
    tooltipContent.push(
      formatField('Prévision de déficit en eau du sol', pointData.previsionalWaterDeficit)
    );
    tooltipContent.push(formatField('Pluie', pointData.rain));
    tooltipContent.push(formatField('Irrigation', pointData.irrigation));
    tooltipContent.push(formatField('Drainage racinaire', pointData.rootDrainage));
    tooltipContent.push(
      formatField(
        'Période de très grande sensibilité au stress hydrique',
        pointData.highSensibility ? 'oui' : 'non'
      )
    );
    return { html: tooltipContent.join('<br/>') };
  }
}
