import { LinkResource, Entity } from 'src/app/core';

export class PeriodicInput extends LinkResource implements Entity {
  id: string;
  label: string;
  code: string;

  getRelativeApiUrl = () => '/fs-agro-datum/api/periodicInputs';

  constructor(periodicInputData?: any) {
    super(periodicInputData);
    if (periodicInputData) {
      this.id = periodicInputData.id;
      this.label = periodicInputData.label;
      this.code = periodicInputData.code;
    }
  }
}
