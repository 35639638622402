import { LinkResource, Entity } from 'src/app/core/hal';

export class LatestErrors extends LinkResource implements Entity {
  constructor(findLatestErrorsData?: any) {
    super(findLatestErrorsData);
    if (findLatestErrorsData) {
      Object.assign(this, findLatestErrorsData);
    }
  }

  id: string;
  sendDate: Date;
  registeredParcelId: string;
  entite: String;
  message: String;
  getRelativeApiUrl = () => '/fs-connector-fertiweb/api/errors/latestsOfEachParcel';
}
