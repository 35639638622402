import { Item } from './item';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export class MainItem extends Item {
  subItems: Item[];

  constructor(
    label: String,
    icon: IconDefinition,
    routePath: String,
    tooltip: String = null,
    subItems: Item[] = null
  ) {
    super(label, icon, routePath, tooltip);
    this.subItems = subItems;
  }
}
