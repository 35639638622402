import { LinkResource } from 'src/app/core';

export class GeoPngDto extends LinkResource {
  bbox: [number, number, number, number];

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }
}
