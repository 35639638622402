import { Injectable } from '@angular/core';
import { Entity } from './entity.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseEntities } from './response-entities.model';
import { toResponseEntities } from './response-entities-pipeable';
import { map, shareReplay } from 'rxjs/operators';
import {
  HttpDeleter,
  HttpMappingGetter,
  HttpPatcher,
  HttpPoster,
  HttpPutter,
} from './entity-provider.model';
import { environment } from 'src/environments/environment';
import { tapLogError } from 'src/app/core/rxjs-operators/tap-log-error/tap-log-error';

@Injectable({
  providedIn: 'root',
})
export class EntityProviderService {
  constructor(private httpClient: HttpClient) {}

  httpGetter<E extends Entity>(
    TargetType: { new (data?: any): E },
    relativeUrl?: string,
    api?: string
  ): HttpMappingGetter<E> {
    return (params?: HttpParams) =>
      this.httpClient
        .get<ResponseEntities<E>>(
          (api ? api : environment.api) +
            (relativeUrl ? relativeUrl : new TargetType().getRelativeApiUrl()),
          { params }
        )
        .pipe(
          toResponseEntities(TargetType),
          shareReplay(1)
        );
  }

  httpGetterAbsoluteUrl<E extends Entity>(
    TargetType: { new (data?: any): E },
    url: string
  ): HttpMappingGetter<E> {
    return (params: HttpParams) =>
      this.httpClient.get<ResponseEntities<E>>(url, { params }).pipe(
        toResponseEntities(TargetType),
        shareReplay(1)
      );
  }

  httpPost<E extends Entity>(
    TargetType: { new (data?: any): E },
    relativeUrl?: string,
    api?: string
  ): HttpPoster<E> {
    return (data: E, params?: HttpParams) =>
      this.httpClient
        .post<E>(
          (api ? api : environment.api) +
            (relativeUrl ? relativeUrl : new TargetType().getRelativeApiUrl()),
          data,
          { params }
        )
        .pipe(
          map(a => new TargetType(a)),
          tapLogError('httpPost'),
          shareReplay(1)
        );
  }

  httpPut<E extends Entity>(
    TargetType: { new (data?: any): E },
    relativeUrl?: string,
    api?: string
  ): HttpPutter<E> {
    return (data: E, params?: HttpParams) =>
      this.httpClient
        .put<E>(
          (api ? api : environment.api) +
            (relativeUrl ? relativeUrl : new TargetType().getRelativeApiUrl()),
          data,
          { params }
        )
        .pipe(
          map(a => new TargetType(a)),
          tapLogError('httpPut'),
          shareReplay(1)
        );
  }

  httpPatch<E extends Entity>(
    TargetType: { new (data?: any): E },
    relativeUrl: string,
    api?: string
  ): HttpPatcher<E> {
    return (data: E, values, params?: HttpParams) =>
      this.httpClient
        .patch<E>(
          `${api ? api : environment.api}${
            relativeUrl ? relativeUrl : new TargetType().getRelativeApiUrl()
          }/${data.id}`,
          values,
          { params }
        )
        .pipe(
          map(a => new TargetType(a)),
          tapLogError('httpPatch'),
          shareReplay(1)
        );
  }

  httpDelete<E extends Entity>(
    TargetType: { new (data?: any): E },
    relativeUrl: string,
    api?: string
  ): HttpDeleter<E> {
    return (data: E, params?: HttpParams) =>
      this.httpClient
        .delete<E>(
          `${api ? api : environment.api}${
            relativeUrl ? relativeUrl : new TargetType().getRelativeApiUrl()
          }/${data.id}`,
          { params }
        )
        .pipe(tapLogError('httpDelete'));
  }
}
