import { Entity, LinkResource } from 'src/app/core/hal';
import { Parcel, FwRemoteParcelRequest, TotalNDeliveredRecommendation } from 'src/app/shared';

export class RegisteredParcel extends LinkResource implements Entity {
  id: string;
  parcelRefId: string;
  registeredCooperativeId?: string;
  parcel?: Parcel;
  totalNDeliveredRecommendation?: TotalNDeliveredRecommendation;
  fwRemoteParcelRequest?: FwRemoteParcelRequest;

  getRelativeApiUrl = () => '/fs-core/api/registeredParcels';

  constructor(registeredparcelData?: any) {
    super(registeredparcelData);
    if (registeredparcelData) {
      Object.assign(this, registeredparcelData);
    }
  }
}
