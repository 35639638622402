import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ParcelDistributionDto, ParcelService } from 'src/app/shared';
import { TotalNdoseFilter } from 'src/app/feature/statistics/total-ndose/total-ndose-filter';

@Component({
  selector: 'app-total-ndose-distribution',
  templateUrl: './total-ndose-distribution.component.html',
  styleUrls: ['./total-ndose-distribution.component.scss'],
})
export class TotalNdoseDistributionComponent implements OnInit {
  parcels$: Observable<ParcelDistributionDto[]>;
  step = 10;

  constructor(private parcelService: ParcelService) {}

  ngOnInit() {
    this.loadParcels();
  }

  loadParcels(filter?: TotalNdoseFilter) {
    const params = filter ? filter.toHttpParams() : new HttpParams();
    this.parcels$ = this.parcelService.getNDoseParcelDistribution(
      params.set('step', this.step.toString())
    );
  }
}
