import { Entity } from 'src/app/core/hal';

export class RegisteredCooperativeCountDto implements Entity {
  id: string;
  count: number;

  getRelativeApiUrl = () => '/fs-core/api/registeredCooperatives/countByCampaign';

  constructor(dto?: any) {
    if (dto) {
      this.id = dto.id;
      this.count = dto.count;
    }
  }
}
