import { NgModule } from '@angular/core';
import { CampaignsListComponent } from './campaigns-list.component';
import { SharedModule } from 'src/app/shared';
import { CooperativeSelectorPopupComponent } from './cooperative-selector-popup/cooperative-selector-popup.component';
import { CropsEditorComponent } from './crops-editor/crops-editor.component';

@NgModule({
  declarations: [CampaignsListComponent, CooperativeSelectorPopupComponent, CropsEditorComponent],
  imports: [SharedModule],
  exports: [CampaignsListComponent],
  providers: [],
})
export class CampaignsListModule {}
