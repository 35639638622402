import { LinkResource, Entity } from 'src/app/core/hal';

export class Logbook extends LinkResource implements Entity {
  id: string;
  date: Date;
  message: string;

  getRelativeApiUrl = () => '/fs-logbook/api/logbooks';

  constructor(logbookData?: any) {
    super(logbookData);
    if (logbookData) {
      Object.assign(this, logbookData);
    }
  }
}
