import { Cooperative } from 'src/app/shared/cooperative/cooperative.model';
import { User } from './user.model';

export class Coordinator extends User {
  cooperatives: Cooperative[];

  // Required to create new coordinators
  type = 'coordinator';
  profile = 'COORDINATOR';

  constructor(coordinatorData?: any) {
    super(coordinatorData);
    if (coordinatorData) {
      this.cooperatives = coordinatorData.cooperatives;
    }
  }
}
