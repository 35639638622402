import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { DxDataGridComponent } from 'devextreme-angular';
import {
  LatestErrorsService,
  RegisteredParcel,
  Parcel,
  RegisterParcelService,
  TimeService,
} from 'src/app/shared';
import DataSource from 'devextreme/data/data_source';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { tap, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { mapErrorToMsg, ResponseEntities } from 'src/app/core';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';

@Component({
  selector: 'app-errors-daily-fertiweb',
  templateUrl: './errors-daily-fertiweb.component.html',
  styleUrls: ['./errors-daily-fertiweb.component.scss'],
})
export class ErrorsDailyFertiwebComponent implements OnInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  icons = faIcons;
  dataSource: DataSource;
  totalNbErrors: number;
  datasource$: Observable<ResponseEntities<any>>;

  constructor(
    private findLatestErrorsService: LatestErrorsService,
    private datasourceCreator: DxDatasourceCreatorService,
    private httpMappingService: HttpMappingService,
    private registerParcelService: RegisterParcelService,
    private fileDownloaderService: FileDownloaderService,
    private timeService: TimeService
  ) {
    this.calculateDate = this.calculateDate.bind(this);
  }

  ngOnInit() {
    this.initDataSource();
  }

  exportDataGrid() {
    this.dataGrid.instance.exportToExcel(false);
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams => {
        this.datasource$ = this.loadData(searchParams);
        return this.datasource$;
      },
    });
  }

  loadData(searchParams) {
    return this.findLatestErrorsService
      .getLatestsErrorsOfEachParcelByHttpParams(searchParams.httpParams)
      .pipe(
        // TODO : use projection !
        this.httpMappingService.mapEntities({
          pathToIdInInput: 'registeredParcelId',
          targetType: RegisteredParcel,
          pathToDataInOutput: 'registeredParcelData',
        }),
        tap(response => (this.totalNbErrors = response.page.totalElements)),

        // TODO : use projection !
        this.httpMappingService.mapEntities({
          pathToIdInInput: 'registeredParcelData.parcelRefId',
          pathToDataInOutput: 'parcelData',
          targetType: Parcel,
        }),
        shareReplay(1),
        mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
      );
  }

  downloadZip(data) {
    const fileName = data.parcelData.code + '_' + data.datetime + '.zip';
    this.fileDownloaderService.downloadFileUrl(
      data._links.download.href,
      'application/zip',
      fileName
    );
  }

  refreshParcelErrors() {
    this.datasource$.subscribe(responseEntities => {
      const parcelIds: string[] = responseEntities._.map(
        responseEntity => responseEntity.parcelData.id
      );

      const uniqueParcelIds = Array.from(new Set(parcelIds));

      this.registerParcelService
        .getRegisterParcels(uniqueParcelIds)
        .subscribe(x => this.initDataSource());
    });
  }

  calculateDate(dataSource) {
    return this.timeService.utcToTimeZone(dataSource.sendDate);
  }
}
