import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MyAuthGuard } from './my-auth-guard';

export class RouteBuilder {
  static guard(routes: Routes): Routes {
    return (
      (environment.authentication.guardAllRoutes && [
        {
          path: '',
          canActivate: [MyAuthGuard],
          children: routes,
        },
      ]) ||
      routes
    );
  }
}
