import { Entity, LinkResource } from 'src/app/core/hal';
import { DailyStatus } from './DailyStatus.model';

export class IrrigationStatus extends LinkResource implements Entity {
  id: string;
  parcelRefId: string;
  lastComputationDate: Date;
  dailyStatuses: DailyStatus[];

  getRelativeApiUrl = () => '/fs-irrigation/api/irrigationStatuses';

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }
}
