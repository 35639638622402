import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { shareReplay, toArray } from 'rxjs/internal/operators';
import { filter, mergeMap } from 'rxjs/operators';
import { Errors, TestReport } from './test-report.model';
import { ResponseEntities, toResponseEntities, tapLogError } from 'src/app/core';

@Injectable()
export class TestReportService {
  customerDatafileApi = '/fs-customer-datafile/api';

  constructor(private httpClient: HttpClient, route: ActivatedRoute) {}

  getTestReport(dataFileId: string, projection: string): Observable<TestReport> {
    const dataFileUrl =
      environment.api +
      this.customerDatafileApi +
      '/testReports/search/findFirstByDataFileIdOrderByBeginDateDesc';
    return this.httpClient
      .get<TestReport>(dataFileUrl, { params: { uuid: dataFileId, projection } })
      .pipe(
        tapLogError('getTestReports'),
        shareReplay(1)
      );
  }

  getTestReportErrors(
    searchParams: {
      httpParams: HttpParams;
      projection: string;
    },
    testReportId: string,
    entityType?: string
  ): Observable<ResponseEntities<Errors>> {
    let searchUrl =
      environment.api + this.customerDatafileApi + '/testReportErrors/search/findByTestReportId';

    let params = searchParams.httpParams.set('projection', searchParams.projection);
    params = params.set('uuid', testReportId);

    if (entityType) {
      searchUrl += 'AndEntityType';
      params = params.set('type', entityType);
    }

    return this.httpClient.get<ResponseEntities<Errors>>(searchUrl, { params }).pipe(
      toResponseEntities(Errors),
      shareReplay(1)
    );
  }

  filterByEntityType(filterValue: string): (source: Observable<Errors[]>) => Observable<Errors[]> {
    return (source: Observable<Errors[]>) => {
      return source.pipe(
        mergeMap(error => error),
        filter(error => !filterValue || filterValue === error.entityType),
        toArray()
      );
    };
  }
}
