import { LinkResource } from 'src/app/core';

export class HistoricCropWasteUsage extends LinkResource {
  id: string;
  label: string;
  code: string;

  constructor(historicCropWasteUsageData?: any) {
    super(historicCropWasteUsageData);
    if (historicCropWasteUsageData) {
      this.id = historicCropWasteUsageData.id;
      this.label = historicCropWasteUsageData.label;
      this.code = historicCropWasteUsageData.code;
    }
  }
}
