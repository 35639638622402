import { Component, OnInit, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import {
  RegisteredTechnicianService,
  FarmerService,
  Farmer,
  EmbeddedRegisteredFarmer,
} from 'src/app/shared';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { mapErrorToMsg, ResponseEntities } from 'src/app/core';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { DxDataGridComponent } from 'devextreme-angular';
import { httpMapping, HttpMappingParam } from 'src/app/core/rxjs-operators/http-mapping';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';
import { tap } from 'rxjs/operators';
import { map, shareReplay } from 'rxjs/internal/operators';

@Component({
  selector: 'app-campaign-farmer',
  templateUrl: './campaign-farmer.component.html',
  styleUrls: ['./campaign-farmer.component.scss'],
})
export class CampaignFarmerComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

  icons = faIcons;
  technicianId: string;
  dataSource: DataSource;
  totalNbFarmer: number;
  registeredFarmers: EmbeddedRegisteredFarmer[];

  farmerMapping = new HttpMappingParam<Farmer>({
    pathToDataInOutput: 'farmer',
    pathToIdInInput: 'farmerRefId',
    httpGetter: this.entityProviderService.httpGetter(Farmer, '/fs-core/api/farmers'),
  });

  constructor(
    private registeredTechnicianService: RegisteredTechnicianService,
    private farmerService: FarmerService,
    private route: ActivatedRoute,
    private router: Router,
    private entityProviderService: EntityProviderService,
    private datasourceCreator: DxDatasourceCreatorService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.technicianId = params.id;
      this.initDataSource();
    });

    // FIXME orchestration ! bug if initDataSource ends before loadEmbeddedRegisteredFarmer...
    this.loadEmbeddedRegisteredFarmer();
  }

  loadTechicianData() {
    return this.registeredTechnicianService.getRegisteredTechnicianByTechnicianId(
      this.technicianId
    );
  }

  loadFarmerData(searchParams): Observable<ResponseEntities<Farmer>> {
    return this.farmerService.getFarmersFromRegisteredTechnician(
      this.loadTechicianData(),
      'technician-card',
      searchParams.httpParams
    );
  }

  loadEmbeddedRegisteredFarmer() {
    return this.loadTechicianData()
      .pipe(
        map(a => a.registeredFarmers),
        shareReplay(1)
      )
      .subscribe(registeredFarmers => (this.registeredFarmers = registeredFarmers));
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchparams =>
        this.loadFarmerData(searchparams).pipe(
          httpMapping(this.farmerMapping),
          map(resp => {
            resp._embedded.farmers.map(farmer => {
              const registeredFarmer = this.registeredFarmers.find(
                r => r.farmerRefId === farmer.id
              );
              farmer.parcelCount = registeredFarmer ? registeredFarmer.parcelCount : 0;
              return farmer;
            });
            return resp;
          }),
          tap(response => (this.totalNbFarmer = response.page.totalElements)),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
    });
  }

  fullNameColumn_calculateCellValue(dataSource) {
    return dataSource.firstName + ' ' + dataSource.lastName;
  }

  columnsCellClick(e) {
    if (e.column.dataField === 'farmer.code') {
      this.router.navigate(['/farmer-card', e.data.farmer.id]);
    }
  }
}
