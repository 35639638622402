import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, flatMap, shareReplay } from 'rxjs/internal/operators';
import { ResponseEntities, toResponseEntities, tapLogError } from 'src/app/core';
import { Technician } from './technician.model';
import { RegisteredFarmer } from 'src/app/shared/registered-farmer/registered-farmer.model';

@Injectable()
export class TechnicianService {
  fsUserTechnicianApi = environment.api + '/fs-core/api/technicians';

  constructor(private httpClient: HttpClient) {}

  getTechnicianById(id: string, projection: string): Observable<Technician> {
    const technicianIdUrl = this.fsUserTechnicianApi + `/${id}`;
    const params = { projection };
    return this.httpClient.get<Observable<Technician>>(technicianIdUrl, { params }).pipe(
      map(a => new Technician(a)),
      tapLogError('getTechnicianById')
    );
  }

  // return ResponseEntities because we need totalCount
  search(searchParams: {
    httpParams: HttpParams;
    query: string;
  }): Observable<ResponseEntities<Technician>> {
    const url =
      this.fsUserTechnicianApi +
      '/search/findByCodeIgnoreCaseContainingOrFirstNameIgnoreCaseContainingOrLastNameIgnoreCaseContaining';

    return this.httpClient
      .get<ResponseEntities<Technician>>(url, {
        params: searchParams.httpParams.set('value', searchParams.query),
      })
      .pipe(
        toResponseEntities(Technician),
        shareReplay(1)
      );
  }

  getTechnicianFromRegisteredFarmer(
    registeredFarmer$: Observable<RegisteredFarmer>,
    projection: string
  ): Observable<Technician> {
    return registeredFarmer$.pipe(
      map(a => a.technicianRefId),
      flatMap(a => this.getTechnicianById(a, projection)),
      shareReplay(1)
    );
  }

  getByHttpParams(params: HttpParams): Observable<ResponseEntities<Technician>> {
    return this.httpClient
      .get<ResponseEntities<Technician>>(this.fsUserTechnicianApi, { params })
      .pipe(
        toResponseEntities(Technician),
        shareReplay(1)
      );
  }
}
