import { Component, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { Campaign } from 'src/app/shared';
import { ToastrService } from 'ngx-toastr';
import { ProductionTrackingTotalNService } from './services/production-tracking-total-n.service';
import { ProductionTrackingStatus } from './production-tracking-status';
import { finalize } from 'rxjs/operators';
import { IProductionTrackingService } from './services/production-tracking.service.interface';

@Component({
  selector: 'app-production-tracking',
  templateUrl: './production-tracking.component.html',
  styleUrls: ['./production-tracking.component.scss'],
})
export class ProductionTrackingComponent {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;
  cooperativeStatus: ProductionTrackingStatus[];
  summary: { label: string; count: number }[] = [];
  campaign: Campaign;
  icons = faIcons;
  selectedTrackingService: IProductionTrackingService;
  trackingServices: IProductionTrackingService[] = [];

  static getPdfStatusTooltip(arg) {
    const pc = arg.target ? (arg.value * 100) / arg.target : 100;
    return {
      text: 'PDF produit : ' + arg.value + ' ( ' + pc + '% )',
    };
  }

  getS = count => (count && count > 1 ? 's' : '');

  constructor(
    private toasterService: ToastrService,
    private productionTrackingTotalNService: ProductionTrackingTotalNService
  ) {
    this.trackingServices.push(productionTrackingTotalNService);
    this.selectedTrackingService = this.trackingServices[0];
  }

  exportDataGrid() {
    this.datagrid.instance.exportToExcel(false);
  }

  generateMissingPdf() {
    if (this.campaign && this.selectedTrackingService) {
      this.selectedTrackingService
        .generateMissingPdf(this.campaign)
        .subscribe(() =>
          this.toasterService.success('Génération demandée avec succés, traitements en cours.')
        );
    }
  }

  updateData() {
    if (this.campaign && this.selectedTrackingService) {
      this.datagrid.instance.beginCustomLoading('Mise à jour des données');
      this.selectedTrackingService
        .getData(this.campaign)
        .pipe(finalize(() => this.datagrid.instance.endCustomLoading()))
        .subscribe(res => this.setStatus(res));
    } else {
      this.setStatus([]);
    }
  }

  setStatus(status) {
    this.cooperativeStatus = status;
    this.updateTotal();
  }

  updateTotal() {
    this.summary = [];
    const tot = new ProductionTrackingStatus({});
    this.cooperativeStatus.forEach(status => {
      tot.failedCount += status.failedCount;
      tot.pdfCount += status.pdfCount;
      tot.computedCount += status.computedCount;
      tot.totalCount += status.totalCount;
    });

    this.summary.push({ label: 'PDF produits', count: tot.pdfCount });
    this.summary.push({ label: 'Calculées sans PDF', count: tot.computedCount - tot.pdfCount });
    this.summary.push({
      label: 'Non calculées',
      count: tot.totalCount - tot.computedCount,
    });
  }

  btnAreDisabled() {
    return !(this.campaign && this.selectedTrackingService);
  }

  customizePieLabel(dxChartObject): string {
    return `${dxChartObject.value} (${Math.round(dxChartObject.percent * 100)}%)`;
  }

  getComputedTooltip(data) {
    return `${data.computedCount} parcelle${this.getS(data.computedCount)} calculée${this.getS(
      data.computedCount
    )}, ${data.failedCount} parcelle${this.getS(data.failedCount)} en erreur`;
  }
  generate(cooperativeId) {
    if (this.campaign && this.selectedTrackingService) {
      this.selectedTrackingService
        .generateMissingPdfByCoop(this.campaign, cooperativeId)
        .subscribe(() =>
          this.toasterService.success('Génération demandée avec succés, traitements en cours.')
        );
    }
  }
}
