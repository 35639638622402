import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import {
  Campaign,
  Cooperative,
  Parcel,
  FwRemoteParcelRequest,
  RegisteredCooperative,
  RegisteredParcel,
  TotalNDeliveredRecommendation,
} from 'src/app/shared';
import { ProductionTrackingStatus } from 'src/app/feature/production-tracking/production-tracking-status';
import { Observable, of } from 'rxjs';
import { EntityProviderService } from 'src/app/core/hal';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { IProductionTrackingService } from './production-tracking.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductionTrackingTotalNService implements IProductionTrackingService {
  // to get associated Cooperative of registeredCooperatives, to have the name
  registeredCooperativeCooperativeMapping = {
    targetType: Cooperative,
    pathToIdInInput: 'cooperativeRefId',
    pathToDataInOutput: 'cooperative',
    projection: 'cooperativeList',
  };

  // to get associated Parcel of registeredParcels, to have the projectedDoseComputationDate
  registeredParcelParcelMapping = {
    targetType: Parcel,
    pathToIdInInput: 'parcelRefId',
    pathToDataInOutput: 'parcel',
    projection: 'projectedDoseComputationDate',
  };

  registeredParcelTotalNDeliveredRecommendationMapping = {
    targetType: TotalNDeliveredRecommendation,
    pathToIdInInput: 'parcelRefId',
    keyToSearchInGetter: 'parcelRefId',
    pathToDataInOutput: 'totalNDeliveredRecommendation',
    projection: 'parcel-ref',
  };

  registeredParcelFwRemoteParcelRequestMapping = {
    targetType: FwRemoteParcelRequest,
    keyToSearchInGetter: 'registeredParcelId',
    pathToDataInOutput: 'fwRemoteParcelRequest',
    projection: 'ids',
  };

  // to get RegisteredParcel[] of RegisteredCooperatives, to have the projectedDoseComputationDate
  //  and the totalNDeliveredRecommendation
  registeredCooperativeRegisteredParcelMapping = {
    targetType: RegisteredParcel,
    projection: 'registeredCooperative',
    keyToSearchInGetter: 'registeredFarmer.registeredCooperative.id',
    pathToDataInOutput: 'registeredParcels',
    reverseMapping: {
      useArrayInOutput: true,
    },
    httpGetter: params =>
      this.entityProviderService
        .httpGetter(RegisteredParcel)(params)
        .pipe(
          this.httpMappingService.mapEntities(
            this.registeredParcelParcelMapping,
            this.registeredParcelTotalNDeliveredRecommendationMapping,
            this.registeredParcelFwRemoteParcelRequestMapping
          )
          // TODO :   mergeAllPages
        ),
  };

  registeredCooperativeGetter = params =>
    this.entityProviderService
      .httpGetter(RegisteredCooperative)(params)
      .pipe(
        this.httpMappingService.mapEntities(this.registeredCooperativeCooperativeMapping),
        this.httpMappingService.mapEntities(this.registeredCooperativeRegisteredParcelMapping)
      );

  getProductionTrackingStatus(coopResponse): ProductionTrackingStatus {
    const registeredParcels = coopResponse.registeredParcels;
    return registeredParcels
      ? new ProductionTrackingStatus({
          cooperativeName: coopResponse.cooperative && coopResponse.cooperative.name,
          cooperativeId: coopResponse.cooperative && coopResponse.cooperative.id,
          totalCount: registeredParcels.length,
          computedCount: registeredParcels.filter(
            registeredParcel =>
              registeredParcel.parcel && registeredParcel.parcel.projectedDoseComputationDate
          ).length,
          pdfCount: registeredParcels.filter(
            registeredParcel => registeredParcel.totalNDeliveredRecommendation
          ).length,
          failedCount: registeredParcels.filter(
            registeredParcel =>
              registeredParcel.fwRemoteParcelRequest &&
              registeredParcel.fwRemoteParcelRequest.failed
          ).length,
        })
      : new ProductionTrackingStatus({
          cooperativeName: coopResponse.cooperative && coopResponse.cooperative.name,
          cooperativeId: coopResponse.cooperative && coopResponse.cooperative.id,
        });
  }

  parseResults(response): ProductionTrackingStatus[] {
    return response.getAllEmbedded().map(r => this.getProductionTrackingStatus(r));
  }
  constructor(
    private entityProviderService: EntityProviderService,
    private httpMappingService: HttpMappingService,
    private toasterService: ToastrService
  ) {}

  getData(campaign: Campaign): Observable<ProductionTrackingStatus[]> {
    if (campaign) {
      return this.registeredCooperativeGetter(
        new HttpParams().set('campaign', campaign.id).set('projection', 'campaign-list')
      ).pipe(map(r => this.parseResults(r)));
    } else {
      return of([]);
    }
  }

  generateMissingPdf(campaign: Campaign) {
    const params = new HttpParams().set('campaignId', campaign.id);
    return this.entityProviderService.httpPut(TotalNDeliveredRecommendation)(null, params);
  }

  generateMissingPdfByCoop(campaign: Campaign, cooperativeId: string): Observable<object> {
    const params = new HttpParams()
      .set('cooperativeId', cooperativeId)
      .set('campaignId', campaign.id);
    return this.entityProviderService.httpPut(TotalNDeliveredRecommendation)(null, params);
  }

  getTitle() {
    return 'Dose Totale';
  }

  getProductionTitle() {
    return 'Production de dose Totale';
  }
}
