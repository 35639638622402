import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/internal/operators';
import { ResponseEntities, tapLogError } from 'src/app/core';
import { RegisteredParcel } from './registered-parcel.model';

@Injectable()
export class RegisteredParcelService {
  constructor(private httpClient: HttpClient) {}

  registeredParcelUrl = environment.api + '/fs-core/api/registeredParcels';
  getAllRegisteredParcels(): Observable<RegisteredParcel[]> {
    return this.httpClient.get<ResponseEntities<RegisteredParcel[]>>(this.registeredParcelUrl).pipe(
      map(a => new ResponseEntities<RegisteredParcel>(RegisteredParcel, a)),
      map(a => a.getAllEmbedded()),
      tapLogError('getAllRegisteredParcels'),
      shareReplay(1)
    );
  }

  getRegisteredParcel(parcelId: string): Observable<RegisteredParcel> {
    const params = new HttpParams().set('parcelRefId', parcelId);
    return this.httpClient
      .get<ResponseEntities<RegisteredParcel>>(this.registeredParcelUrl, {
        params,
      })
      .pipe(
        map(a => new ResponseEntities<RegisteredParcel>(RegisteredParcel, a)),
        map(a => a.getAllEmbedded()),
        tapLogError('getAllRegisteredParcels'),
        shareReplay(1)
      );
  }
}
