import { LinkResource, Entity } from 'src/app/core/hal';

export class ServicePack extends LinkResource implements Entity {
  static relativeApiUrl = '/fs-core/api/servicePacks';
  id: string;
  getRelativeApiUrl = () => ServicePack.relativeApiUrl;
  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }
}
