import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NDoseComputationMethod } from 'src/app/shared/n-dose-computation-method/n-dose-computation-method.model';
import { NDoseComputationMethodsService } from 'src/app/shared/n-dose-computation-method/n-dose-computation-method.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-computation-method-select',
  template: `
    <dx-select-box
      [dataSource]="nDoseComputationMethods$ | async"
      displayExpr="label"
      showClearButton="true"
      [value]="value"
      (onValueChanged)="valueChange.emit($event.value)"
    ></dx-select-box>
  `,
})
export class NdoseComputationMethodSelectComponent implements OnInit {
  @Input() value: NDoseComputationMethod;
  @Output() valueChange = new EventEmitter<NDoseComputationMethod>();

  nDoseComputationMethods$: Observable<NDoseComputationMethod[]>;

  constructor(private nDoseComputationMethodsService: NDoseComputationMethodsService) {}

  ngOnInit() {
    this.nDoseComputationMethods$ = this.nDoseComputationMethodsService.getAllComputationMethods(
      'dropdown'
    );
  }
}
