import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ParcelLaiInfoDto } from './parcel-lai-info-dto.model';
import { GeoPngDto } from './geo-png-dto.model';
import { LaiCurvePointDto } from './lai-curve-point-dto.model';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';
import { environment } from 'src/environments/environment';
import { ResponseEntities } from 'src/app/core';
import { toResponseEntities } from 'src/app/core/hal';
import { shareReplay, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { BiophyLayer } from 'src/app/shared/biophy-value/biophy-layer.enum';

@Injectable({
  providedIn: 'root',
})
export class BiophyValueService {
  fsBiophyValue = environment.api + '/fs-biophy/api/biophyValues';

  constructor(private httpClient: HttpClient, private fileDownloader: FileDownloaderService) {}

  getLastLaiLayer(parcelId: string): Observable<ParcelLaiInfoDto> {
    const url = this.fsBiophyValue + '/lai/png/last';
    const params = { parcelId };
    return this.httpClient.get<ResponseEntities<ParcelLaiInfoDto>>(url, { params }).pipe(
      toResponseEntities(ParcelLaiInfoDto),
      map(respEntity => {
        const embedded = respEntity.getAllEmbedded();
        return _.isEmpty(embedded) ? null : embedded[0];
      }),
      shareReplay(1)
    );
  }

  getLastTrueColorLayer(parcelId: string): Observable<GeoPngDto> {
    const url = this.fsBiophyValue + '/fieldImage/png/last';
    const params = { parcelId };
    return this.httpClient.get<GeoPngDto>(url, { params }).pipe(
      map(geoPng => new GeoPngDto(geoPng)),
      shareReplay(1)
    );
  }

  getLaiCurve(parcelId: string, includeBlacklisted: boolean): Observable<LaiCurvePointDto[]> {
    const url = this.fsBiophyValue + `/${parcelId}/lai/statistics/median`;
    const params = { blacklisted: includeBlacklisted ? 'true' : 'false' };
    return this.httpClient.get<LaiCurvePointDto>(url, { params }).pipe(
      toResponseEntities(LaiCurvePointDto),
      map(respEntities => respEntities.getAllEmbedded()),
      shareReplay(1)
    );
  }

  getLayerByAcquisitionID(
    parcelId: string,
    type: BiophyLayer,
    acquisitionID: string
  ): Observable<GeoPngDto> {
    const url = this.fsBiophyValue + `/${parcelId}/${type}/png`;
    const params = { acquisitionID };
    return this.httpClient.get<GeoPngDto>(url, { params }).pipe(
      map(geoPng => new GeoPngDto(geoPng)),
      shareReplay(1)
    );
  }

  patchBiophyValue(biophyValueId: string, data: {}): Observable<any> {
    const url = this.fsBiophyValue + `/${biophyValueId}`;
    return this.httpClient.patch<any>(url, data);
  }
}
