import { NgModule } from '@angular/core';
import { FarmerCardComponent } from './farmer-card.component';
import { SharedModule } from 'src/app/shared';
import { CampaignParcelComponent } from './campaign-parcel/campaign-parcel.component';
import { FarmerDetailsComponent } from './farmer-details/farmer-details.component';

@NgModule({
  declarations: [FarmerCardComponent, CampaignParcelComponent, FarmerDetailsComponent],
  imports: [SharedModule],
  exports: [FarmerCardComponent],
  providers: [],
})
export class FarmerCardModule {}
