import { Component, ViewChild } from '@angular/core';
import { Coordinator } from 'src/app/shared';

import { DxDataGridComponent } from 'devextreme-angular';
import { UsersListComponent } from 'src/app/feature/users-list/users-list.component';

@Component({
  selector: 'app-coordinators-list',
  templateUrl: '../users-list.component.html',
  styleUrls: ['../users-list.component.scss'],
})
export class CoordinatorsListComponent extends UsersListComponent {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  name = {
    singular: 'Coordinateur',
    plural: 'Coordinateurs',
  };

  displayCoop = true;

  getEndpoint() {
    return 'coordinators';
  }

  getEndpointFilter() {
    return this.getEndpoint();
  }

  getType() {
    return Coordinator;
  }
}
