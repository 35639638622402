import { AfterViewInit, Component, ViewChild } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { SearchComponent } from 'src/app/feature/search/search/search.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements AfterViewInit {
  icons = faIcons;
  query: String = '';
  isProduction: boolean = environment.production;
  @ViewChild('searchInput') searchInput;

  constructor(private router: Router, private route: ActivatedRoute) {
    const firstLoadSuscriber = this.router.events
      .pipe(filter((event): event is ActivationEnd => event instanceof ActivationEnd))
      .subscribe(event => {
        if (SearchComponent === event.snapshot.component) {
          this.query = event.snapshot.params.query;
        }
        firstLoadSuscriber.unsubscribe();
      });
  }

  ngAfterViewInit() {
    this.searchInput.valueChanges
      .pipe(
        skip(1),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(model => this.search());
  }

  search() {
    if (this.query) {
      this.router.navigate(['/search', this.query]);
    } else {
      this.router.navigate(['/search']);
    }
  }

  testAuthent() {
    this.router.navigate(['/secured']);
  }
}
