import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, flatMap, shareReplay } from 'rxjs/internal/operators';
import { tapLogError } from 'src/app/core';
import { Variety } from './variety.model';
import { Parcel } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class VarietyService {
  constructor(private httpClient: HttpClient) {}

  getVarietyById(id: string): Observable<Variety> {
    const cropIdUrl = environment.api + '/fs-agro-datum/api/varieties/' + id;
    return this.httpClient.get<Observable<Variety>>(cropIdUrl).pipe(
      map(a => new Variety(a)),
      tapLogError('getVarietyById')
    );
  }

  getVarietyFromParcels(parcel: Observable<Parcel>): Observable<Variety> {
    return parcel.pipe(
      map(a => a.agroData.crop.varietyRefId),
      flatMap(a => this.getVarietyById(a)),
      shareReplay(1)
    );
  }
}
