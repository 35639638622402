import { Component, Input, Output, EventEmitter } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseEntities } from 'src/app/core';
import { Errors, TestReportService } from 'src/app/shared';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-test-report-errors-grid',
  templateUrl: './test-report-errors-grid.component.html',
  styleUrls: ['./test-report-errors-grid.component.scss'],
})
export class TestReportErrorsGridComponent {
  @Input() entityType: string;
  @Input() testReport: string;
  @Output() totalElements = new EventEmitter<number>();

  getEntityLabel = this.getEntityFromValue.bind(this);

  dataSource: DataSource;

  constructor(
    private testReportService: TestReportService,
    private datasourceCreator: DxDatasourceCreatorService
  ) {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.loadErrors(searchParams).pipe(tap(r => this.totalElements.emit(r.page.totalElements))),
      getLoadParams: () => ({
        httpParams: new HttpParams().set('projection', 'testReportErrorInfo'),
      }),
    });
  }

  loadErrors(searchParams): Observable<ResponseEntities<Errors>> {
    const type = this.entityType === 'ALL' ? null : this.entityType;
    return this.testReportService.getTestReportErrors(searchParams, this.testReport, type);
  }

  getEntityFromValue(error) {
    let label;
    switch (error.entityType) {
      case 'TECHNICIAN':
        label = 'Technicien';
        break;
      case 'FARMER':
        label = 'Exploitant';
        break;
      case 'PARCEL':
        label = 'Parcelle';
        break;
    }
    return label;
  }
}
