import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { ReversedGrasslandAge } from './reversed-grassland-age.model';

@Injectable({
  providedIn: 'root',
})
export class ReversedGrasslandAgeService {
  constructor(private httpClient: HttpClient) {}

  getReversedGrasslandAgeById(id: string): Observable<ReversedGrasslandAge> {
    const reversedGrasslandAgeByIdIdUrl =
      environment.api + '/fs-agro-datum/api/reversedGrasslandAges/' + id;
    return this.httpClient
      .get<Observable<ReversedGrasslandAge>>(reversedGrasslandAgeByIdIdUrl)
      .pipe(
        map(a => new ReversedGrasslandAge(a)),
        tapLogError('getReversedGrasslandAgeById')
      );
  }

  getReversedGrasslandAgeFromParcel(parcel$: Observable<Parcel>): Observable<ReversedGrasslandAge> {
    return parcel$.pipe(
      map(a => a.agroData.activities.grasslandActivity.reversedGrasslandAgeRefId),
      flatMap(reversedGrasslandAgeRefId =>
        this.getReversedGrasslandAgeById(reversedGrasslandAgeRefId)
      ),
      shareReplay(1)
    );
  }
}
