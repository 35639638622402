import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arvalis',
  templateUrl: './arvalis.component.html',
  styleUrls: ['./arvalis.component.scss'],
})
export class ArvalisComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
