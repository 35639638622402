import { LinkResource } from 'src/app/core';

export class NDoseComputationEngine extends LinkResource {
  id: string;
  label: string;
  code: string;
  internal: boolean;
  ndoseComputationMethods: NDoseComputationMethods[];

  constructor(nDoseComputationEngineData?: any) {
    super(nDoseComputationEngineData);
    if (nDoseComputationEngineData) {
      this.id = nDoseComputationEngineData.id;
      this.label = nDoseComputationEngineData.label;
      this.code = nDoseComputationEngineData.code;
      this.internal = nDoseComputationEngineData.internal;
      if (nDoseComputationEngineData.crops) {
        this.ndoseComputationMethods = nDoseComputationEngineData.ndoseComputationMethods.map(
          ndoseComputationMethod => new NDoseComputationMethods(ndoseComputationMethod)
        );
      }
    }
  }
}

class NDoseComputationMethods extends LinkResource {
  id: string;
  label: string;
  code: string;
  constructor(ndoseComputationMethodsData?: any) {
    super(ndoseComputationMethodsData);
    if (ndoseComputationMethodsData) {
      this.id = ndoseComputationMethodsData.id;
      this.label = ndoseComputationMethodsData.label;
      this.code = ndoseComputationMethodsData.code;
    }
  }
}
