import { LinkResource } from './link-resource.model';

export function getAllEmbedded<T>(responseEntities: ResponseEntities<T>): T[] {
  return responseEntities._embedded
    ? Object.values(responseEntities._embedded).reduce((data, curr) => data.concat(curr), [])
    : [];
}

export class ResponseEntities<T> extends LinkResource {
  _embedded: Embedded<T>;
  page?: Page;

  constructor(TargetType: { new (data?: any): T }, responseEntitiesData?: any) {
    super(responseEntitiesData);
    if (responseEntitiesData) {
      this._embedded = new Embedded<T>(TargetType, responseEntitiesData._embedded);
      this.page = new Page(responseEntitiesData.page);
    } else {
      this._embedded = new Embedded<T>(TargetType, []);
      this.page = new Page({ totalElements: 0, totalPages: 0 });
    }
  }

  getAllEmbedded(): T[] {
    return getAllEmbedded(this);
  }

  get _() {
    return this.getAllEmbedded();
  }
}

export class Embedded<T> {
  [key: string]: T[];

  constructor(TargetType: { new (data?: any): T }, embeddedData?: any) {
    if (embeddedData) {
      for (const key of Object.keys(embeddedData)) {
        this[key] = [];
        for (const item of embeddedData[key]) {
          this[key].push(TargetType ? new TargetType(item) : item);
        }
      }
    }
  }
}

class Page {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;

  constructor(pageData?: any) {
    if (pageData) {
      this.size = pageData.size;
      this.totalElements = pageData.totalElements;
      this.totalPages = pageData.totalPages;
      this.number = pageData.number;
    }
  }
}
