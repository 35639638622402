import { LinkResource } from 'src/app/core';

export class User extends LinkResource {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  adress: string;
  zipCode: string;
  city: string;
  phone1: string;
  phone2: string;

  constructor(userData?: any) {
    super(userData);
    if (userData) {
      this.id = userData.id;
      this.firstName = userData.firstName;
      this.lastName = userData.lastName;
      this.email = userData.email;
      this.adress = userData.adress;
      this.zipCode = userData.zipCode;
      this.city = userData.city;
      this.phone1 = userData.phone1;
      this.phone2 = userData.phone2;
    }
  }
}
