import { LinkResource } from 'src/app/core';

export class ParcelLaiInfoDto extends LinkResource {
  parcelId: string;
  sensingDate: Date;
  median: number;
  bbox: [number, number, number, number];

  constructor(data?: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
      this.sensingDate = new Date(data.sensingDate);
    }
  }
}
