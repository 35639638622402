export class ParcelDistributionDto {
  parcels: number;
  projectedDoseRange: string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
