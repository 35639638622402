import { Component } from '@angular/core';

@Component({
  selector: 'app-titled-panel',
  templateUrl: './titled-panel.component.html',
  styleUrls: ['./titled-panel.component.scss'],
})
export class TitledPanelComponent {
  constructor() {}
}
