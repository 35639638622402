import { LinkResource, Entity } from 'src/app/core/hal';
import { Cooperative, RegisteredParcel } from 'src/app/shared';

export class RegisteredCooperative extends LinkResource implements Entity {
  id: string;
  cooperativeRefId: string;
  campaign: EmbeddedCampaign;
  registeredParcels?: RegisteredParcel[];
  cooperative?: Cooperative;

  getRelativeApiUrl = () => '/fs-core/api/registeredCooperatives';

  constructor(registeredCooperativeData?: any) {
    super(registeredCooperativeData);
    if (registeredCooperativeData) {
      this.id = registeredCooperativeData.id;
      this.cooperativeRefId = registeredCooperativeData.cooperativeRefId;
      this.cooperative = registeredCooperativeData.cooperative;
      this.campaign = new EmbeddedCampaign(registeredCooperativeData.campaign);
    }
  }
}

class EmbeddedCampaign {
  active: boolean;
  code: string;
  id: string;
  label: string;
  year: number;
  crops: Crops[];

  constructor(campaignData?: any) {
    if (campaignData) {
      this.active = campaignData.active;
      this.code = campaignData.code;
      this.id = campaignData.id;
      this.label = campaignData.label;
      this.year = campaignData.year;
      if (campaignData.crops) {
        this.crops = campaignData.crops.map(crop => new Crops(crop));
      }
    }
  }
}

class Crops {
  id: string;
  refId: string;
  constructor(cropsData?: any) {
    if (cropsData) {
      this.id = cropsData.id;
      this.refId = cropsData.refId;
    }
  }
}
