import { Component, Output, EventEmitter } from '@angular/core';
import { TotalNdoseFilter } from 'src/app/feature/statistics/total-ndose/total-ndose-filter';
import { Campaign } from 'src/app/shared';

@Component({
  selector: 'app-total-ndose-filter',
  templateUrl: './total-ndose-filter.component.html',
  styleUrls: ['./total-ndose-filter.component.scss'],
})
export class TotalNdoseFilterComponent {
  campaign: Campaign;
  @Output() filterUpdated = new EventEmitter<TotalNdoseFilter>();

  filters = new TotalNdoseFilter();

  constructor() {}

  updateFilter(filter: string, value: any) {
    this.filters[filter] = value;
    this.filterUpdated.emit(this.filters);
  }
}
