import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BiophyLayer } from 'src/app/shared/biophy-value/biophy-layer.enum';

@Component({
  selector: 'app-biophy-toggle',
  templateUrl: './biophy-toggle.component.html',
  styleUrls: ['./biophy-toggle.component.scss'],
})
export class BiophyToggleComponent {
  @Input() toggleValue = 'lai';
  @Output() toggleValueChange = new EventEmitter<string>();

  layers = BiophyLayer;

  constructor() {}
}
