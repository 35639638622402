import { Component, OnChanges, Input } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import {
  ParcelService,
  Parcel,
  ParcelCardDto,
  OrganicWasteService,
  PreviousCrop,
} from 'src/app/shared';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { HttpMappingService } from 'src/app/core/rxjs-operators/http-mapping/http-mapping.service';
import { DxDatasourceCreatorService, mapErrorToMsg } from 'src/app/core';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-crop-infos',
  templateUrl: './crop-infos.component.html',
  styleUrls: ['./crop-infos.component.scss'],
})
export class CropInfosComponent implements OnChanges {
  @Input() parcelId: string;
  dataSource: DataSource;
  icons = faIcons;
  parcel$: Observable<Parcel>;

  parcelAgroData$: Observable<ParcelCardDto>;

  dateFormat = 'dd/MM/yyyy';

  constructor(
    private parcelService: ParcelService,
    private organicWasteService: OrganicWasteService,
    private httpMappingService: HttpMappingService,
    private datasourceCreator: DxDatasourceCreatorService
  ) {}

  ngOnChanges() {
    if (this.parcelId) {
      this.loadCropInfos();
    }
  }

  loadCropInfos() {
    this.parcel$ = this.parcelService.getParcelById(this.parcelId, 'card-crop-detail').pipe(
      this.httpMappingService.mapEntity({
        pathToIdInInput: 'agroData.activities.anteprevious.previousCropRefId',
        targetType: PreviousCrop,
        pathToDataInOutput: 'previousCropData',
      })
    );
    this.parcelService.getParcelAgroData(this.parcel$).subscribe(parcelDto => {
      this.getOrganicWasteTypes(parcelDto);
    });
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.parcelService
          .httpGet(searchParams.httpParams)
          .pipe(mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')),
    });
  }

  getOrganicWasteTypes(parcelCard: ParcelCardDto) {
    this.organicWasteService
      .getOrganicWasteByIds(parcelCard.getOrganicWasteRefs(), 'parcel-card')
      .subscribe(organicWastes => {
        parcelCard.getOrganicWastes().forEach(ow => {
          const foundWaste: any = _.find(organicWastes, w => w.id === ow.refId) || {};
          ow.liquid = foundWaste.liquid;
        });
        this.parcelAgroData$ = of(parcelCard);
      });
  }

  getConversionUnit(organicWaste): string {
    return organicWaste.liquid ? 'm^3/hectare' : 'tonne/hectare';
  }
}
