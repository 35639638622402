import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared';
import { ParcelsListComponent } from './parcels-list.component';

@NgModule({
  declarations: [ParcelsListComponent],
  imports: [SharedModule],
  exports: [ParcelsListComponent],
  providers: [],
})
export class ParcelsListModule {}
