import { NgModule } from '@angular/core';
import { ErrorsDailyFertiwebComponent } from './errors-daily-fertiweb.component';
import { SharedModule } from 'src/app/shared';

@NgModule({
  declarations: [ErrorsDailyFertiwebComponent],
  imports: [SharedModule],
  exports: [ErrorsDailyFertiwebComponent],
  providers: [],
})
export class ErrorsDailyFertiwebModule {}
