import { NgModule } from '@angular/core';
import { IngestionReportComponent } from './ingestion-report/ingestion-report.component';
import { TestReportComponent } from './test-report/test-report.component';
import { ReportsComponent } from './reports.component';
import { SharedModule } from 'src/app/shared';
import { IngestionReportService, TestReportService } from 'src/app/shared';
import { TestReportErrorsGridComponent } from './test-report/test-report-errors-grid/test-report-errors-grid.component';

@NgModule({
  declarations: [
    IngestionReportComponent,
    TestReportComponent,
    ReportsComponent,
    TestReportErrorsGridComponent,
  ],
  imports: [SharedModule],
  exports: [ReportsComponent],
  providers: [IngestionReportService, TestReportService],
})
export class ReportsModule {}
