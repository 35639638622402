import { NgModule } from '@angular/core';
import { BaseUnitConverterPipe } from './base-unit-converter.pipe';
import { BoolToTextPipe } from './bool-to-text.pipe';
import { UnitConverterPipe } from './unit-converter.pipe';
import { UnitDisplayPipe } from './unit-display.pipe';
import { UtcDateDisplayPipe } from './utc-date-display.pipe';

@NgModule({
  declarations: [
    BaseUnitConverterPipe,
    BoolToTextPipe,
    UnitConverterPipe,
    UnitDisplayPipe,
    UtcDateDisplayPipe,
  ],
  exports: [
    BaseUnitConverterPipe,
    BoolToTextPipe,
    UnitConverterPipe,
    UnitDisplayPipe,
    UtcDateDisplayPipe,
  ],
  providers: [
    BaseUnitConverterPipe,
    BoolToTextPipe,
    UnitConverterPipe,
    UnitDisplayPipe,
    UtcDateDisplayPipe,
  ],
})
export class PipesModule {}
