export class LinkResource {
  _links: Links;
  constructor(linkResourceData?: any) {
    if (linkResourceData) {
      this._links = linkResourceData._links;
    }
  }
}

class Links {
  [key: string]: LinkHref;
  constructor(linksData?: any) {
    if (linksData) {
      this.key = linksData.key;
    }
  }
}

class LinkHref {
  href: string;
  constructor(linkHrefData?: any) {
    if (linkHrefData) {
      this.href = linkHrefData.href;
    }
  }
}
