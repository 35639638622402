import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/internal/operators';
import { RegisteredCooperative } from './registered-cooperative.model';
import { Campaign } from 'src/app/shared/campaign/campaign.model';
import { ResponseEntities, tapLogError } from 'src/app/core';
import { toResponseEntities } from 'src/app/core/hal';
import { RestResourceService } from 'src/app/core';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';

@Injectable({
  providedIn: 'root',
})
export class RegisteredCooperativeService {
  relativeUrl = '/fs-core/api/registeredCooperatives';
  cooperativesUrl = '/fs-core/api/cooperatives';
  fsCampaignRegisteredCoopApi = environment.api + this.relativeUrl;
  fsCoreCoopApi = environment.api + this.cooperativesUrl;

  httpDelete = this.entityProviderService.httpDelete(RegisteredCooperative, this.relativeUrl);
  httpGet = this.entityProviderService.httpGetter(RegisteredCooperative, this.relativeUrl);

  constructor(
    private httpClient: HttpClient,
    private restResourceService: RestResourceService,
    private entityProviderService: EntityProviderService
  ) {}

  getAllRegisteredCooperatives(): Observable<RegisteredCooperative[]> {
    const allCooperativeUrl = this.fsCampaignRegisteredCoopApi;
    return this.httpClient.get<ResponseEntities<RegisteredCooperative[]>>(allCooperativeUrl).pipe(
      map(a => new ResponseEntities<RegisteredCooperative>(RegisteredCooperative, a)),
      map(a => a.getAllEmbedded()),
      tapLogError('getAllRegisteredCooperatives'),
      shareReplay(1)
    );
  }

  getPaginatedRegisteredCooperatives(searchParams: {
    httpParams: HttpParams;
    projection: string;
  }): Observable<ResponseEntities<RegisteredCooperative>> {
    const params = searchParams.httpParams.set('projection', searchParams.projection);
    return this.getByHttpParams(params);
  }

  getByHttpParams(params: HttpParams): Observable<ResponseEntities<RegisteredCooperative>> {
    return this.httpClient
      .get<ResponseEntities<RegisteredCooperative>>(this.fsCampaignRegisteredCoopApi, { params })
      .pipe(
        toResponseEntities(RegisteredCooperative),
        shareReplay(1)
      );
  }

  getByCampaignId(campaignId: string, projection: string): Observable<RegisteredCooperative[]> {
    const params = new HttpParams().set('projection', projection).set('campaign.id', campaignId);
    return this.httpClient
      .get<ResponseEntities<RegisteredCooperative[]>>(this.fsCampaignRegisteredCoopApi, { params })
      .pipe(
        toResponseEntities(RegisteredCooperative),
        map(a => a.getAllEmbedded()),
        shareReplay(1)
      );
  }

  createRegisteredCooperative(cooperativeRefId: string, campaign: Campaign) {
    const postData = {
      cooperative: this.fsCoreCoopApi + '/' + cooperativeRefId,
      campaign: this.restResourceService.getUrl(campaign),
    };
    return this.httpClient.post<RegisteredCooperative>(this.fsCampaignRegisteredCoopApi, postData);
  }
}
