import { NgModule } from '@angular/core';
import { HomePageComponent } from './home-page.component';
import { SharedModule } from 'src/app/shared';

@NgModule({
  declarations: [HomePageComponent],
  imports: [SharedModule],
  exports: [HomePageComponent],
  providers: [],
})
export class HomePageModule {}
