import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseEntities, toResponseEntities, tapLogError } from 'src/app/core';
import { LatestErrors } from './latest-errors.model';
import { shareReplay, map } from 'rxjs/operators';

@Injectable()
export class LatestErrorsService {
  fsconnectorFertiwebErrorsApi = environment.api + '/fs-connector-fertiweb/api/errors/';
  constructor(private httpClient: HttpClient) {}

  getByHttpParams(
    params: HttpParams,
    endpoint: String
  ): Observable<ResponseEntities<LatestErrors>> {
    console.log(params);
    return this.httpClient
      .get<ResponseEntities<LatestErrors>>(this.fsconnectorFertiwebErrorsApi + endpoint, { params })
      .pipe(
        toResponseEntities(LatestErrors),
        shareReplay(1)
      );
  }

  getLatestsErrorsOfEachParcelByHttpParams(
    params: HttpParams
  ): Observable<ResponseEntities<LatestErrors>> {
    return this.getByHttpParams(params, 'latestsOfEachParcel');
  }

  getLatestErrorParcelByHttpParams(
    params: HttpParams,
    registeredParcelId: String
  ): Observable<ResponseEntities<LatestErrors>> {
    return this.getByHttpParams(params, registeredParcelId).pipe(
      map(a => new ResponseEntities<LatestErrors>(LatestErrors, a)),
      map(a => a.getAllEmbedded()),
      tapLogError('getLatestErrorParcelByHttpParams'),
      shareReplay(1)
    );
  }
}
