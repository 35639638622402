import { HttpParams } from '@angular/common/http';
import { LoadOptionsModel } from 'src/app/core/dx';

export class SpringSearchParams {
  projection: string;
  size: number;
  page: number;
  sort: string;

  static fromLoadOptions(loadOptions: LoadOptionsModel): SpringSearchParams {
    const searchParam = new SpringSearchParams();
    searchParam.page = loadOptions.skip / loadOptions.take;
    searchParam.size = loadOptions.take;

    if (loadOptions.sort && loadOptions.sort['0'] && loadOptions.sort['0'].selector) {
      const selector = loadOptions.sort['0'];
      searchParam.sort = selector.selector + ',' + (selector.desc ? 'desc' : 'asc');
    }
    return searchParam;
  }

  toHttpParams(httpParams?: HttpParams): HttpParams {
    if (!httpParams) {
      httpParams = new HttpParams();
    }
    return httpParams
      .set('size', String(this.size))
      .set('page', String(this.page))
      .set('sort', String(this.sort));
  }
}
