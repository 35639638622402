import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared';
import { ProductionTrackingComponent } from './production-tracking.component';
import { ProductionTrackingTotalNService } from './services/production-tracking-total-n.service';

@NgModule({
  declarations: [ProductionTrackingComponent],
  imports: [CommonModule, SharedModule],
  providers: [ProductionTrackingTotalNService],
})
export class ProductionTrackingModule {}
