import { LinkResource, Entity } from 'src/app/core';

export class StageEstimate extends LinkResource implements Entity {
  id: string;
  phenologicalStageRefId: string;
  stageDate: Date;
  estimateDate: Date;

  getRelativeApiUrl = () => '/fs-parcel/api/stageEstimates';

  constructor(data: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
      this.stageDate = new Date(data.stageDate);
      this.estimateDate = new Date(data.estimateDate);
    }
  }
}
