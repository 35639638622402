import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { GrasslandUsage } from './grassland-usage.model';

@Injectable({
  providedIn: 'root',
})
export class GrasslandUsageService {
  constructor(private httpClient: HttpClient) {}

  getGrasslandUsageById(id: string): Observable<GrasslandUsage> {
    const grasslandUsageIdUrl = environment.api + '/fs-agro-datum/api/grasslandUsages/' + id;
    return this.httpClient.get<Observable<GrasslandUsage>>(grasslandUsageIdUrl).pipe(
      map(a => new GrasslandUsage(a)),
      tapLogError('getGrasslandUsageById')
    );
  }

  getGrasslandUsageFromParcel(parcel$: Observable<Parcel>): Observable<GrasslandUsage> {
    return parcel$.pipe(
      map(a => a.agroData.activities.grasslandActivity.grasslandUsageRefId),
      flatMap(grasslandUsageRefId => this.getGrasslandUsageById(grasslandUsageRefId)),
      shareReplay(1)
    );
  }
}
