import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, mergeMap, toArray, shareReplay } from 'rxjs/operators';
import { ResponseEntities, tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { InputMode } from './input-mode.model';

@Injectable({
  providedIn: 'root',
})
export class InputModeService {
  constructor(private httpClient: HttpClient) {}

  getInputModeById(id: string): Observable<InputMode> {
    const inputModeIdUrl = environment.api + '/fs-agro-datum/api/inputModes/' + id;
    return this.httpClient.get<Observable<InputMode>>(inputModeIdUrl).pipe(
      map(a => new InputMode(a)),
      tapLogError('getInputModeById')
    );
  }

  getInputModeByIds(ids: string[]): Observable<InputMode[]> {
    const inputModeByIdsUrl = environment.api + '/fs-agro-datum/api/inputModes';
    return this.httpClient
      .get<ResponseEntities<InputMode>>(inputModeByIdsUrl, {
        params: new HttpParams({ fromObject: { id: ids } }),
      })
      .pipe(
        map(a => new ResponseEntities<InputMode>(InputMode, a)),
        map(a => a.getAllEmbedded()),
        tapLogError('getInputModeByIds'),
        shareReplay(1)
      );
  }

  // organicWasteInput
  getOrganicWastesInputFromParcel(parcel$: Observable<Parcel>): Observable<InputMode[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.organicWasteActivity.inputs),
      map(b => b.organicWasteInputModeRefId),
      toArray(),
      flatMap(ids => this.getInputModeByIds(ids)),
      shareReplay(1)
    );
  }

  getPreviousOrganicWastesInputFromParcel(parcel$: Observable<Parcel>): Observable<InputMode[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.previous.organicWasteInputs),
      map(b => b.organicWasteInputModeRefId),
      toArray(),
      flatMap(ids => this.getInputModeByIds(ids)),
      shareReplay(1)
    );
  }

  getAntepreviousOrganicWastesInputFromParcel(
    parcel$: Observable<Parcel>
  ): Observable<InputMode[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.anteprevious.organicWasteInputs),
      map(b => b.organicWasteInputModeRefId),
      toArray(),
      flatMap(ids => this.getInputModeByIds(ids)),
      shareReplay(1)
    );
  }

  getHistoricOrganicWastesInputFromParcel(parcel$: Observable<Parcel>): Observable<InputMode[]> {
    return parcel$.pipe(
      mergeMap(a => a.agroData.activities.historicActivities.inputs),
      map(b => b.organicWasteInputModeRefId),
      toArray(),
      flatMap(ids => this.getInputModeByIds(ids)),
      shareReplay(1)
    );
  }
}
