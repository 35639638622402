import { Injectable } from '@angular/core';

import OlMap from 'ol/Map';
import OlTileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style } from 'ol/style.js';
import GeoJSON from 'ol/format/GeoJSON';
import Overlay from 'ol/Overlay.js';
import { Image } from 'ol/layer';
import { OSM, Vector as VectorSource, ImageStatic } from 'ol/source';
import Control from 'ol/control/Control';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  readonly projection = 'EPSG:4326';

  createMap(targetElement: string): OlMap {
    return new OlMap({
      target: targetElement,
      layers: [
        new OlTileLayer({
          source: new OSM(),
        }),
      ],
    });
  }

  createGeoJsonLayer(geojson, featureStyle: Style, layerName?: string): VectorLayer {
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojson),
    });

    const layer = new VectorLayer({
      source: vectorSource,
      style: featureStyle,
      zIndex: 5,
    });

    if (layerName) {
      layer.set('name', layerName);
    }

    return layer;
  }

  createImageLayer(
    source: string,
    bbox: [number, number, number, number],
    layerName?: string
  ): Image {
    const layer = new Image({
      source: new ImageStatic({
        url: '',
        imageLoadFunction: image => (image.getImage().src = source),
        projection: this.projection,
        imageExtent: bbox,
      }),
    });
    if (layerName) {
      layer.set('name', layerName);
    }

    return layer;
  }

  addOverlay(map: OlMap, targetElement: string, center?: number[]) {
    const position = center ? center : map.getView().getCenter();
    map.addOverlay(
      new Overlay({
        position,
        positioning: 'center-center',
        element: document.getElementById(targetElement),
      })
    );
  }

  centerMapOnBbox(map: OlMap, bbox: [number, number, number, number]) {
    map.setView(new OlView(this.getViewConfig(bbox)));
    map.getView().fit(bbox, { size: map.getSize() });
  }

  getViewConfig(bbox: number[]): any {
    const center = [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2];
    return {
      projection: this.projection,
      center,
      zoom: 15,
    };
  }

  getFeatureStyle(strokeColor: string, filled: boolean): Style {
    const stroke = new Stroke({
      color: strokeColor,
      width: 1,
    });
    const fill = filled
      ? null
      : new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        });
    return new Style({ stroke, fill });
  }

  drawFeatures(map: OlMap, geojson: any, style: Style): VectorLayer {
    const featureLayer = this.createGeoJsonLayer(geojson, style);
    map.addLayer(featureLayer);
    this.centerMapOnBbox(map, geojson.bbox);

    return featureLayer;
  }

  hideLayersByName(map: OlMap, layerName: string) {
    map.getLayers().forEach(layer => {
      if (layer.get('name') === layerName) {
        layer.setVisible(false);
      }
    });
  }

  addControl(map: OlMap, element: string) {
    map.addControl(new Control({ element: document.getElementById(element) }));
  }
}
