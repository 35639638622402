import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap, shareReplay } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { Kind } from './kind.model';

@Injectable({
  providedIn: 'root',
})
export class KindService {
  constructor(private httpClient: HttpClient) {}

  getKindById(id: string): Observable<Kind> {
    const kindIdUrl = environment.api + '/fs-agro-datum/api/kinds/' + id;
    return this.httpClient.get<Observable<Kind>>(kindIdUrl).pipe(
      map(a => new Kind(a)),
      tapLogError('getKindById')
    );
  }

  getKindFromParcel(parcel$: Observable<Parcel>): Observable<Kind> {
    return parcel$.pipe(
      map(a => a.agroData.crop.kindRefId),
      flatMap(kindRefId => this.getKindById(kindRefId)),
      shareReplay(1)
    );
  }
}
