import { LinkResource, Entity } from 'src/app/core';

export class PreviousCrop extends LinkResource implements Entity {
  id: string;
  label: string;
  code: string;

  getRelativeApiUrl = () => '/fs-agro-datum/api/previousCrops';

  constructor(previousCropData?: any) {
    super(previousCropData);
    if (previousCropData) {
      this.id = previousCropData.id;
      this.label = previousCropData.label;
      this.code = previousCropData.code;
    }
  }
}
