import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpMappingService } from './http-mapping.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [HttpMappingService],
})
export class HttpMappingModule {}
