export class IngestionReport {
  beginDate: Date;
  endDate: Date;
  technician: IngestionStatistic;
  farmer: IngestionStatistic;
  parcel: IngestionStatistic;

  constructor(ingestionReportData?: any) {
    if (ingestionReportData) {
      this.beginDate = ingestionReportData.beginDate;
      this.endDate = ingestionReportData.endDate;
      this.technician = new IngestionStatistic(ingestionReportData.technician);
      this.farmer = new IngestionStatistic(ingestionReportData.farmer);
      this.parcel = new IngestionStatistic(ingestionReportData.parcel);
    }
  }
}

class IngestionStatistic {
  added: number;
  updated: number;
  deleted: number;

  constructor(ingestionStatisticData?: any) {
    if (ingestionStatisticData) {
      this.added = ingestionStatisticData.added;
      this.updated = ingestionStatisticData.updated;
      this.deleted = ingestionStatisticData.deleted;
    }
  }
}
