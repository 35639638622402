import { OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function tapLogError(msg): OperatorFunction<any, any> {
  return catchError(error => {
    console.error(`tapLogError: ${msg}: ${error.message}`);
    console.error(error);
    return throwError(error);
  });
}
