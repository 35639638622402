export class TestReport {
  id: string;
  beginDate: Date;
  endDate: Date;
  errors: Errors[] = [];
  technician: TestStatistic;
  farmer: TestStatistic;
  parcel: TestStatistic;

  constructor(testReportData?: any) {
    if (testReportData) {
      this.id = testReportData.uuid;
      this.beginDate = testReportData.beginDate;
      this.endDate = testReportData.endDate;
      this.technician = new TestStatistic(testReportData.technician);
      this.farmer = new TestStatistic(testReportData.farmer);
      this.parcel = new TestStatistic(testReportData.parcel);
      if (testReportData.errors) {
        this.errors = testReportData.errors.map(error => new Errors(error));
      }
    }
  }
}

export class Errors {
  field: string;
  message: string;
  entityCode: string;
  level: string;
  entityType: string;

  constructor(errorsData?: any) {
    if (errorsData) {
      this.field = errorsData.field;
      this.message = errorsData.message;
      this.entityCode = errorsData.entityCode;
      this.level = errorsData.level;
      this.entityType = errorsData.entityType;
    }
  }
}

class TestStatistic {
  rejected: number;
  total: number;

  constructor(testStatisticData?: any) {
    if (testStatisticData) {
      this.rejected = testStatisticData.rejected;
      this.total = testStatisticData.total;
    }
  }
}
