import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { tapLogError } from 'src/app/core';
import { Parcel } from 'src/app/shared';
import { CatchCrop } from './catch-crop.model';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CatchCropService {
  constructor(private httpClient: HttpClient) {}

  getCatchCropById(id: string): Observable<CatchCrop> {
    const catchCropIdUrl = environment.api + '/fs-agro-datum/api/catchCrops/' + id;
    return this.httpClient.get<Observable<CatchCrop>>(catchCropIdUrl).pipe(
      map(a => new CatchCrop(a)),
      tapLogError('getCatchCropById')
    );
  }

  getCatchCropFromParcel(parcel$: Observable<Parcel>): Observable<CatchCrop> {
    return parcel$.pipe(
      map(a => a.agroData.activities.catchCropActivity.catchCropRefId),
      flatMap(catchCropRefId => this.getCatchCropById(catchCropRefId)),
      shareReplay(1)
    );
  }
}
