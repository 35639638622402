import { Observable, Subject, zip } from 'rxjs';
import { map, mergeMap, shareReplay, toArray } from 'rxjs/operators';

export class Datasource<T> {
  private readonly TType: { new (data?: any): T };
  private originalDefinition: { observable: Observable<any[]>; groupName: string };
  private joinDefinitions: JoinDefinition<T>[] = [];

  constructor(TType: { new (data?: any): T }) {
    this.TType = TType;
  }

  public setOriginalData(observable: Observable<any[]>, groupName: string): Datasource<T> {
    this.originalDefinition = { observable, groupName };
    return this;
  }

  public join(
    observable: Observable<any>,
    groupName: string,
    primaryIdentifier: (datasourceRow: T) => string | number,
    foreignIdentifier: (joinDataRow: any) => string | number
  ): Datasource<T> {
    this.joinDefinitions.push({ observable, groupName, primaryIdentifier, foreignIdentifier });
    return this;
  }

  public build(): Observable<T[]> {
    const datasourceOutput: Subject<T[]> = new Subject<T[]>();
    const allJoinObservables: Observable<any[]>[] = this.joinDefinitions.map(
      joinDefinition => joinDefinition.observable
    );
    zip(...allJoinObservables).subscribe(multipleJoinValues => {
      this.originalDefinition.observable
        .pipe(
          mergeMap(originalDataRow => originalDataRow),
          map(originalDataRow => this.newDatasourceRow(originalDataRow)),
          map(datasourceRow =>
            this.addJoinValuesToDatasourceRow(datasourceRow, multipleJoinValues)
          ),
          toArray(),
          shareReplay(1)
        )
        .subscribe(datasource => datasourceOutput.next(datasource));
    });
    return datasourceOutput;
  }

  private newDatasourceRow(originalData) {
    const datasourceRow = new this.TType();
    datasourceRow[this.originalDefinition.groupName] = originalData;
    return datasourceRow;
  }

  private addJoinValuesToDatasourceRow(datasourceRow: T, multipleJoinValues: any[]): T {
    for (let key = 0; key < multipleJoinValues.length; key++) {
      const joinDefinition = this.joinDefinitions[key];
      const joinValues = multipleJoinValues[key];
      this.addJoinValueToDatasourceRow(datasourceRow, joinDefinition, joinValues);
    }
    return datasourceRow;
  }

  private addJoinValueToDatasourceRow(
    datasourceRow: T,
    joinDefinition: JoinDefinition<T>,
    joinValues: any[]
  ) {
    const primaryId = joinDefinition.primaryIdentifier(datasourceRow);
    datasourceRow[joinDefinition.groupName] = {};
    for (const value of joinValues) {
      if (primaryId === joinDefinition.foreignIdentifier(value)) {
        datasourceRow[joinDefinition.groupName] = value;
      }
    }
  }
}

class JoinDefinition<T> {
  observable: Observable<any[]>;
  groupName: string;
  primaryIdentifier: (datasource: T) => string | number;
  foreignIdentifier: (joinData: any) => string | number;
}
