import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { TestReport, TestReportService, CustomerDataFileService } from 'src/app/shared';

@Component({
  selector: 'app-test-report',
  templateUrl: './test-report.component.html',
  styleUrls: ['./test-report.component.scss'],
})
export class TestReportComponent implements OnInit {
  icons = faIcons;

  // Observables
  testReport$: Observable<TestReport>;

  // Data
  dataFileId: string;
  totalCount: number;
  testReportId: string;

  constructor(
    private testReportService: TestReportService,
    private customerDataFileService: CustomerDataFileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dataFileId = params.id;
      this.loadTestReportData().subscribe(reportData => {
        this.testReportId = reportData.id;
      });
    });
  }

  loadTestReportData(): Observable<TestReport> {
    this.testReport$ = this.testReportService.getTestReport(this.dataFileId, 'testReportInfo');
    return this.testReport$;
  }

  downloadCsv() {
    this.customerDataFileService.downloadDataFileCsv(this.dataFileId);
  }
}
