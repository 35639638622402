import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared';
import { ArvalisComponent } from './arvalis.component';
import { ArvalisImportsComponent } from './arvalis-imports/arvalis-imports.component';
import { ArvalisExportComponent } from './arvalis-export/arvalis-export.component';
import { ArvalisExportTotalNdoseModalComponent } from './arvalis-export-total-ndose-modal/arvalis-export-total-ndose-modal.component';

@NgModule({
  declarations: [
    ArvalisComponent,
    ArvalisImportsComponent,
    ArvalisExportComponent,
    ArvalisExportTotalNdoseModalComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [ArvalisComponent],
})
export class ArvalisModule {}
