import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { Entity, ResponseEntities, SpringSearchParams } from 'src/app/core/hal';
import { LoadOptionsModel } from './load.options.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class DxDatasourceCreatorService {
  fromData<T>(data: {
    load: ({ httpParams: HttpParams }) => Observable<ResponseEntities<T>>;
    getLoadParams?: () => any;
    projection?: string;
    insert?: (values: {}) => Promise<any>;
    update?: (data: Entity, newValues: {}) => Promise<any>;
    delete?: (data: Entity) => Promise<any>;
  }) {
    return new DataSource({
      load: this.buildLoadFunction(data),
      insert: data.insert,
      update: data.update,
      remove: data.delete,
    });
  }

  asStore<T>(data: {
    load: ({ httpParams: HttpParams }) => Observable<ResponseEntities<T>>;
    getLoadParams?: () => any;
    projection?: string;
    key?: string;
  }) {
    return new DataSource({
      store: new CustomStore({
        key: data.key || 'id',
        load: this.buildLoadFunction(data),
      }),
    });
  }

  private buildLoadFunction<T>(data: {
    load: ({ httpParams: HttpParams }) => Observable<ResponseEntities<T>>;
    getLoadParams?: () => any;
    projection?: string;
  }) {
    return (loadOptions: LoadOptionsModel) => {
      const args = data.getLoadParams ? data.getLoadParams() : {};
      const httpParams = args.httpParams || new HttpParams();
      args.httpParams = SpringSearchParams.fromLoadOptions(loadOptions).toHttpParams(httpParams);
      if (data.projection) {
        args.httpParams = args.httpParams.set('projection', data.projection);
      }

      return data
        .load(args)
        .toPromise()
        .then(resp => ({
          data: resp.getAllEmbedded(),
          totalCount: resp.page.totalElements,
        }));
    };
  }
}
