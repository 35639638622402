import { NgModule } from '@angular/core';
import { CooperativesListComponent } from './cooperatives-list.component';
import { SharedModule } from 'src/app/shared';

@NgModule({
  declarations: [CooperativesListComponent],
  imports: [SharedModule],
  exports: [CooperativesListComponent],
  providers: [],
})
export class CooperativesListModule {}
