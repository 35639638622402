import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as faIcons from '@fortawesome/free-solid-svg-icons';

const version = require('./version.json').version;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  public version;
  icons = faIcons;

  constructor(public dialogRef: MatDialogRef<AboutComponent>) {
    this.version = version;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
