import { NgModule } from '@angular/core';
import { IngestionComponent } from './ingestion.component';
import { SharedModule } from 'src/app/shared';
import { UploadDatafileComponent } from './upload-datafile/upload-datafile.component';

@NgModule({
  declarations: [IngestionComponent, UploadDatafileComponent],
  imports: [SharedModule],
  exports: [IngestionComponent],
  providers: [],
})
export class IngestionModule {}
