import { Entity, LinkResource } from 'src/app/core/hal';

export class Technician extends LinkResource implements Entity {
  adress: string;
  city: string;
  email: string;
  firstName: string;
  id: string;
  idpIdentifier: string;
  lastModifiedDate: Date;
  lastName: string;
  login: string;
  phone1: string;
  phone2: string;
  profile: string;
  zipCode: string;
  active: boolean;
  code: string;
  codeInCoop: string;

  getRelativeApiUrl = () => '/fs-core/api/technicians';

  constructor(technicianDetailsData: any) {
    super(technicianDetailsData);
    if (technicianDetailsData) {
      this.adress = technicianDetailsData.adress;
      this.city = technicianDetailsData.city;
      this.email = technicianDetailsData.email;
      this.firstName = technicianDetailsData.firstName;
      this.id = technicianDetailsData.id;
      this.idpIdentifier = technicianDetailsData.idpIdentifier;
      this.lastModifiedDate = technicianDetailsData.lastModifiedDate;
      this.lastName = technicianDetailsData.lastName;
      this.login = technicianDetailsData.login;
      this.phone1 = technicianDetailsData.phone1;
      this.phone2 = technicianDetailsData.phone2;
      this.profile = technicianDetailsData.profile;
      this.zipCode = technicianDetailsData.zipCode;
      this.active = technicianDetailsData.active;
      this.code = technicianDetailsData.code;
      this.codeInCoop = technicianDetailsData.codeInCoop;
    }
  }

  getFullName() {
    return [this.firstName, this.lastName].join(' ').trim();
  }
}
