import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IngestionReportService, IngestionReport } from 'src/app/shared';
import { Observable } from 'rxjs';
import * as faIcons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ingestion-report',
  templateUrl: './ingestion-report.component.html',
  styleUrls: ['./ingestion-report.component.scss'],
})
export class IngestionReportComponent implements OnInit {
  icons = faIcons;
  ingestionReport$: Observable<IngestionReport>;
  dataFileId: string;
  noIngestMessage = "Ce fichier n'a pas encore été ingéré";
  noModification = 'Aucune modification';

  constructor(
    private ingestionReportsService: IngestionReportService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dataFileId = params.id;
      this.loadIngestionReportData();
    });
  }

  loadIngestionReportData() {
    this.ingestionReport$ = this.ingestionReportsService.getIngestionReport(this.dataFileId);
  }
}
