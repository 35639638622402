import { Component, OnChanges, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MapService } from 'src/app/shared/parcel/map/map.service';
import { BiophyValueService } from 'src/app/shared/biophy-value/biophy-value.service';
import { DxChartComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { TimeService } from 'src/app/shared/time/time.service';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';
import { BiophyLayer } from 'src/app/shared/biophy-value/biophy-layer.enum';
import * as faIcons from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-time-tracking-modal',
  templateUrl: './time-tracking-modal.component.html',
  styleUrls: ['./time-tracking-modal.component.scss'],
})
export class TimeTrackingModalComponent implements OnChanges {
  @ViewChild(DxChartComponent) lineChart: DxChartComponent;

  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Input() parcelId: string;
  @Input() geojson: any;

  icons = faIcons;
  map;
  laiCurve: any[];

  selectedAcquisition = {
    point: null,
    layer: null,
  };

  biophyToggle = BiophyLayer.LAI;
  includeBlacklisted = false;

  // Created layers (by type and acquisitionID)
  layers = {};

  constructor(
    private mapService: MapService,
    private biophyValueService: BiophyValueService,
    private timeService: TimeService,
    private fileDownloaderService: FileDownloaderService
  ) {
    this.refresh = this.refresh.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.curvePointTooltip = this.curvePointTooltip.bind(this);
    this.loadAcquisition = this.loadAcquisition.bind(this);
    this.reloadCurve = this.reloadCurve.bind(this);

    this.layers[BiophyLayer.LAI] = {};
    this.layers[BiophyLayer.TRUECOLOR] = {};
  }

  ngOnChanges(changes) {
    if (!this.map) {
      this.map = this.mapService.createMap('lai-map');
      this.mapService.addControl(this.map, 'biophyToggle');
    }

    if (changes.geojson && this.geojson) {
      this.mapService.drawFeatures(
        this.map,
        this.geojson,
        this.mapService.getFeatureStyle('#9820DEFF', false)
      );
    }

    if (changes.parcelId && this.parcelId) {
      this.loadLaiCurve();
    }
  }

  loadLaiCurve() {
    this.biophyValueService
      .getLaiCurve(this.parcelId, this.includeBlacklisted)
      .subscribe(points => {
        this.laiCurve = points.map(p => {
          const curvePoint: any = p;
          if (p.blacklisted) {
            curvePoint.blacklistedMean = p.stats.mean;
          } else {
            curvePoint.mean = p.stats.mean;
          }
          return curvePoint;
        });
        if (_.isEmpty(this.laiCurve)) {
          if (this.selectedAcquisition.layer) {
            this.selectedAcquisition.layer.setVisible(false);
          }
          this.selectedAcquisition = { point: null, layer: null };
        } else {
          if (
            !this.selectedAcquisition.point ||
            (!this.includeBlacklisted && this.selectedAcquisition.point.blacklisted)
          ) {
            this.loadAcquisition(_.findLast(this.laiCurve, curvePoint => !curvePoint.blacklisted));
          }
        }
      });
  }

  curvePointTooltip(pointInfo) {
    const formatField = (fieldName, value) => `${fieldName} : ${_.isNil(value) ? '' : value}`;
    const pointData = pointInfo.point.data;
    const tooltipContent = [];
    tooltipContent.push(formatField('Source ID', pointData.acquisitionName));
    tooltipContent.push(formatField('Capteur', pointData.sensor));
    tooltipContent.push(
      formatField('Date', this.timeService.utcToTimeZone(pointData.x, 'DD/MM/YYYY'))
    );
    tooltipContent.push(formatField('Moyenne', pointData.stats.mean));
    tooltipContent.push(formatField('Médiane', pointData.stats.median));
    tooltipContent.push(formatField('Variance', pointData.stats.variance));
    tooltipContent.push(formatField('Validité', pointData.stats.validity));

    return { html: tooltipContent.join('<br/>') };
  }

  // Triggered on point click
  loadAcquisition(point) {
    this.selectedAcquisition.point = point;
    this.loadSelectedImage(this.biophyToggle);
  }

  // Triggered on point click or toggle change
  loadSelectedImage(type: BiophyLayer) {
    this.biophyToggle = type;

    // Hide the current layer before loading another one
    if (this.selectedAcquisition.layer) {
      this.selectedAcquisition.layer.setVisible(false);
    }

    if (!this.selectedAcquisition.point) {
      this.selectedAcquisition.layer = null;
      return;
    }

    const acquisitionID = this.selectedAcquisition.point.acquisitionName;

    // Load or create layer for the selected acquisitionID
    let acquisitionLayer = this.layers[type][acquisitionID];
    if (acquisitionLayer) {
      acquisitionLayer.setVisible(true);
      this.selectedAcquisition.layer = acquisitionLayer;
    } else {
      this.biophyValueService
        .getLayerByAcquisitionID(this.parcelId, type, acquisitionID)
        .subscribe(geopng => {
          if (_.has(geopng, '_links.png.href')) {
            this.fileDownloaderService.getFileAsUrl(geopng._links.png.href).subscribe(fileUrl => {
              acquisitionLayer = this.mapService.createImageLayer(fileUrl, geopng.bbox);
              this.selectedAcquisition.layer = acquisitionLayer;
              this.layers[type][acquisitionID] = acquisitionLayer;
              this.map.addLayer(acquisitionLayer);
            });
          }
        });
    }
  }

  reloadCurve(event) {
    this.includeBlacklisted = event.value;
    this.loadLaiCurve();
  }

  updateBlacklist() {
    const blacklisted = !this.selectedAcquisition.point.blacklisted;
    this.biophyValueService
      .patchBiophyValue(this.selectedAcquisition.point.biophyValueId, { blacklisted })
      .subscribe(() => {
        this.selectedAcquisition.point.blacklisted = blacklisted;
        this.loadLaiCurve();
      });
  }

  refresh() {
    this.lineChart.instance.render({ force: true });
    this.map.updateSize();
    this.mapService.centerMapOnBbox(this.map, this.geojson.bbox);
  }

  closeModal() {
    this.isOpenChange.emit(false);
  }
}
