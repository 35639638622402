import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  utcToTimeZone(date, format = 'DD/MM/YYYY à HH:mm:ss') {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = moment.utc(date);
    if (!utcDate.isValid()) {
      return;
    }
    return utcDate.tz(timezone).format(format);
  }

  utcToString(date, format = 'YYYY-MM-DD'): string {
    return moment.utc(date).format(format);
  }
}
