import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-load-indicator',
  template: `
    <dx-load-indicator [visible]="visible" [height]="size" [width]="size"></dx-load-indicator>
  `,
})
export class LoadIndicatorComponent implements OnChanges {
  @Input() observable: Observable<any>;
  @Input() size = 20;

  // Makes EventEmitter async to avoid ExpressionChangedAfterItHasBeenCheckedError
  @Output() pending = new EventEmitter<boolean>(true);

  visible = false;

  constructor() {}

  ngOnChanges() {
    if (this.observable) {
      this.visible = true;
      this.pending.emit(true);
      this.observable
        .pipe(
          finalize(() => {
            this.visible = false;
            this.pending.emit(false);
          })
        )
        .subscribe();
    }
  }
}
