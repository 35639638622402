import { Entity, HttpMappingGetter } from 'src/app/core/hal';
import { HttpClient, HttpParams } from '@angular/common/http';

export abstract class HttpMappingParamAbstract<T extends Entity> {
  // default 'id' : path to the id inside origin, used to search and map
  pathToIdInInput?: string;
  // where to store data from getter inside origin
  pathToDataInOutput?: string;
  // to get data to map inside origin, take httpParams and do an HTTP GET.
  // The operator will add all ids to fetch in the httpParams.
  // You can use : EntityProviderService to generate this getter :
  //   httpGetter: this.entityProviderService.httpGetter(Crop, '/fs-agro-datum/api/crops')
  httpGetter?: HttpMappingGetter<T>;
  // default 'id' : key to search in the getter, param name append to the httpParams :
  // GET  .../api/parcels?keyToSearchInGetter=45&keyToSearchInGetter=12&...
  keyToSearchInGetter?: string;
  // default "new HttpParams()"
  httpParams?: HttpParams;
  // projection to use with getter
  projection?: string;
  // activate the reverse mapping, even if empty "{}"
  reverseMapping?: {
    // default false : store all founded mapping inside an array. Useful if there are several results to fetch.
    useArrayInOutput?: boolean;
    // default 'id' :
    pathToId?: string;
    // default disabled : search data to map in an array in the data from the getter
    arrayOfEntities?: {
      // path to the array of sub elements in the data from the getter
      pathTo?: string;
      // path to the id inside the array of sub elements
      pathToIdInside?: string;
    };
  };
  // path to the url of data to map inside the element
  linkToResource?: string;
  // HttpClient to use to get data
  httpClient?: HttpClient;
  // TargetType of data
  targetType?: { new (data?: any): T };
  // relative API URL to the data (from Environment.api)
  relativeApiUrl?: string;
  // default 3800 : max url length, split the generated url if the length of the params part of url is greater than this value
  maxUrlLength?: number;
  // default false : the endpoint can't handle several ids, just one, then make several GETs to fetch data...
  onGetByElement?: boolean;
}

export class HttpMappingParam<T extends Entity> extends HttpMappingParamAbstract<T> {
  constructor(params: HttpMappingParamAbstract<T>) {
    super();
    Object.assign(this, params);
  }

  // get path to id, 'id' by default
  getPathToId(): string {
    return (this.reverseMapping ? this.reverseMapping.pathToId : this.pathToIdInInput) || 'id';
  }

  getKeyToSearch(): string {
    return this.keyToSearchInGetter || 'id';
  }

  getPathToDataInOutput(): string {
    if (this.linkToResource) {
      return this.pathToDataInOutput || this.linkToResource + '._data';
    } else {
      return this.pathToDataInOutput || this.pathToIdInInput + '_data';
    }
  }

  getRelativeApiUrl() {
    return this.targetType ? new this.targetType().getRelativeApiUrl() : this.relativeApiUrl;
  }

  getHttpParams(): HttpParams {
    let httpParamsResult: HttpParams = this.httpParams || new HttpParams();

    if (this.projection) {
      httpParamsResult = httpParamsResult.set('projection', this.projection);
    }
    return httpParamsResult;
  }
}
