import { Component, OnInit, Input } from '@angular/core';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import {
  Technician,
  CooperativeService,
  Cooperative,
  RegisteredTechnicianService,
  TechnicianService,
} from 'src/app/shared';

@Component({
  selector: 'app-technician-details',
  templateUrl: './technician-details.component.html',
  styleUrls: ['./technician-details.component.scss'],
})
export class TechnicianDetailsComponent implements OnInit {
  parcel = 'Parcelles des campagnes actives';
  icons = faIcons;
  technicianId: string;
  technician$: Observable<Technician>;
  cooperative$: Observable<Cooperative>;
  logoUrl: any;
  @Input() parcelIds: string[];

  constructor(
    private registeredTechnicianService: RegisteredTechnicianService,
    private cooperativeService: CooperativeService,
    private technicianService: TechnicianService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.technicianId = params.id;
      this.loadTechnicianDetails();
    });
  }

  loadTechnicianDetails() {
    const registeredTechnician$ = this.registeredTechnicianService.getRegisteredTechnicianByTechnicianId(
      this.technicianId
    );

    registeredTechnician$.subscribe(r => {
      this.parcelIds = _.flatMap(r.registeredFarmers, registeredFarmer =>
        _.flatMap(registeredFarmer.parcels, t => t.refId)
      );
    });
    this.cooperative$ = this.cooperativeService.getCooperativeFromRegisteredTechnician(
      registeredTechnician$
    );
    this.technician$ = this.technicianService.getTechnicianById(this.technicianId, 'card');
    this.cooperative$.subscribe(coop => this.loadUrlLogo(coop.id));
  }

  loadUrlLogo(coopId) {
    this.cooperativeService.getLargeLogo(coopId).subscribe(data => this.getImageFromBlob(data));
  }

  getImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.logoUrl = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
