import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseEntities } from 'src/app/core';
import { RegisterParcel } from './register-parcel.model';

@Injectable({
  providedIn: 'root',
})
export class RegisterParcelService {
  constructor(private httpClient: HttpClient) {}

  getRegisterParcel(id: string) {
    const registerParcelUrl = environment.api + '/fs-totaln/api/registerParcel/' + id;
    return this.httpClient.post<ResponseEntities<RegisterParcel>>(registerParcelUrl, id);
  }

  getRegisterParcels(ids: string[]) {
    const registerParcelsUrl = environment.api + '/fs-totaln/api/registerParcels';
    return this.httpClient.post<ResponseEntities<RegisterParcel>>(registerParcelsUrl, ids);
  }
}
