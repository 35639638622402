import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './top-bar/top-bar.component';
import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
  MatDialogModule,
  MatTabsModule,
  MatIconModule,
} from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { SideBarModule } from './side-bar/side-bar.module';
import { FormsModule } from '@angular/forms';
import { AboutComponent } from './about/about.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AboutChangelogComponent } from './about/about-changelog/about-changelog.component';
import { AboutBackendVersionsComponent } from './about/about-backend-versions/about-backend-versions.component';
import { DxDataGridModule } from 'devextreme-angular';

@NgModule({
  declarations: [
    TopBarComponent,
    AboutComponent,
    AboutChangelogComponent,
    AboutBackendVersionsComponent,
  ],
  exports: [TopBarComponent, AboutComponent, SideBarModule],
  entryComponents: [AboutComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    MatCardModule,
    MatButtonModule,
    RouterModule,
    MatTooltipModule,
    SideBarModule,
    FormsModule,
    MatDialogModule,
    FlexLayoutModule,
    MatTabsModule,
    MatIconModule,
    DxDataGridModule,
  ],
})
export class LayoutModule {}
