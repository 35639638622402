import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpMappingParam, HttpMappingParamAbstract } from './http-mapping-param.model';
import { Entity, ResponseEntities } from 'src/app/core/hal';
import { httpMapping, httpMappingEntity } from './http-mapping.opearator';
import { OperatorFunction } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpMappingService {
  constructor(private httpClient: HttpClient) {}

  getParam<E extends Entity>(...paramArray: HttpMappingParamAbstract<E>[]): HttpMappingParam<E>[] {
    return paramArray.map(param => {
      const httpMappingParam = new HttpMappingParam<E>(param);
      httpMappingParam.httpClient = this.httpClient;
      return httpMappingParam;
    });
  }

  mapEntities<E extends Entity, F extends Entity>(
    ...params: HttpMappingParamAbstract<F>[]
  ): OperatorFunction<ResponseEntities<E>, ResponseEntities<E>> {
    return httpMapping.apply(this, this.getParam.apply(this, params));
  }

  mapEntity<E extends Entity, F extends Entity>(
    ...paramArray: HttpMappingParamAbstract<F>[]
  ): OperatorFunction<E, E> {
    return httpMappingEntity.apply(this, this.getParam.apply(this, paramArray));
  }

  mapEntitiesLink<E extends Entity, F extends Entity>(
    linkToResource: string,
    projection?: string
  ): OperatorFunction<ResponseEntities<E>, ResponseEntities<E>> {
    return httpMapping.apply(
      this,
      this.getParam<F>({
        linkToResource,
        httpParams: projection ? new HttpParams().set('projection', projection) : null,
      })
    );
  }

  mapEntitiesLinks<E extends Entity>(
    linksToResources: string[],
    projection?: string
  ): OperatorFunction<ResponseEntities<E>, ResponseEntities<E>> {
    const httpParams = projection ? new HttpParams().set('projection', projection) : null;
    const httpMappingParams = linksToResources.map(
      linkToResource => this.getParam({ linkToResource, httpParams })[0]
    );
    return httpMapping.apply(this, httpMappingParams);
  }

  mapEntityLink<E extends Entity, F extends Entity>(
    linkToResource: string,
    projection?
  ): OperatorFunction<E, E> {
    return httpMappingEntity.apply(
      this,
      this.getParam<F>({
        linkToResource,
        httpParams: projection ? new HttpParams().set('projection', projection) : null,
      })
    );
  }

  mapEntityLinks<E extends Entity>(
    linksToResources: string[],
    projection?: string
  ): OperatorFunction<E, E> {
    const httpParams = projection ? new HttpParams().set('projection', projection) : null;
    const httpMappingParams = linksToResources.map(
      linkToResource => this.getParam({ linkToResource, httpParams })[0]
    );
    return httpMappingEntity.apply(this, httpMappingParams);
  }
}
