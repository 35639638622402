import { LinkResource, Entity } from 'src/app/core/hal';

export class RegisteredCrop extends LinkResource implements Entity {
  constructor(registeredCropData?: any) {
    super(registeredCropData);
    if (registeredCropData) {
      Object.assign(this, registeredCropData);
    }
  }

  static relativeApiUrl = '/fs-core/api/registeredCrops';
  id: string;
  cropRefId: string;
  servicePackIds?: string[];
  getRelativeApiUrl = () => RegisteredCrop.relativeApiUrl;
}
