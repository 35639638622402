import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-parcel-card',
  templateUrl: './parcel-card.component.html',
  styleUrls: ['./parcel-card.component.scss'],
})
export class ParcelCardComponent implements OnInit {
  parcelId: string;
  biophyEnabled = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.parcelId = params.id;
    });
  }
}
