import { LinkResource, Entity } from 'src/app/core';

export class Kind extends LinkResource implements Entity {
  id: string;
  label: string;
  code: string;

  getRelativeApiUrl = () => '/fs-agro-datum/api/kinds';

  constructor(kindData?: any) {
    super(kindData);
    if (kindData) {
      this.id = kindData.id;
      this.label = kindData.label;
      this.code = kindData.code;
    }
  }
}
