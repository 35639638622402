import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ParcelService, BaseUnitConverterPipe, Kind } from 'src/app/shared';
import { HttpMappingParam, EntityProviderService, httpMapping } from 'src/app/core';
import { TotalNdoseFilter } from 'src/app/feature/statistics/total-ndose/total-ndose-filter';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ParcelPerformanceTarget } from './parcel-performance-target';

@Component({
  selector: 'app-total-ndose-performance-target',
  templateUrl: './total-ndose-performance-target.component.html',
  styleUrls: ['./total-ndose-performance-target.component.scss'],
})
export class TotalNdosePerformanceTargetComponent implements OnInit {
  parcels$: Observable<ParcelPerformanceTarget[]>;

  kindMapping = new HttpMappingParam<Kind>({
    pathToIdInInput: 'agroData.crop.kindRefId',
    pathToDataInOutput: 'agroData.crop.kindData',
    httpGetter: this.entityProviderService.httpGetter(Kind, '/fs-agro-datum/api/kinds'),
    httpParams: new HttpParams().set('projection', 'code'),
  });

  constructor(
    private parcelService: ParcelService,
    private baseUnitConverter: BaseUnitConverterPipe,
    private entityProviderService: EntityProviderService
  ) {}

  ngOnInit() {
    this.loadParcels();
  }

  loadParcels(filter?: TotalNdoseFilter) {
    const params = filter ? filter.toHttpParams() : new HttpParams();
    this.parcels$ = this.parcelService
      .getNDoseParcelStatistics({ httpParams: params }, 'performance-target')
      .pipe(
        httpMapping(this.kindMapping),
        map(respEntities => respEntities.getAllEmbedded()),
        map(parcels =>
          parcels.map(parcel => {
            const perfTarget = new ParcelPerformanceTarget(parcel);
            perfTarget.kind = parcel.agroData.crop.kindData.code;
            perfTarget.projectedDose = this.convertValue(perfTarget.projectedDose, 'kg/hectare');
            perfTarget.medianYieldTarget = this.convertValue(
              perfTarget.medianYieldTarget,
              'tonne/hectare'
            );
            return perfTarget;
          })
        )
      );
  }

  convertValue(value: number, unit: string): number {
    return Number(this.baseUnitConverter.transform(value, unit, ''));
  }

  customizePoint(point) {
    // To fix later
    return { color: point.data.kind === 'MAISF' ? '#ABBF3A' : '#FAB800' };
  }
}
