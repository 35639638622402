import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';
import {
  Cooperative,
  Crop,
  Farmer,
  Parcel,
  ParcelService,
  RegisteredFarmer,
  Technician,
} from 'src/app/shared';
import { mapErrorToMsg, ResponseEntities } from 'src/app/core';
import { DxDatasourceCreatorService } from 'src/app/core/dx';
import { httpMapping, HttpMappingParam } from 'src/app/core/rxjs-operators/http-mapping';
import { EntityProviderService } from 'src/app/core/hal/entity-provider.service';

@Component({
  selector: 'app-parcels-list',
  templateUrl: './parcels-list.component.html',
  styleUrls: ['./parcels-list.component.scss'],
})
export class ParcelsListComponent implements OnInit {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent;

  constructor(
    private parcelService: ParcelService,
    private datasourceCreator: DxDatasourceCreatorService,
    private entityProviderService: EntityProviderService
  ) {}

  dataSource: DataSource;
  coopFilter: string;
  totalNbParcels: number;

  parcelCropMapping = new HttpMappingParam<Crop>({
    pathToIdInInput: 'agroData.crop.cropRefId',
    pathToDataInOutput: 'agroData.crop',
    // return [ {id: 'cropId', code: 'WHEAT'}, ...]
    httpGetter: this.entityProviderService.httpGetter(Crop, '/fs-agro-datum/api/crops'),
    httpParams: new HttpParams().set('projection', 'parcelList'),
  });

  registeredFarmerMapping = new HttpMappingParam<RegisteredFarmer>({
    keyToSearchInGetter: 'parcels.parcelRefId',
    pathToDataInOutput: 'registeredFarmer',
    // return [ {id: 'registeredFarmerId', farmerRefId: 'farmerId', parcels: [{id: 'registeredParcelId', refId: 'parcelId'}, ...]}, ...]
    httpGetter: this.entityProviderService.httpGetter(
      RegisteredFarmer,
      '/fs-core/api/registeredFarmers'
    ),
    httpParams: new HttpParams()
      .set('projection', 'parcelList')
      .set('campaign.active', 'true')
      .set('size', '1000'),
    reverseMapping: { arrayOfEntities: { pathTo: 'parcels', pathToIdInside: 'refId' } },
  });

  farmerMapping = new HttpMappingParam<Farmer>({
    pathToDataInOutput: 'farmer',
    pathToIdInInput: 'registeredFarmer.farmerRefId',
    // return [ {id: 'farmerId', lastName: 'farmerLastName', ...}, ...]
    httpGetter: this.entityProviderService.httpGetter(Farmer, '/fs-core/api/farmers'),
    httpParams: new HttpParams().set('projection', 'parcelList'),
  });

  technicianMapping = new HttpMappingParam<Technician>({
    pathToDataInOutput: 'technician',
    pathToIdInInput: 'registeredFarmer.registeredTechnician.technicianRefId',
    httpGetter: this.entityProviderService.httpGetter(Technician, '/fs-core/api/technicians'),
    httpParams: new HttpParams().set('projection', 'parcelList'),
  });

  registeredCooperativeMapping = new HttpMappingParam<Cooperative>({
    pathToDataInOutput: 'cooperative',
    pathToIdInInput: 'registeredFarmer.registeredCooperative.cooperativeRefId',
    httpGetter: this.entityProviderService.httpGetter(Cooperative, '/fs-core/api/cooperatives'),
    httpParams: new HttpParams().set('projection', 'parcelList'),
  });

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = this.datasourceCreator.fromData({
      load: searchParams =>
        this.loadParcelList(searchParams).pipe(
          tap(response => (this.totalNbParcels = response.page.totalElements)),
          httpMapping(this.registeredFarmerMapping, this.parcelCropMapping),
          httpMapping(
            this.farmerMapping,
            this.registeredCooperativeMapping,
            this.technicianMapping
          ),
          mapErrorToMsg('Il y a eu une erreur pendant la récupération des données !')
        ),
      getLoadParams: () => ({ httpParams: new HttpParams().set('projection', 'parcelList') }),
    });
  }

  loadParcelList(searchParams): Observable<ResponseEntities<Parcel>> {
    return this.parcelService.getFilteredParcels(searchParams, this.coopFilter);
  }

  getFarmerFullName(tableRow): string {
    return tableRow.farmer ? tableRow.farmer.getFullName() : '';
  }

  getTechnicianFullName(tableRow): string {
    return tableRow.technician ? tableRow.technician.getFullName() : '';
  }

  applyFilter(filter: Cooperative) {
    this.coopFilter = filter ? filter.id : null;
    this.datagrid.instance.refresh();
  }
}
