import { Component } from '@angular/core';
import DataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor() {
    this.configureDxComponents();
  }

  configureDxComponents() {
    DataGrid.defaultOptions({
      device: { deviceType: 'desktop' },
      options: {
        allowColumnResizing: true,
        columnAutoWidth: true,
        rowAlternationEnabled: true,
        showBorders: true,
        wordWrapEnabled: true,
        onToolbarPreparing: e => {
          e.toolbarOptions.items.unshift(
            {
              location: 'before',
              template: 'datagridTitle',
            },
            {
              location: 'after',
              template: 'datagridActions',
            }
          );
        },
      },
    });
  }
}
