import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Campaign, Cooperative, CustomerDataFileService } from 'src/app/shared';
import { finalize } from 'rxjs/operators';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-upload-datafile-popup',
  templateUrl: './upload-datafile.component.html',
  styleUrls: ['./upload-datafile.component.scss'],
})
export class UploadDatafileComponent {
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Output() fileUploaded = new EventEmitter<void>();

  allowedFileExtensions = ['.zip'];
  selectedFile = [];
  campaign: Campaign;
  cooperative: Cooperative;
  uploading = false;

  constructor(private customerDataFileService: CustomerDataFileService) {
    this.closeModal = this.closeModal.bind(this);
    this.uploadDatafile = this.uploadDatafile.bind(this);
  }

  closeModal() {
    // Clear values to have empty fields the next time we open the modal
    this.selectedFile = [];
    this.campaign = null;
    this.cooperative = null;

    this.isOpenChange.emit(false);
  }

  isFormValid() {
    return this.campaign && this.cooperative && this.selectedFile.length === 1;
  }

  uploadDatafile() {
    confirm(
      "Attention, le dépôt d'un fichier est une action administrateur. Êtes-vous certain de vouloir poursuivre ?",
      'Confirmer le dépôt'
    ).then(confirmed => {
      if (confirmed) {
        this.uploading = true;
        this.customerDataFileService
          .uploadDataFile(this.selectedFile[0], this.campaign.id, this.cooperative.id)
          .pipe(
            finalize(() => {
              this.uploading = false;
              this.fileUploaded.emit();
              this.closeModal();
            })
          )
          .subscribe();
      }
    });
  }
}
