import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { RegisteredCrop } from 'src/app/shared';
import * as _ from 'lodash';
import { DifferenceService } from 'src/app/core/utility/difference.service';
@Component({
  selector: 'app-crops-editor',
  templateUrl: './crops-editor.component.html',
  styleUrls: ['./crops-editor.component.scss'],
})
export class CropsEditorComponent implements OnChanges {
  @Input() campaignCrops: RegisteredCrop[];
  @Output() campaignCropsChange = new EventEmitter<RegisteredCrop[]>();
  @Input() crops: any;
  @Input() servicePackIds: string[];
  cropIds: string[];
  modifiedCrops: RegisteredCrop[];

  constructor(private differenceService: DifferenceService) {}

  ngOnChanges(changes): void {
    if (changes.campaignCrops && !this.modifiedCrops) {
      this.modifiedCrops = _.cloneDeep(changes.campaignCrops.currentValue);
      this.cropIds = this.modifiedCrops
        ? _.uniq(this.modifiedCrops.map(registeredCrop => registeredCrop.cropRefId))
        : [];
    }
  }

  valueChange(isServicePachChange) {
    // remove registeredCrop from the campaign that cropRefId not in this.cropIds
    // this.campaign.crops = this.campaign.crops
    //   ? this.campaign.crops.filter(crop => this.cropIds.includes(crop.cropRefId))
    //   : [];
    if (this.modifiedCrops) {
      if (!isServicePachChange) {
        for (let i = this.modifiedCrops.length - 1; i >= 0; i--) {
          if (!this.cropIds.includes(this.modifiedCrops[i].cropRefId)) {
            this.modifiedCrops.splice(i, 1);
          }
        }
        const campaignCropIds = this.modifiedCrops.map(registeredCrop => registeredCrop.cropRefId);
        // add new registeredCrop in the campaign
        this.cropIds
          .filter(cropId => !campaignCropIds.includes(cropId))
          .forEach(newCropId =>
            this.modifiedCrops.push(new RegisteredCrop({ cropRefId: newCropId }))
          );
      }
      this.campaignCropsChange.emit(this.modifiedCrops);
    }
  }

  getCropLabel(cropId) {
    const foundCrop = this.crops.find(crop => crop.id === cropId);
    return foundCrop ? foundCrop.label : '???';
  }
}
