import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { shareReplay } from 'rxjs/internal/operators';
import { tapLogError } from 'src/app/core';
import { FileDownloaderService } from 'src/app/shared/files/file-downloader.service';

@Injectable()
export class TotalnRecommendationService {
  fsTotalnRecommendationApi = environment.api + '/fs-totaln/api/totalNDeliveredRecommendations';

  constructor(private httpClient: HttpClient, private fileDownloader: FileDownloaderService) {}

  recommendationExistsForParcel(parcelId: string): Observable<boolean> {
    const url = this.fsTotalnRecommendationApi + '/search/existsByParcelRefId';
    return this.httpClient.get<boolean>(url, { params: { id: parcelId } }).pipe(
      tapLogError('recommendationExistsForParcel'),
      shareReplay(1)
    );
  }

  downloadParcelReport(parcelId: string) {
    const url = this.fsTotalnRecommendationApi + '/reports';
    const params = new HttpParams().set('parcelRef', parcelId);
    this.fileDownloader.downloadFileFromGet('TotalN.pdf', url, params);
  }
}
