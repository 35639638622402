import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NDoseComputationEngine } from './n-dose-computation-engine.model';
import { tapLogError } from 'src/app/core/rxjs-operators';

@Injectable({
  providedIn: 'root',
})
export class NDoseComputationEngineService {
  constructor(private httpClient: HttpClient) {}

  getNDoseComputationEngineById(id: string): Observable<NDoseComputationEngine> {
    const nDoseComputationEnginesIdUrl =
      environment.api + '/fs-agro-datum/api/nDoseComputationEngines/' + id;
    return this.httpClient
      .get<Observable<NDoseComputationEngine>>(nDoseComputationEnginesIdUrl)
      .pipe(
        map(a => new NDoseComputationEngine(a)),
        tapLogError('getNDoseComputationEngineById')
      );
  }
}
