import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IngestionReport } from './ingestion-report.model';
import { tapLogError } from 'src/app/core';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class IngestionReportService {
  constructor(private httpClient: HttpClient, route: ActivatedRoute) {}

  getIngestionReport(dataFileId): Observable<IngestionReport> {
    const dataFileUrl =
      environment.api + '/fs-customer-datafile/api/importReports/getMoreRecentByDataFileUuid';
    return this.httpClient.get<IngestionReport>(dataFileUrl, { params: { uuid: dataFileId } }).pipe(
      tapLogError('getTestReports'),
      shareReplay(1)
    );
  }
}
