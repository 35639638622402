import { NgModule } from '@angular/core';
import { FarmersListComponent } from './farmers-list.component';
import { SharedModule } from 'src/app/shared';

@NgModule({
  declarations: [FarmersListComponent],
  imports: [SharedModule],
  exports: [FarmersListComponent],
  providers: [],
})
export class FarmersListModule {}
