import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-secured-route',
  template: `
    <p>Login works!</p>
    <button mat-button (click)="callSecuredWS()">Call secure WS</button>
    <p>response = {{ securedResponse | async }}</p>
  `,
  styles: [],
})
export class SecuredComponent implements OnInit {
  queryParams: string;
  securedResponse: Observable<any>;
  constructor(private httpClient: HttpClient) {}

  ngOnInit() {
    this.callSecuredWS();
  }

  callSecuredWS(): Observable<any> {
    const focusTestUrl = 'https://focuss-dev.oneatlas.airbus.com/hello-protected/';
    this.securedResponse = this.httpClient.get<any>(focusTestUrl);
    return this.securedResponse;
  }
}
