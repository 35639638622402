export class ProductionTrackingStatus {
  cooperativeName: string;
  cooperativeId: string;
  totalCount = 0;
  pdfCount = 0;
  computedCount = 0;
  failedCount = 0;
  constructor(data) {
    Object.assign(this, data);
  }
}
