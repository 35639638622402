import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-input-debounce',
  template: `
    <dx-text-box
      [showClearButton]="showClearButton"
      valueChangeEvent="keyup"
      (onValueChanged)="debouncedOnChange($event)"
    ></dx-text-box>
  `,
})
export class InputDebounceComponent {
  @Input() showClearButton: boolean;
  @Input() delay = 500;
  @Output() valueChanged = new EventEmitter<string>();

  debouncedOnChange = _.debounce(this.onChange.bind(this), this.delay);

  constructor() {}

  onChange(dxEvent) {
    this.valueChanged.emit(dxEvent.value);
  }
}
